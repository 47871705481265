import { SetStateAction, useEffect } from "react";
import { useFileUpload } from "src/shared/hooks/useFileUpload";

// Utils
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";

// Types
import { UseFormReturn } from "react-hook-form";
import { Library } from "src/redux/library/types";

// Components
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import { Textarea } from "src/shared/components/ui/textarea";
import { FormField, FormMessage } from "src/shared/components/form/Form";
import FormController from "src/shared/components/form/FormController";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import UploadButton from "src/shared/components/button/UploadButton";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";

// Icons
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import { Loader2Icon } from "lucide-react";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

type AboutUsFormProp = {
	form: UseFormReturn<Library, any, undefined>;
	setImage: React.Dispatch<SetStateAction<File[] | null>>;
	isAboutUsUpdateLoading: boolean;
	isSocialMediaUpdateLoading: boolean;
	isContactInfoUpdateLoading: boolean;
	_onSavePress: () => void;
	_onCancelPress: () => void;
};

const AboutUsForm = ({
	form,
	setImage,
	isAboutUsUpdateLoading,
	isSocialMediaUpdateLoading,
	isContactInfoUpdateLoading,
	_onSavePress,
	_onCancelPress,
}: AboutUsFormProp) => {
	const { isLoading: isImageUploading } = useFileUpload();

	const allowedAccess = useCheckAccessEditability();

	return (
		<SectionContainer>
			<SectionHeader className="flex-col-reverse sm:flex-row gap-4">
				<div className="flex flex-col gap-2">
					<Label variant="header">About Us</Label>
					<Label variant="description">
						Displayed on the About MR.DIY page of the app.
					</Label>
				</div>
				<div className="grid grid-cols-2 sm:grid-cols-3 gap-2 w-full sm:w-[40%] lg:w-[30%]">
					<Button
						className={`${
							!allowedAccess.includes("can_edit") && "hidden"
						} col-span-1 sm:col-span-2 xl:w-[80%] xl:ml-auto`}
						size="sm"
						disabled={
							!form.formState.isDirty ||
							!form.formState.errors ||
							!!form.formState.errors.image ||
							// !form.formState.isValid ||
							isAboutUsUpdateLoading ||
							isSocialMediaUpdateLoading ||
							isContactInfoUpdateLoading
						}
						onClick={_onSavePress}
					>
						{isAboutUsUpdateLoading ||
						isSocialMediaUpdateLoading ||
						isContactInfoUpdateLoading ? (
							<ButtonLoading />
						) : (
							"Save"
						)}
					</Button>
					<Button
						variant="secondary"
						className={`${
							!allowedAccess.includes("can_edit") && "hidden"
						} col-span-1`}
						size="sm"
						disabled={
							!form.formState.isDirty ||
							!form.formState.isValid ||
							isAboutUsUpdateLoading ||
							isSocialMediaUpdateLoading ||
							isContactInfoUpdateLoading
						}
						onClick={_onCancelPress}
					>
						Cancel
					</Button>
				</div>
			</SectionHeader>
			<div className="grid xl:grid-cols-2 gap-4">
				<FormField
					control={form.control}
					name="image"
					render={({ field: { value, onChange, ...fieldProps } }) => {
						return (
							<div className="grid w-full xl:col-span-1 gap-2">
								<Label variant="form" htmlFor="banner">
									Banner<span className="text-destructive-foreground">*</span>
								</Label>
								<div
									className={`${
										!allowedAccess.includes("can_edit")
											? "grayscale border-disabled-foreground"
											: "border-secondary"
									} h-[14em] xl:h-[22em] border-[1px] rounded-md flex items-center justify-center`}
									style={{
										backgroundImage: `url(${value})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
								>
									<UploadButton
										accept="image/*"
										className={`${
											!allowedAccess.includes("can_edit") && "hidden"
										}`}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											const file = e.target.files?.[0];

											if (file) {
												const isAttachmentValid = validateFileSize({
													file: file,
													form: form,
													errorMessage: "Image size must not exceed 2mb.",
													fieldName: "image",
													sizeLimit: 2,
												});

												const isExtensionValid = validateFileExtension({
													file: file,
													form: form,
													errorMessage:
														"Invalid file type. Please upload a .png, .jpg, or .jpeg file.",
													fieldName: "image",
													validExtensions: [
														"image/jpeg",
														"image/jpg",
														"image/png",
													],
												});

												if (!isExtensionValid || !isAttachmentValid) {
													return;
												} else {
													form.clearErrors();
												}

												const imageUrl = URL.createObjectURL(file);

												// Use this if multiple add
												// setImage((prevValue) =>
												// 	prevValue ? [...prevValue, file] : [file]
												// );

												setImage([file]);
												onChange(imageUrl);

												// Here you would typically upload the file to your server
												// and then update the form with the returned URL
												// uploadFileToServer(file).then(url => onChange(url));
											}
										}}
										value="" // Keep the file input value empty
										{...fieldProps}
									>
										{!isImageUploading ? (
											<ImportIconSvg
												fill={"#292D32"}
												className="w-5 h-5 mr-2"
											/>
										) : (
											<Loader2Icon className="w-5 h-5 animate-spin mr-2" />
										)}
										<span className="text-xs lg:text-base">Upload Image</span>
									</UploadButton>
								</div>
								{form.formState.errors.image && (
									<FormMessage>
										<>{form.formState.errors.image}</>
									</FormMessage>
								)}
							</div>
						);
					}}
				/>
				<FormField
					control={form.control}
					name="content"
					render={({ field }) => (
						<FormController label="Content" required>
							<div className="flex flex-col w-full h-[20em] sm:h-[14em] xl:h-[22em] gap-2 xl:col-span-1">
								<Textarea
									id="content"
									{...field}
									rows={20}
									wrap="soft"
									disabled={!allowedAccess.includes("can_edit")}
								/>
							</div>
						</FormController>
					)}
				/>
			</div>
		</SectionContainer>
	);
};

export default AboutUsForm;
