import { AccountLoggedIn } from "../account/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SuccessMultipleDataResponse, SuccessResponse } from "../types";
import { ForgotPassword } from "./schema";

type Credential = {
	email: string;
	password: string;
};

export type ResetPasswordBody = {
	otpCode: number;
	confirmPassword: string;
	newPassword: string;
};

type ValidateLinkResponse = {
	isValid: boolean;
};

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
	}),
	endpoints: (builder) => ({
		login: builder.mutation<SuccessResponse<AccountLoggedIn>, Credential>({
			query: (credentials) => ({
				url: "/auth",
				method: "POST",
				body: { ...credentials, authType: "user", imei: "A" },
			}),
		}),
		verifyOtp: builder.mutation<SuccessMultipleDataResponse<string>, number>({
			query: (otp) => ({
				url: `/user/otp/verify/${otp}`,
				method: "POST",
			}),
		}),
		forgotPassword: builder.mutation<
			SuccessMultipleDataResponse<string>,
			ForgotPassword
		>({
			query: (reqBody) => ({
				url: `/auth/forgot-password`,
				method: "POST",
				body: { ...reqBody },
			}),
		}),
		resetPassword: builder.mutation<
			SuccessMultipleDataResponse<string>,
			ResetPasswordBody
		>({
			query: (reqBody) => ({
				url: `/user/auth/reset-password`,
				method: "POST",
				body: { ...reqBody },
			}),
		}),
		validateResetLink: builder.mutation<ValidateLinkResponse, string>({
			query: (resetLink) => ({
				url: `/user/otp/verify-link/${resetLink}`,
				method: "POST",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<ValidateLinkResponse>) => {
				return data[0];
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useForgotPasswordMutation,
	useVerifyOtpMutation,
	useResetPasswordMutation,
	useValidateResetLinkMutation,
} = authApi;
