import { useEffect, useMemo, useState } from "react";
import {
	useDeleteCategoryMutation,
	useGetCategoriesQuery,
} from "../../redux/category/apiSlice";
import { useToast } from "src/shared/hooks/useToast";

// Types
import { Category } from "../../redux/category/types";

// Components
import { Label } from "src/shared/components/ui/label";
import { ToastClose } from "src/shared/components/ui/toast";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import CategoryFormModal from "./fragments/CategoryFormModal";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import LevelTwoForm from "./fragments/LevelTwoForm";
import LevelThreeForm from "./fragments/LevelThreeForm";
import LevelOneForm from "./fragments/LevelOneForm";
import Loading from "src/shared/components/loading-indicator/Loading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

// Icon
import { X } from "lucide-react";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

const CategoryPage = () => {
	const { toast } = useToast();

	const { data: categories = [], isLoading: isCategoriesLoading } =
		useGetCategoriesQuery();

	const [_deleteCategory, { isLoading: isDeleteLoading }] =
		useDeleteCategoryMutation();
	const [searchValue, setSearchValue] = useState("");
	const [isCategoryFormModalOpen, setIsCategoryFormModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);
	const [categorySelected, setCategorySelected] = useState({
		id: 0,
		name: "",
		image: "",
	});

	const filteredCategories = useMemo(() => {
		return categories.filter((category) =>
			category.name.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, categories]);

	const _onOpenModalPress = (category: Category) => {
		setCategorySelected(category);
		setIsCategoryFormModalOpen(true);
	};

	const _onOpenDeleteModalPress = (category: Category) => {
		setCategorySelected(category);
		setIsDeleteModalOpen(true);
	};

	const _onConfirmDelete = async () => {
		try {
			const response = await _deleteCategory(categorySelected.id);

			if (response.error) {
				throw response.error;
			}

			setCategorySelected({
				id: 0,
				name: "",
				image: "",
			});
			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
			setSearchValue("");
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});

				setCategorySelected({
					id: 0,
					name: "",
					image: "",
				});
				setIsDeleteModalOpen(false);
			}
		}
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
	};

	useEffect(() => {
		if (!isNotifModalOpen) {
			setCategorySelected({
				id: 0,
				name: "",
				image: "",
			});
		}
	}, [isNotifModalOpen]);

	if (isCategoriesLoading) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Category Management</Label>
			</PageHeader>

			<Label variant="description">
				Select a Category from the preceding column in order to add or edit its
				sub categories.
			</Label>

			<div className="grid gap-8 grid-cols-3 mt-8">
				<LevelOneForm
					categories={filteredCategories}
					_onOpenDeleteModalPress={_onOpenDeleteModalPress}
					_onOpenModalPress={_onOpenModalPress}
					_onOpenAddCategoryModal={setIsCategoryFormModalOpen}
					setSearchValue={setSearchValue}
				/>
				<LevelTwoForm />
				<LevelThreeForm />
			</div>

			<CategoryFormModal
				selectedCategory={categorySelected}
				setCategorySelected={setCategorySelected}
				modalState={isCategoryFormModalOpen}
				modalTrigger={() =>
					setIsCategoryFormModalOpen(!isCategoryFormModalOpen)
				}
				setIsNotifModalOpen={setIsNotifModalOpen}
			/>

			<ConfirmationDialog
				title="Delete Category?"
				description={`Category ${categorySelected.name} will be deleted`}
				confirmButtonLabel="Delete"
				closeButtonLabel="Cancel"
				modalState={isDeleteModalOpen}
				isLoading={isDeleteLoading}
				_onCancel={() => {
					setCategorySelected({
						id: 0,
						name: "",
						image: "",
					});
					setIsDeleteModalOpen(false);
				}}
				_onConfirm={_onConfirmDelete}
			/>

			<NotificationDialog
				description={"Category has been deleted successfully!"}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>

			<NotificationDialog
				description={
					!categorySelected.id
						? "Category has been added successfully!"
						: "Category has been updated successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default CategoryPage;
