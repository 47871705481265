import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";

// Utils
import { formatDate } from "src/shared/helpers/formatDate";

// Components
import { DragHandle } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";

// Icons
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import { Banner } from "src/redux/banner/types";

type ActiveBannerColumnProps = {
	_onOpenDeleteModal: (id: number, name: string) => void;
	allowedAccess: string[];
};

export const useActiveBannerColumns = ({
	_onOpenDeleteModal,
	allowedAccess,
}: ActiveBannerColumnProps): ColumnDef<Banner>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Banner>[] = [
		{
			id: "select",
			header: ({ table }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(value) => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "order",
			header: ({ column }) => (
				<div className="mx-4">
					<span className="pl-1">#</span>
				</div>
			),
			size: 20,
			cell: ({ row }) => (
				<div className="flex text-xs font-bold text-center">
					<p className="mx-auto inline-block">{row.getValue("order")}</p>
				</div>
			),
		},
		{
			id: "drag",
			header: () =>
				allowedAccess.includes("can_edit") ? (
					<div className="ml-4 mr-8 lg:mr-6"></div>
				) : null,
			size: 20,
			cell: () => (allowedAccess.includes("can_edit") ? <DragHandle /> : null),
		},
		{
			accessorKey: "title",
			header: ({ column }) => <Label>Title</Label>,
			cell: ({ row }) => (
				<div className="w-[10em] xl:w-[8em] max-w-[10em] overflow-hidden text-ellipsis whitespace-nowrap">
					{row.getValue("title")}
				</div>
			),
		},
		{
			accessorKey: "image",
			header: () => (
				<div className="text-center">
					<Label>Image</Label>
				</div>
			),
			cell: ({ row }) => {
				const image = row.getValue("image") as string;
				return image ? (
					<div className="h-16 w-[20em] mx-6 2xl:mx-auto 2xl:h-20 2xl:w-[25em] rounded-lg overflow-hidden">
						<img
							src={image}
							alt="banner"
							className="w-full h-full object-cover"
						/>
					</div>
				) : null;
			},
		},
		{
			accessorKey: "description",
			header: ({ column }) => <Label>Description</Label>,
			cell: ({ row }) => (
				<div className="max-h-16 max-w-[20em] overflow-hidden text-ellipsis line-clamp-3">
					{row.original.description}
				</div>
			),
		},
		{
			accessorKey: "startDate",
			header: () => <Label>From</Label>,
			cell: ({ row }) => {
				const date = row.getValue("startDate");
				return (
					<div className="max-w-[6em] w-full overflow-hidden text-ellipsis whitespace-nowrap">
						{formatDate(String(date))}
					</div>
				);
			},
		},
		{
			accessorKey: "endDate",
			header: () => <Label>To</Label>,
			cell: ({ row }) => {
				const date = row.getValue("endDate");
				return (
					<div className="max-w-[6em] w-full overflow-hidden text-ellipsis whitespace-nowrap">
						{!date ? "No expiration date" : formatDate(String(date))}
					</div>
				);
			},
		},
		{
			id: "actions",
			header: () =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div>
						<Label>Action</Label>
					</div>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_edit") && "hidden"}`}
							onClick={() => navigate(`${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_delete") && "hidden"}`}
							onClick={() =>
								_onOpenDeleteModal(row.original.id, row.original.title)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				) : null,
		},
	];

	return columns;
};
