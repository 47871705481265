import React from "react";
import { Input } from "../ui/input";
import SearchIconSvg from "src/assets/svg/SearchIconSvg";

type SearchInputType = {
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

const SearchInput = ({ setSearchValue }: SearchInputType) => {
	return (
		<div className="w-full md:w-[15em] xl:w-fit flex flex-row-reverse md:flex-row gap-6 justify-end items-center">
			<div className="relative w-full row-start-1 md:row-start-auto md:w-[15em] md:max-w-[15em] lg:w-[20em] lg:max-w-[20em]">
				<SearchIconSvg
					fill="black"
					className="absolute left-0 bottom-0 transform -translate-y-1/2 w-[14px] h-[14px] lg:w-5 lg:h-4 text-secondary"
				/>
				<Input
					id="search1"
					placeholder="Search"
					className="pl-6 lg:pl-8 text-[0.825rem] md:text-sm"
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default SearchInput;
