// Types
import { ColumnDef } from "@tanstack/react-table";
import { Store } from "src/redux/store/types";

// Icons
import { FaSort } from "react-icons/fa";

type StoreColumnProps = (id: number, name: string) => void;

export const useValidStoreDataColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<Store>[] => {
	const columns: ColumnDef<Store>[] = [
		{
			accessorKey: "store_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						Store
						<br />
						Code
					</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div
						className="max-w-[15ch] w-fit overflow-hidden text-ellipsis whitespace-nowrap text-center"
						id="import-cell"
					>
						{row.getValue("store_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "system_name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						System
						<br />
						Name
					</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap text-center">
						{row.getValue("system_name")}
					</div>
				);
			},
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Name</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[12ch] overflow-hidden text-ellipsis whitespace-nowrap text-center">
						{row.getValue("name")}
					</div>
				);
			},
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Address</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[8em] overflow-hidden text-ellipsis line-clamp-2">
						{row.getValue("address")}
					</div>
				);
			},
		},
		{
			accessorKey: "postal_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Code</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[5ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("postal_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "city",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">City</p>
				</button>
			),
		},
		{
			accessorKey: "opening_hrs",
			header: ({ column }) => <p className="font-bold w-fit">Opening</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("opening_hrs")}
					</div>
				);
			},
		},
		{
			accessorKey: "landline_no",
			header: () => <p className="font-bold w-fit">Landline</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("landline_no")}
					</div>
				);
			},
		},
		{
			accessorKey: "latitude",
			header: () => <p className="font-bold">Lat</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("latitude")}
					</div>
				);
			},
		},
		{
			accessorKey: "longitude",
			header: () => <p className="font-bold">Long</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("longitude")}
					</div>
				);
			},
		},
		{
			accessorKey: "status",
			header: () => <p className="font-bold text-center">Status</p>,

			cell: ({ row }) => (
				<div
					className={`mx-auto text-center text-white rounded-full text-[0.85em] w-[7em] font-bold py-1 px-1 ${
						row.getValue("status") == 0 ? "bg-neutral-500" : "bg-green-600"
					}`}
				>
					{/* TODO: ADD BADGE */}
					{row.getValue("status") == 0 ? "INACTIVE" : "ACTIVE"}
				</div>
			),
		},
	];

	return columns;
};
