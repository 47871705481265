import React, {
	useState,
	useRef,
	useEffect,
	HTMLProps,
	forwardRef,
	SetStateAction,
} from "react";
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import CalendarIconSvg from "src/assets/svg/CalendarIconSvg";
import { isAfter, isBefore } from "date-fns";

type CustomDateInputProps = HTMLProps<HTMLInputElement> & {
	onChange?: (date: Date) => void;
	initialDate?: Date;
	className?: string;
	dateFormat?: Intl.DateTimeFormatOptions;
	startDate?: Date;
	disabled?: boolean;
	isCalendarOpen: boolean;
	setIsCalendarOpen: React.Dispatch<SetStateAction<boolean>>;
};

const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>(
	(
		{
			onChange,
			initialDate = new Date(),
			className = "",
			startDate,
			dateFormat = {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			},
			isCalendarOpen = false,
			setIsCalendarOpen,
			disabled,
			...props
		},
		ref
	) => {
		const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
		const [isDateEarlierThanToday, setIsDateEarlierThanToday] = useState(false);
		const [displayedYear, setDisplayedYear] = useState<number>(
			initialDate.getFullYear()
		);
		const [displayedMonth, setDisplayedMonth] = useState<number>(
			initialDate.getMonth()
		);

		const calendarRef = useRef<HTMLDivElement>(null);
		const selectRef = useRef<HTMLDivElement>(null);

		const MONTHS = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		// Generate array of years (100 years before and after current year)
		const currentYear = new Date().getFullYear();
		const YEARS = Array.from({ length: 201 }, (_, i) => currentYear - 100 + i);

		useEffect(() => {
			// Declare these outside handleClickOutside to avoid recreating them on every event
			const initialMonth = initialDate.getMonth();
			const initialYear = initialDate.getFullYear();

			const handleClickOutside = (event: MouseEvent) => {
				if (
					calendarRef.current &&
					!calendarRef.current.contains(event.target as Node) &&
					!selectRef.current?.contains(event.target as Node)
				) {
					setIsCalendarOpen(false);
					setDisplayedMonth(initialMonth);
					setDisplayedYear(initialYear);
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () =>
				document.removeEventListener("mousedown", handleClickOutside);
		}, [initialDate, setIsCalendarOpen]);

		// useEffect(() => {
		// 	const handleClickOutside = (event: MouseEvent) => {
		// 		if (
		// 			calendarRef.current &&
		// 			!calendarRef.current.contains(event.target as Node) &&
		// 			!selectRef.current?.contains(event.target as Node)
		// 		) {
		// 			setIsCalendarOpen(false);
		// 			setDisplayedMonth(initialDate.getMonth());
		// 			setDisplayedYear(initialDate.getFullYear());
		// 		}
		// 	};

		// 	document.addEventListener("mousedown", handleClickOutside);
		// 	return () =>
		// 		document.removeEventListener("mousedown", handleClickOutside);
		// }, []);

		const handleDateSelect = (newDate: Date) => {
			setSelectedDate(newDate);
			onChange?.(newDate);
			setIsCalendarOpen(false);
		};

		const handleMonthChange = (value: string) => {
			setDisplayedMonth(parseInt(value));
		};

		const handleYearChange = (value: string) => {
			setDisplayedYear(parseInt(value));
		};

		// Generate calendar days
		const generateCalendarDays = (): JSX.Element[] => {
			const firstDay = new Date(displayedYear, displayedMonth, 1);
			const firstDayOfMonth = firstDay.getDay();
			const daysInMonth = new Date(
				displayedYear,
				displayedMonth + 1,
				0
			).getDate();

			const days: JSX.Element[] = [];

			// Add empty cells for days before the first of the month
			for (let i = 0; i < firstDayOfMonth; i++) {
				days.push(<div key={`empty-${i}`} className="h-8 w-8" />);
			}

			// Add the days of the month
			for (let day = 1; day <= daysInMonth; day++) {
				const currentDate = new Date(displayedYear, displayedMonth, day);

				const isSelected =
					currentDate.toDateString() === selectedDate.toDateString();

				const isBeforeToday =
					new Date(displayedYear, displayedMonth, day + 1) < new Date();

				let isBeforeStartDate = false;

				if (startDate) {
					if (isBefore(startDate, currentDate)) {
						isBeforeStartDate = false;
					} else {
						isBeforeStartDate = true;
					}
				}

				{
					isBeforeToday || isBeforeStartDate
						? days.push(
								<button
									type="button"
									key={day}
									disabled
									className={`h-8 w-8 rounded-full flex items-center justify-center text-disabled-foreground text-sm`}
								>
									{day}
								</button>
						  )
						: days.push(
								<button
									type="button"
									key={day}
									disabled={isDateEarlierThanToday}
									onClick={() => handleDateSelect(currentDate)}
									className={`h-8 w-8 rounded-full flex items-center justify-center text-sm ${
										isSelected
											? "bg-upload-foreground text-secondary"
											: "hover:bg-accent"
									}`}
								>
									{day}
								</button>
						  );
				}
			}

			return days;
		};

		const navigateMonth = (direction: number) => {
			let newMonth = displayedMonth + direction;
			let newYear = displayedYear;

			if (newMonth > 11) {
				newMonth = 0;
				newYear += 1;
			} else if (newMonth < 0) {
				newMonth = 11;
				newYear -= 1;
			}

			setDisplayedMonth(newMonth);
			setDisplayedYear(newYear);
		};

		return (
			<div className={`relative ${className}`} ref={calendarRef}>
				<div
					className="relative"
					onClick={() => setIsCalendarOpen(!isCalendarOpen)}
				>
					<Input
						readOnly
						className="pl-3 pr-10 hover:cursor-pointer"
						ref={ref}
						disabled={disabled}
						{...props}
					/>
					<Button
						size="icon"
						disabled={disabled}
						className="absolute right-0 top-0 h-full px-3 hover:bg-transparent bg-transparent border-none hover:border-none focus:bg-none focus:shadow-none active:shadow-none shadow-none disabled:bg-transparent"
					>
						<CalendarIconSvg
							fill={disabled ? "#c9c9c9" : "#292D32"}
							className="h-4 w-4"
						/>
					</Button>
				</div>

				{isCalendarOpen && (
					<div className="absolute mt-2 p-4 bg-white border rounded-lg shadow-lg z-50 min-w-[260px]">
						<div className="flex justify-between items-center mb-4 space-x-2">
							<Button
								size="sm"
								variant="ghost"
								className="rounded-full p-0 bg-transparent hover:bg-upload"
								onClick={() => navigateMonth(-1)}
							>
								←
							</Button>

							<div className="flex-1 flex space-x-2">
								<Select
									value={displayedMonth.toString()}
									onValueChange={handleMonthChange}
								>
									<SelectTrigger className="h-8">
										<SelectValue />
									</SelectTrigger>
									<SelectContent
										className="max-h-[20em] h-[20em] overflow-auto"
										ref={selectRef}
									>
										{MONTHS.map((month, index) => (
											<SelectItem key={month} value={index.toString()}>
												{month}
											</SelectItem>
										))}
									</SelectContent>
								</Select>

								<Select
									value={displayedYear.toString()}
									onValueChange={handleYearChange}
								>
									<SelectTrigger className="h-8">
										<SelectValue />
									</SelectTrigger>
									<SelectContent
										className="max-h-[20em] h-[20em] overflow-auto"
										ref={selectRef}
									>
										{YEARS.map((year) => (
											<SelectItem key={year} value={year.toString()}>
												{year}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>

							<Button
								variant="ghost"
								size="sm"
								className="rounded-full p-0 bg-transparent hover:bg-upload"
								onClick={() => navigateMonth(1)}
							>
								→
							</Button>
						</div>

						<div className="grid grid-cols-7 gap-1 mb-2">
							{["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
								<div
									key={day}
									className="h-8 w-8 flex items-center justify-center text-sm font-medium text-gray-500"
								>
									{day}
								</div>
							))}
						</div>

						<div className="grid grid-cols-7 gap-1">
							{generateCalendarDays()}
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default CustomDateInput;
