import { useCallback, useMemo, useState } from "react";
import { useCustomerColumns } from "./columns";
import {
	useDeleteCustomerMutation,
	useDeleteMultipleCustomersMutation,
	useGetCustomersQuery,
} from "src/redux/customer/apiSlice";
import { useToast } from "src/shared/hooks/useToast";

// Utils
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Types
import { Customer } from "src/redux/customer/types";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import { ToastClose } from "src/shared/components/ui/toast";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageControls from "src/shared/components/control/PageControls";
import SearchInput from "src/shared/components/textfield/SearchInput";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import { X } from "lucide-react";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

const CustomerPage = () => {
	const { toast } = useToast();

	const allowedAccess = useCheckAccessEditability();

	const { data: customersArray = [], isLoading: isCustomersArrayLoading } =
		useGetCustomersQuery();
	const [_deleteCustomer, { isLoading: isDeleteCustomerLoading }] =
		useDeleteCustomerMutation();
	const [_deleteCustomers, { isLoading: isDeleteCustomersLoading }] =
		useDeleteMultipleCustomersMutation();

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [selectedCustomer, setSelectedCustomer] = useState({
		id: 0,
		user_id: 0,
		name: "",
	});
	const [selectedRows, setSelectedRows] = useState<Customer[]>([]);

	const filteredCustomers = useMemo(() => {
		return customersArray.filter(
			(customer) =>
				customer.name.toLowerCase().includes(searchValue.toLowerCase()) ||
				customer.email.toLowerCase().includes(searchValue.toLowerCase()) ||
				customer.mobile_no.toString().includes(searchValue) ||
				customer.landline.toString().includes(searchValue)
		);
	}, [searchValue, customersArray]);

	const handleSelectionChange = useCallback((newSelectedRows: Customer[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onOpenDeleteModal = (id: number, user_id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedCustomer({
			id: id,
			user_id: user_id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		try {
			const response = await _deleteCustomer(selectedCustomer.user_id);

			if (response.error) {
				throw response.error;
			}

			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		try {
			const ids: Number[] = selectedRows.map((customer) => {
				return customer.user_id;
			});

			const response = await _deleteCustomers(ids);

			if (response.error) {
				throw response.error;
			}

			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});

				setIsMultipleDeleteModalOpen(false);
			}
		}
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useCustomerColumns({
		_onOpenDeleteModal,
		allowedAccess,
	});

	if (isCustomersArrayLoading) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Customer Management</Label>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full md:w-[15em]">
					<Button
						variant="destructive"
						className={`${
							!allowedAccess.includes("can_delete") && "hidden"
						} md:max-w-[10em]`}
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredCustomers}
					columns={columns}
					onSelectionChange={handleSelectionChange}
					hasPagination={customersArray.length > 10}
				/>
			</div>

			<ConfirmationDialog
				title="Delete Customer?"
				description={`Customer ${selectedCustomer.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={isDeleteCustomerLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Customers?`}
				description={`${selectedRows.length} customers will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={isDeleteCustomersLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Customers has been deleted successfully!"
						: "Customer has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default CustomerPage;
