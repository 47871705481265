import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToast } from "src/shared/hooks/useToast";
import {
	useGetAboutUsInfoQuery,
	useGetContactInfoQuery,
	useGetSocialMediaLinksQuery,
	useUpdateAboutUsMutation,
	useUpdateContactMutation,
	useUpdateSocialMediaMutation,
} from "src/redux/library/apiSlice";

// Library
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Utils
import {
	AboutUs,
	Contact,
	Library,
	SocialMediaLink,
} from "src/redux/library/types";
import { LibraryFormSchema } from "src/redux/library/schema";

// Components
import { Form } from "src/shared/components/form/Form";
import { Label } from "src/shared/components/ui/label";
import { ToastClose } from "src/shared/components/ui/toast";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import SocialMediaForm from "./fragments/SocialMediaForm";
import ContactInfoForm from "./fragments/ContactInfoForm";
import AboutUsForm from "./fragments/AboutUsForm";

// Icons
import { X } from "lucide-react";
import { formatDateWithTime } from "src/shared/helpers/formatDate";
import { useFileUpload } from "src/shared/hooks/useFileUpload";

type DateUpdated = {
	date: string;
	time: string;
};

const LibraryForm = () => {
	const navigate = useNavigate();

	const { toast } = useToast();

	const {
		uploadImage: _uploadImage,
		isSuccess: isImageUploaded,
		isError: isImageUploadingFailed,
	} = useFileUpload();

	const [_updateAboutUs, { isLoading: isAboutUsUpdateLoading }] =
		useUpdateAboutUsMutation();
	const [_updateSocialMedia, { isLoading: isSocialMediaUpdateLoading }] =
		useUpdateSocialMediaMutation();
	const [_updateContactInfo, { isLoading: isContactInfoUpdateLoading }] =
		useUpdateContactMutation();
	const { data: aboutUs, isLoading: isLoadingLibrary } =
		useGetAboutUsInfoQuery();
	const { data: socialMediaPlatforms, isLoading: isLoadingSocialMedia } =
		useGetSocialMediaLinksQuery();
	const { data: contactInfo, isLoading: isLoadingContactInfo } =
		useGetContactInfoQuery();

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);
	const [dateUpdated, setDateUpdated] = useState<DateUpdated | null>(null);
	const [image, setImage] = useState<File[] | null>(null);

	const form = useForm<Library>({
		resolver: zodResolver(LibraryFormSchema),
		defaultValues: {
			image: "",
			content: "",
			contact_info: {
				email_address: "",
				mobile_no: undefined,
				caremail: "",
				main_office_address: "",
			},
			social_media: [],
		},
		mode: "onTouched",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const _onConfirmLeave = () => {
		form.reset();
		setIsLeaveModalOpen(false);
	};

	const _onCancelPress = () => {
		if (!form.formState.isDirty) navigate("/library");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: Library) => {
		if (aboutUs && socialMediaPlatforms && contactInfo) {
			const apiCalls: Promise<any>[] = [];
			let abortApiCalls = false;

			let isAboutUsUpdated = !(
				aboutUs?.content === values.content && aboutUs?.image === values.image
			);

			let isContactInfoUpdated = !(
				contactInfo?.caremail === values.contact_info.caremail &&
				contactInfo?.email_address === values.contact_info.email_address &&
				contactInfo?.mobile_no === values.contact_info.mobile_no &&
				contactInfo?.main_office_address ===
					values.contact_info.main_office_address
			);

			let isSocialMediaUpdated = socialMediaPlatforms.some(
				(platform, index) => {
					const correspondingValue = values.social_media[index];

					// Compare platform and correspondingValue fields
					return (
						platform.icon !== correspondingValue.icon ||
						platform.platform !== correspondingValue.platform ||
						platform.social_media_link !==
							correspondingValue.social_media_link ||
						!!platform.status !== correspondingValue.status
					);
				}
			);

			try {
				setIsSaveModalOpen(false);

				if (isAboutUsUpdated) {
					let finalImagePath = values.image;

					if (image) {
						const imageUploaded = await _uploadImage(image, "libraries");
						finalImagePath = String(imageUploaded);
					}

					if (!isImageUploaded && isImageUploadingFailed) {
						console.log("Abort saving!");
						abortApiCalls = true;
					} else {
						const aboutUsReqBody: AboutUs = {
							id: aboutUs.id,
							image: finalImagePath,
							content: values.content,
							created_date: aboutUs.created_date,
							updated_date: aboutUs.updated_date,
							created_from: aboutUs.created_from,
							updated_from: aboutUs.updated_from,
							created_by: aboutUs.created_by,
							updated_by: aboutUs.updated_by,
							country_code: aboutUs.country_code,
							status: aboutUs.status,
							social_media_link: aboutUs.social_media_link,
							contact_info: aboutUs.contact_info,
							is_delete: aboutUs.is_delete,
						};

						const aboutUsPromise = _updateAboutUs(aboutUsReqBody).then(
							(aboutUsResponse) => {
								if (aboutUsResponse.error) {
									throw aboutUsResponse.error;
								}
								return aboutUsResponse;
							}
						);

						apiCalls.push(aboutUsPromise);
					}
				}

				if (isContactInfoUpdated) {
					const contactInfoReqBody: Contact = {
						id: contactInfo?.id,
						email_address: values.contact_info.email_address,
						caremail: values.contact_info.caremail,
						mobile_no: values.contact_info.mobile_no,
						main_office_address: values.contact_info.main_office_address,
						created_date: contactInfo?.created_date,
						is_delete: contactInfo?.is_delete,
						created_from: 1,
						created_by: contactInfo?.created_by,
						updated_by: Number(localStorage.getItem("USER_ID")),
						country_code: contactInfo?.country_code,
						status: 1,
					};

					const contactInfoPromise = _updateContactInfo(
						contactInfoReqBody
					).then((contactInfoResponse) => {
						if (contactInfoResponse.error) {
							throw contactInfoResponse.error;
						}
						return contactInfoResponse;
					});
					apiCalls.push(contactInfoPromise);
				}

				if (isSocialMediaUpdated) {
					const socialMediaPromises = values.social_media.map((socmed) => {
						const socialMediaLinksReqBody: SocialMediaLink = {
							id: socmed.id,
							platform: socmed.platform,
							icon: String(socmed.icon),
							social_media_link: socmed.social_media_link,
							updated_by: 153,
							updated_from: 1,
							country_code: "US",
							status: !socmed.status ? 0 : 1,
						};

						return _updateSocialMedia(socialMediaLinksReqBody).then(
							(socialMediaResponse) => {
								if (socialMediaResponse.error) {
									throw socialMediaResponse.error;
								}
								return socialMediaResponse;
							}
						);
					});

					apiCalls.push(...socialMediaPromises);
				}

				if (abortApiCalls == false) {
					await Promise.all(apiCalls);

					const updatedDate = formatDateWithTime(new Date());

					setDateUpdated(updatedDate);

					form.reset(values, {
						keepDirty: false,
						keepTouched: false,
						keepIsValid: false,
						keepErrors: false,
					});
					setTimeout(() => {
						setIsNotifModalOpen(true);
					}, 150);
				} else {
					form.setError("image", {
						type: "manual",
						message:
							"There is an issue with the file you're uploading. Please recheck your image.",
					});
					toast({
						description: "Failed to upload your image. Please try again.",
						duration: 2000,
						variant: "destructive",
						action: (
							<ToastClose
								className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
								aria-label="Close"
							>
								<X className="w-5 h-5" />
							</ToastClose>
						),
					});
				}
			} catch (error) {
				if (isErrorResponse(error)) {
					const errorResponse = reshapeErrorResponse(error);

					toast({
						description:
							errorResponse.status >= 500
								? "Server Issue! Please contact your administrator."
								: errorResponse.data.message,
						duration: 2000,
						variant: "destructive",
						action: (
							<ToastClose
								className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
								aria-label="Close"
							>
								<X className="w-5 h-5" />
							</ToastClose>
						),
					});
				}
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
	};

	const _findAndFormatLatestUpdatedDate = () => {
		if (aboutUs && Array.isArray(socialMediaPlatforms) && contactInfo) {
			// Collect all updated_date fields
			const updatedDates = [
				new Date(String(aboutUs.updated_date)),
				new Date(String(contactInfo.updated_date)),
				...socialMediaPlatforms.map(
					(platform) => new Date(String(platform.updated_date))
				),
			];

			// Find the latest date
			const latestDate = new Date(
				Math.max(...updatedDates.map((date) => date.getTime()))
			);

			const dateAndTime = formatDateWithTime(latestDate);
			setDateUpdated(dateAndTime);
		}
	};

	useEffect(() => {
		if (!isLoadingLibrary || !isLoadingSocialMedia || !isLoadingContactInfo) {
			if (aboutUs && Array.isArray(socialMediaPlatforms) && contactInfo) {
				const platforms = socialMediaPlatforms.map((platform) => {
					return {
						id: platform.id,
						status: !!platform.status,
						icon: platform.icon,
						platform: platform.platform,
						social_media_link: platform.social_media_link,
					};
				});

				_findAndFormatLatestUpdatedDate();

				const library: Library = {
					content: aboutUs.content,
					image: aboutUs.image,
					social_media: platforms,
					contact_info: {
						email_address: contactInfo.email_address,
						mobile_no: contactInfo.mobile_no,
						caremail: contactInfo.caremail,
						main_office_address: contactInfo.main_office_address,
					},
				};

				form.reset(library, {
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				});
			}
		}
	}, [aboutUs, socialMediaPlatforms, contactInfo]);

	if (isLoadingLibrary || isLoadingSocialMedia || isLoadingContactInfo) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<div className="w-auto h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<PageHeader>
							<Label variant="title">Library</Label>
						</PageHeader>

						<div className="space-y-4">
							<Label variant="description">
								Changes will reflect on the app. Last edited on{" "}
								<span className="font-bold">{dateUpdated?.date}</span> at{" "}
								<span className="font-bold">{dateUpdated?.time}</span>
							</Label>

							<AboutUsForm
								form={form}
								setImage={setImage}
								isAboutUsUpdateLoading={isAboutUsUpdateLoading}
								isSocialMediaUpdateLoading={isSocialMediaUpdateLoading}
								isContactInfoUpdateLoading={isContactInfoUpdateLoading}
								_onSavePress={_onSavePress}
								_onCancelPress={_onCancelPress}
							/>
							<ContactInfoForm form={form} />
							<SocialMediaForm form={form} />
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<ConfirmationDialog
				title="Confirm Cancel?"
				description="Canceling will discard unsaved changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<NotificationDialog
				description={"Library updated successfully!"}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default LibraryForm;
