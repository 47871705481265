import { useProductColumns } from "src/modules/product/columns";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import PageHeader from "src/shared/components/layout/header/PageHeader";

// Icons
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import { useCallback, useMemo, useState } from "react";
import { Product, ProductWithCategory } from "src/redux/product/types";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import PreviewProductModal from "./PreviewProductModal";

type ProductListSectionProp = {
	productsArray: ProductWithCategory[];
	pathSegments?: string[];
};

// TODO CREATE API FOR GET ALL PRODUCTS WHERE BANNER ID ==
const ProductListSection = ({
	productsArray,
	pathSegments,
}: ProductListSectionProp) => {
	const [showLoading, setShowLoading] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [selectedRows, setSelectedRows] = useState<Product[]>([]);
	const [selectedProduct, setSelectedProduct] = useState({
		id: 0,
	});
	const [previewProduct, setPreviewProduct] = useState<ProductWithCategory>({
		id: 0,
		sku: "",
		images: [],
		color: "",
		name: "",
		categoryId: 0,
		categoryName: "",
		description: "",
		variations: 0,
		price: 0,
		discounted_price: 0,
		size: "",
	});

	const [isProductPreviewModalOpen, setIsProductPreviewModalOpen] =
		useState(false);

	const handleSelectionChange = useCallback((newSelectedRows: Product[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onPreviewPress = (product: ProductWithCategory) => {
		setPreviewProduct(product);
		setIsProductPreviewModalOpen(true);
	};

	const _onOpenDeleteModal = (id: number) => {
		setIsDeleteModalOpen(true);
		setSelectedProduct({
			id: id,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsDeleteModalOpen(false);
		}, 2000);
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
		}, 2000);
	};

	const columns = useProductColumns({
		_onOpenDeleteModal,
		page: "Banner Form",
		_onPreviewPress,
	});

	return (
		<div className="mt-8">
			<PageHeader className="flex flex-col gap-4 items-start justify-start sm:flex-row sm:items-center">
				<Label variant="sub_header" className="w-fit">
					Product List
				</Label>
				<Button
					variant="destructive"
					className="w-full sm:w-fit"
					disabled={selectedRows.length < 1}
					onClick={_onOpenMultipleDeleteModal}
				>
					<ButtonDeleteIconSvg
						fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
						className="h-5 w-5 mb-[2px]"
					/>
					Remove
				</Button>
			</PageHeader>

			<DataTable
				data={productsArray}
				columns={columns}
				emptyTableText="No Product has been added"
				onSelectionChange={handleSelectionChange}
				hasPagination={productsArray.length > 10}
			/>

			<ConfirmationDialog
				title="Remove Product?"
				description={`This Product will be removed.`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Confirm"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
			<ConfirmationDialog
				title={`Remove Selected Products?`}
				description={`${selectedRows.length} products will be removed`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Confirm"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>
			<PreviewProductModal
				product={previewProduct}
				modalState={isProductPreviewModalOpen}
				modalTrigger={() =>
					setIsProductPreviewModalOpen(!isProductPreviewModalOpen)
				}
			/>
		</div>
	);
};

export default ProductListSection;
