import React, { HTMLProps } from "react";
import { Button } from "../ui/button";
import DownloadIconSvg from "src/assets/svg/DownloadIconSvg";
import { Label } from "../ui/label";
import { Table } from "@tanstack/react-table";

type ExportButtonProps = {
	onClick?: () => void;
	className?: string;
};

const ExportButton = ({ onClick, className }: ExportButtonProps) => {
	return (
		<Button
			className={`${className} relative px-8`}
			onClick={onClick}
			size="sm"
		>
			<DownloadIconSvg className="w-5 h-5" />
			<Label className="hidden md:block cursor-pointer">Get Template</Label>
		</Button>
	);
};

export default ExportButton;
