import { z } from "zod";

export const RoleFormSchema = z.object({
	rolename: z
		.string({
			required_error: "Please enter role name.",
		})
		.min(2, {
			message: "Role name must be at least 2 characters.",
		})
		.max(20, {
			message: "Role name must not exceed 20 characters.",
		})
		.regex(/^[A-Za-z.'\s-]+$/, {
			message: "Role name can only contain letters.",
		})
		.trim(),
	role_description: z
		.string({
			required_error: "Please enter description.",
		})
		.min(5, {
			message: "Description is too short.",
		})
		.max(250, {
			message: "Description is too long.",
		})
		.trim(),
});
