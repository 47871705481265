import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { Role } from "../../redux/role/types";

// Components
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

type RoleColumnTypes = {
	_onOpenDeleteModal: (id: number, name: string) => void;
	allowedAccess: string[];
};

export const useRolesColumns = ({
	_onOpenDeleteModal,
	allowedAccess,
}: RoleColumnTypes): ColumnDef<Role>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Role>[] = [
		{
			id: "select",
			header: ({ table }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(value) => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "rolename",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Role Name</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-h-16 max-w-[20em] w-[13em] overflow-hidden text-ellipsis line-clamp-2">
					{row.original.rolename}
				</div>
			),
		},
		{
			accessorKey: "role_description",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Description</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-h-16 max-w-[20em] w-[20em] overflow-hidden text-ellipsis line-clamp-2">
					{row.original.role_description}
				</div>
			),
		},
		{
			accessorKey: "permissions",
			header: ({ column }) => (
				<Label className="group-hover:cursor-pointer">
					Permissions Editability
				</Label>
			),
			cell: ({ row }) => {
				const permissionsEditable = row.original.permissions.filter(
					(permission) => {
						if (Array.isArray(permission.access)) {
							// Check if at least one access object has status true
							return permission.access.some((access) => access.status === true);
						}
						return false; // Return false if 'access' is not an array
					}
				);

				return (
					<div className="flex flex-col gap-1 max-w-[20em] w-[20em] overflow-hidden text-ellipsis">
						{permissionsEditable
							.filter((permission) =>
								Object.values(permission.access).some((access) => access)
							)
							.map((permission, index) => (
								<Fragment key={index}>
									<div className="flex gap-1 whitespace-nowrap">
										{permission.module}
										{/* <div className="flex gap-1 max-w-[10em] w-[10em] whitespace-nowrap text-ellipsis">
										{Object.entries(permission.access).map(
											([accessType, accessValue]) =>
												accessValue ? (
													<span>
														{accessType[0].toUpperCase()}
														{accessType.slice(1)}
													</span>
												) : null
										)}
									</div> */}
									</div>
								</Fragment>
							))}
					</div>
				);
			},
		},
		{
			id: "actions",
			header: () =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div>
						<Label>Action</Label>
					</div>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_edit") && "hidden"}`}
							onClick={() => navigate(`/roles/${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_delete") && "hidden"}`}
							onClick={() =>
								_onOpenDeleteModal(row.original.id, row.original.rolename)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				) : null,
		},
	];

	return columns;
};
