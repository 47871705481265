import errorIcon from "src/assets/images/error-icon.png";
import mrDiyIcon from "src/assets/images/MRDIYlogo.png";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
	const navigate = useNavigate();
	const isLoggedIn = localStorage.getItem("USER_ID");

	return (
		<div
			className="grid place-items-center max-h-screen text-center bg-upload"
			style={{ height: "calc(100vh - 60px)" }}
		>
			<div className="flex flex-col min-w-[320px] sm:min-w-[400px] md:min-w-[550px] 2xl:min-w-[800px] max-h-[800px] space-y-8 sm:space-y-10 md:space-y-8 z-10 mt-8 lg:mt-4 xl:mt-10">
				<div
					style={{ backgroundImage: `url(${mrDiyIcon})` }}
					className="h-[60px] w-[140px] md:h-[80px] md:w-[180px] bg-contain bg-center bg-no-repeat mx-auto"
				></div>
				<div className="space-y-2 sm:space-y-4 xl:space-y-4">
					<h1 className="text-3xl font-semibold sm:text-4xl lg:text-3xl xl:text-4xl">
						Oops! 404 Page not found
					</h1>
					<p className="text-xs font-normal sm:text-sm xl:text-sm">
						The page you're looking for doesn't exist.
					</p>
				</div>
				<div
					style={{ backgroundImage: `url(${errorIcon})` }}
					className="h-[180px] w-[220px] md:h-[200px] md:w-[230px] 2xl:h-[250px] 2xl:w-[280px] bg-contain bg-center bg-no-repeat mx-auto"
				></div>
				<div className="max-w-[300px] sm:max-w-[480px] md:max-w-[600px] xl:max-w-full h-fit text-center mx-auto">
					<p className="text-xs font-normal sm:text-sm xl:text-sm">
						{!isLoggedIn
							? "If you can't find the page you're looking for, we recommend going back to our Login page instead."
							: "If you can't find the page you're looking for, we recommend going back to our homepage instead."}
					</p>
				</div>

				<div className="h-fit">
					<Button
						size="sm"
						variant="default"
						className="w-fit px-10"
						onClick={() => {
							!isLoggedIn ? navigate("/auth") : navigate(-1);
						}}
					>
						{!isLoggedIn ? "Go to Login" : "Go back"}
					</Button>
				</div>
			</div>
			<div className="bg-[#fddf45] opacity-[30%] absolute bottom-0 h-1/2 xl:h-[45%] w-full z-5"></div>
		</div>
	);
};

export default PageNotFound;
