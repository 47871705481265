import { FormEvent, useEffect, useState } from "react";

// Types
import { UseFormReturn, useWatch } from "react-hook-form";
import { Product } from "src/redux/product/types";

// Utils
import { formatDate } from "src/shared/helpers/formatDate";

// Components
import CustomDateInput from "src/shared/components/date-picker/CustomDateInput";
import FormController from "src/shared/components/form/FormController";
import { FormField } from "src/shared/components/form/Form";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";

type DiscountSectionProp = {
	form: UseFormReturn<Product, any, undefined>;
};

const DiscountSection = ({ form }: DiscountSectionProp) => {
	// TODO CHANGE THIS TO FORM
	const [isNoExpirationDate, setIsNoExpirationDate] = useState(false);

	const [isStartDateOpen, setIsStartDateOpen] = useState<boolean>(false);
	const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false);

	const discountedPrice = useWatch({
		control: form.control,
		name: "discounted_price",
	});
	// * Don't delete, this triggers rerenders and validations if an event happens in CustomDateInput component
	const dates = useWatch({
		control: form.control,
		name: ["startDate", "endDate"],
	});

	const handleDateChange =
		(field: "startDate" | "endDate") =>
		(value: Date | FormEvent<HTMLInputElement>) => {
			const otherField = field === "startDate" ? "endDate" : "startDate";
			const otherValue = form.getValues(otherField);

			// Convert value to date string based on its type
			let dateString = "";
			if (value instanceof Date) {
				// Adjust for timezone to keep the selected date
				const offset = value.getTimezoneOffset();
				const adjustedDate = new Date(value.getTime() - offset * 60 * 1000);
				dateString = adjustedDate.toISOString().split("T")[0];
			} else {
				dateString = (value.target as HTMLInputElement).value;
			}

			// Set the value with formatted date
			form.setValue(field, formatDate(new Date(dateString)));

			// Only validate if both dates have values
			if (dateString && otherValue) {
				const startDate =
					field === "startDate" ? dateString : form.getValues("startDate");
				const endDate =
					field === "endDate" ? dateString : form.getValues("endDate");

				// Compare dates
				if (endDate && startDate) {
					if (new Date(endDate) < new Date(startDate)) {
						form.setError("startDate", {
							type: "manual",
							message: "End date cannot be earlier than Start date.",
						});
					} else {
						form.clearErrors(["startDate", "endDate"]);
					}
				}
			}
		};

	useEffect(() => {
		if (form.formState.isDirty && discountedPrice == 0) {
			form.setValue("startDate", "");
			form.setValue("endDate", "");
		}
	}, [discountedPrice]);

	useEffect(() => {
		if (isNoExpirationDate == true) {
			form.setValue("endDate", "");
		}
	}, [isNoExpirationDate]);

	return (
		<div
			className={`grid lg:grid-cols-3 gap-4 ${
				form.formState.errors.price || form.formState.errors.discounted_price
					? "pt-4"
					: "pt-0"
			}`}
		>
			<Label variant="sub_header" className="w-full lg:col-span-3">
				Discount Duration
			</Label>

			<div className="w-full flex gap-6">
				<div className="relative w-full">
					<FormField
						control={form.control}
						name="startDate"
						render={({ field }) => (
							<FormController label="Discount Start Date">
								{/* <CustomDateInput
									{...field}
									disabled={discountedPrice == 0}
									onChange={(value) =>
										field.onChange(formatDate(value as Date))
									}
									value={String(field.value)}
								/> */}
								<CustomDateInput
									{...field}
									onChange={handleDateChange("startDate")}
									value={String(field.value)}
									isCalendarOpen={isStartDateOpen}
									setIsCalendarOpen={setIsStartDateOpen}
								/>
							</FormController>
						)}
					/>
				</div>
			</div>

			<div className="flex flex-col-reverse gap-2 lg:flex-row lg:col-span-2 lg:gap-4">
				<div className="w-full flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<div className="relative w-full row-start-1 sm:row-start-auto">
						<FormField
							control={form.control}
							name="endDate"
							render={({ field }) => (
								<FormController label="Discount End Date">
									{/* <CustomDateInput
										{...field}
										disabled={discountedPrice == 0 || isNoExpirationDate}
										onChange={(value) =>
											field.onChange(formatDate(value as Date))
										}
										value={String(field.value)}
									/> */}
									<CustomDateInput
										{...field}
										onChange={handleDateChange("endDate")}
										value={String(field.value)}
										isCalendarOpen={isEndDateOpen}
										setIsCalendarOpen={setIsEndDateOpen}
										disabled={isNoExpirationDate}
									/>
								</FormController>
							)}
						/>
					</div>
				</div>

				<div className="flex flex-col w-full gap-4">
					<Label variant="form">Expiration</Label>
					<div className="flex gap-1 lg:gap-2 items-center">
						<Checkbox
							className="w-3 h-3 lg:w-4 lg:h-4"
							checked={isNoExpirationDate}
							disabled={discountedPrice == 0}
							onCheckedChange={() => setIsNoExpirationDate(!isNoExpirationDate)}
						/>
						<Label variant="description">No Expiration</Label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DiscountSection;
