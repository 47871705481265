import { z } from "zod";

export const LoginFormSchema = z.object({
	email: z
		.string()
		.min(1, { message: "Email is required" })
		.email("Invalid email format"),
	password: z
		.string({
			message: "Password is required",
		})
		.min(1, { message: "Password is required" })
		.transform((val) => val.trim()),
});

export const ForgotPasswordSchema = z.object({
	email: z
		.string()
		.min(1, { message: "Email is required" })
		.email("Invalid email format"),
});

export const ResetPasswordSchema = z
	.object({
		new_password: z
			.string()
			.min(8, { message: "Password must be at least 8 characters long" })
			.regex(/[A-Z]/, {
				message: "Password must contain at least one uppercase letter",
			})
			.regex(/[a-z]/, {
				message: "Password must contain at least one lowercase letter",
			})
			.regex(/[0-9]/, { message: "Password must contain at least one number" })
			.regex(/[@$!%*?&#]/, {
				message:
					"Password must contain at least one special character (@, $, !, %, *, ?, &, #)",
			}),
		confirm_password: z
			.string()
			.min(8, { message: "Password must be at least 8 characters long" }),
	})
	.refine((data) => data.new_password === data.confirm_password, {
		message: "Passwords do not match",
		path: ["confirm_password"], // Point the error to the confirm_password field
	});

export type LoginCredentials = z.infer<typeof LoginFormSchema>;

export type ForgotPassword = z.infer<typeof ForgotPasswordSchema>;

export type ResetPassword = z.infer<typeof ResetPasswordSchema>;
