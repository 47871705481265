import { createApi } from "@reduxjs/toolkit/query/react";

import { Account, UpdateAccountType } from "./types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";
import { SuccessMultipleDataResponse } from "../types";

export const accountsApi = createApi({
	reducerPath: "accountsApi",
	tagTypes: ["Accounts", "Account"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		// getAccounts: builder.query<Account[], void>({
		// 	queryFn() {
		// 		return new Promise<{ data: Account[] }>((resolve, reject) => {
		// 			try {
		// 				setTimeout(() => {
		// 					// TODO: Change this to api call
		// 					const accounts = accountsArray.map((account: Account) => {
		// 						return {
		// 							...account,
		// 						};
		// 					});

		// 					resolve({ data: accounts });
		// 				}, 1000);
		// 			} catch (error) {
		// 				reject({ error: error as Error });
		// 			}
		// 		});
		// 	},
		// }),
		getCurrentUserAccount: builder.query<Account, number>({
			query: (id: number) => ({
				url: `/user/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Account>) => {
				return data[0];
			},
			keepUnusedDataFor: 0,
			providesTags: ["Account"],
		}),
		addAccount: builder.mutation<void, Account>({
			query: (newAccount) => ({
				url: "/new",
				method: "POST",
				body: newAccount,
			}),
			invalidatesTags: ["Accounts"],
		}),
		updateAccount: builder.mutation<void, UpdateAccountType>({
			query: (updatedAccount) => ({
				url: `/user/${updatedAccount.id}`,
				method: "PATCH",
				body: updatedAccount,
			}),
			onQueryStarted: async (args) => {
				console.log("args", args);
			},
			invalidatesTags: ["Account"],
		}),
		deleteAccount: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Accounts"],
		}),
	}),
});

export const {
	// useGetAccountsQuery,
	useGetCurrentUserAccountQuery,
	useAddAccountMutation,
	useUpdateAccountMutation,
	useDeleteAccountMutation,
} = accountsApi;
