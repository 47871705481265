import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useToast } from "src/shared/hooks/useToast";
import {
	useAddStoresMutation,
	useGetSelectedStoreQuery,
	useGetStoresQuery,
	useUpdateStoreMutation,
} from "src/redux/store/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { _handleLocationChange } from "src/redux/store/locationSlice";
import { StoreFormSchema } from "src/redux/store/schema";

// Types
import { Store } from "src/redux/store/types";

// Utils
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Component
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import { Form } from "src/shared/components/form/Form";
import { ToastClose } from "src/shared/components/ui/toast";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import StoreInformationSection from "./fragments/StoreInformationSection";

// Icon
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { X } from "lucide-react";

const StoreInformationForm = () => {
	const { toast } = useToast();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { storeId } = useParams<{ storeId: string }>();
	const { data: selectedStore, isFetching: isSelectedStoreFetching } =
		useGetSelectedStoreQuery(Number(storeId));
	const { data: stores = [], isLoading: isStoresArrayFetching } =
		useGetStoresQuery();

	const [_addStore, { isLoading: isAddStoreLoading }] = useAddStoresMutation();
	const [_updateStore, { isLoading: isUpdateStoreLoading }] =
		useUpdateStoreMutation();

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<Store>({
		resolver: zodResolver(StoreFormSchema),
		defaultValues: {
			name: "",
			address: "",
			postal_code: "",
			city: "",
			longitude: 0,
			latitude: 0,
			status: 0,
			opening_hrs: "",
			landline_no: "",
			system_name: "",
			store_code: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		dispatch(
			_handleLocationChange({
				city: "",
				lat: 0,
				long: 0,
			})
		);
		form.reset();
		navigate("/store");
	};
	const _onCancelPress = () => {
		if (!isDirty) {
			form.reset();
			dispatch(
				_handleLocationChange({
					city: "",
					lat: 0,
					long: 0,
				})
			);
			navigate("/store");
		}

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
		setIsSaveModalOpen(false);
	};

	const _onSubmit = async (values: Store) => {
		if (values) {
			let isDuplicated = false;

			stores.forEach((store) => {
				if (
					store.store_code === values.store_code &&
					store.store_code !== selectedStore?.store_code
				) {
					isDuplicated = true;
				}
			});

			if (!isDuplicated) {
				if (values.longitude != 0 && values.latitude != 0) {
					try {
						if (!selectedStore?.id) {
							const newStore: Store = {
								...values,
								country_code: "US",
								created_from: 1,
								updated_from: 1,
								created_by: Number(localStorage.getItem("USER_ID")),
								updated_by: Number(localStorage.getItem("USER_ID")),
							};

							const response = await _addStore(newStore);

							if (response.error) {
								throw response.error;
							}

							setTimeout(() => {
								setIsNotifModalOpen(true);
							}, 250);
						} else {
							const updatedStore: Store = {
								...values,
								updated_by: Number(localStorage.getItem("USER_ID")),
							};

							const response = await _updateStore(updatedStore);

							if (response.error) {
								throw response.error;
							}

							setTimeout(() => {
								setIsNotifModalOpen(true);
							}, 250);
						}
					} catch (error) {
						if (isErrorResponse(error)) {
							const errorResponse = reshapeErrorResponse(error);

							toast({
								description:
									errorResponse.status >= 500
										? "Server Issue! Please contact your administrator."
										: errorResponse.data.message,
								duration: 2000,
								variant: "destructive",
								action: (
									<ToastClose
										className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
										aria-label="Close"
									>
										<X className="w-5 h-5" />
									</ToastClose>
								),
							});
						}
					}
				} else {
					form.setError("longitude", {
						message:
							"Please select a location in the map to set Longitude and Latitude.",
					});
					setIsSaveModalOpen(false);
				}
			} else {
				form.setError("store_code", {
					message: "Store Code already exists.",
				});
				setIsSaveModalOpen(false);
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		dispatch(
			_handleLocationChange({
				city: "",
				lat: 0,
				long: 0,
			})
		);
		navigate("/store");
	};

	useEffect(() => {
		if (selectedStore) {
			dispatch(
				_handleLocationChange({
					city: selectedStore.city,
					lat: selectedStore.latitude,
					long: selectedStore.longitude,
				})
			);

			form.reset(selectedStore, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}
	}, [selectedStore, form.reset]);

	if (isSelectedStoreFetching) {
		return <Loading />;
	}

	if (!isSelectedStoreFetching && !selectedStore) {
		if (pathSegments[2] != "add") {
			navigate("/store", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">
							{!selectedStore ? "New Store" : "Edit Store"}
						</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button
								disabled={
									!isDirty ||
									!isValid ||
									isAddStoreLoading ||
									isUpdateStoreLoading
								}
								onClick={_onSavePress}
							>
								{isAddStoreLoading || isUpdateStoreLoading ? (
									<ButtonLoading />
								) : (
									<>
										<ButtonCheckIconSvg
											className="h-5 w-5"
											fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
										/>
										Save
									</>
								)}
							</Button>
							<Button
								type="button"
								variant="secondary"
								size="md"
								onClick={_onCancelPress}
								disabled={isAddStoreLoading || isUpdateStoreLoading}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>

					<SectionContainer className="lg:grid-cols-3">
						<StoreInformationSection
							selectedStore={!selectedStore ? undefined : selectedStore}
							form={form}
						/>
					</SectionContainer>
				</PageContainer>
			</form>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<NotificationDialog
				description={
					!storeId
						? "Store has been added successfully!"
						: "Store has been updated successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};
export default StoreInformationForm;
