import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "src/lib/utils";
import { useAuth } from "src/providers/AuthProvider";
import { RolePermission } from "src/redux/role/types";

const buttonVariants = cva(
	"inline-flex items-center justify-center gap-2 whitespace-nowrap shadow-none [&_svg]:pointer-events-none [&_svg]:shrink-0 disabled:bg-disabled disabled:text-disabled-foreground disabled:border-disabled-foreground",
	{
		variants: {
			variant: {
				default:
					"bg-primary hover:bg-primary-foreground px-4 py-2 text-secondary rounded-2xl gap-[.35rem] font-semibold shadow-lg border-[#ffd444] hover:border-[#f6e706] border-b-4 active:shadow-inner active:border-none xl:max-w-40",
				destructive:
					"bg-destructive hover:bg-destructive-foreground px-4 py-2 text-white rounded-2xl gap-[.35rem] font-semibold shadow-md border-b-4 border-[#a71d2a] hover:border-[#f36676] active:shadow-inner active:border-none xl:max-w-40",
				secondary:
					"bg-secondary hover:bg-secondary-foreground px-5 py-2 text-white rounded-2xl gap-[.35rem] font-semibold shadow-md border-[#171a1c] hover:border-[#555d68]  border-b-4 active:shadow-inner active:border-none",
				upload:
					"bg-upload opacity-80 hover:bg-upload-foreground px-4 py-2 rounded-xl border-b-[1px] border-primary px-2 h-9 text-secondary inline-flex items-center active:shadow-inner active:border-none",
				filter:
					"bg-[#e7e7e7] hover:bg-[#ececec] px-4 py-2 w-fit text-secondary rounded-2xl gap-[.35rem] font-semibold shadow-lg border-[#dcdcdc] hover:border-[#e7e7e7] border-b-4 active:shadow-inner active:border-none",
				sidenav:
					"w-full justify-start bg-transparent px-4 py-2 hover:bg-secondary rounded-md hover:text-white ",
				table_filter:
					"p-0 w-full hover:bg-transparent hover:cursor-pointer group",
				icon: "bg-transparent hover:bg-transparent shadow-none",
				link: "w-fit ml-auto text-[0.825rem] sm:text-sm text-secondary underline underline-offset-4",
				// * Used by other Shad components
				outline:
					"border border-input px-4 py-2 bg-background hover:bg-accent hover:text-accent-foreground",
				ghost: "hover:bg-accent px-4 py-2 hover:text-accent-foreground",
			},
			size: {
				default: "h-11 w-full sm:w-sm text-[0.825rem] sm:text-sm",
				sm: "h-9 w-full text-xs lg:text-sm rounded-full",
				md: "h-11 w-full sm:w-fit px-4 text-[0.825rem] sm:text-sm",
				icon: "p-0 m-0",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	module?: string;
	accessType?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			variant,
			size,
			asChild = false,
			module,
			accessType,
			disabled,
			...props
		},
		ref
	) => {
		const Comp = asChild ? Slot : "button";

		return (
			<Comp
				type="button"
				className={cn(
					buttonVariants({ variant, size, className }),
					disabled && "bg-disabled text-disabled-foreground pointer-events-none"
				)}
				ref={ref}
				disabled={disabled}
				{...props}
			/>
		);
	}
);
Button.displayName = "Button";

export { Button, buttonVariants };
