import { format, formatISO } from "date-fns";

export const formatDate = (date: Date | string) => {
	return format(date, "MM/dd/yyyy");
};

export const formatDateWithTime = (date: Date | string) => {
	// Ensure the input is a Date object
	const parsedDate = typeof date === "string" ? new Date(date) : date;

	return {
		date: format(parsedDate, "MM/dd/yyyy"),
		time: format(parsedDate, "HH:mm:ss"),
	};
};

// export const formatDateToIso = (date: Date | string) => {
// 	console.log("qweqwe", date);
// 	return formatISO(date);
// };

export const formatDateToIso = (date: Date | string) => {
	// Convert to Date object if input is a string
	const parsedDate = typeof date === "string" ? new Date(date) : date;

	// Format date to ISO 8601 pattern (up to milliseconds)
	const baseIso = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");

	// Extract microseconds if the input is a string with higher precision
	const match = typeof date === "string" && date.match(/\.\d{6}/);
	const microseconds = match ? match[0].slice(1) : null;

	// Append microseconds if available
	return microseconds ? `${baseIso.slice(0, -3)}${microseconds}` : baseIso;
};
