import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
	useGetRolesNoAdminQuery,
	useGetRolesQuery,
} from "../../redux/role/apiSlice";
import {
	useAddUserMutation,
	useGetSelectedUserQuery,
	useUpdateUserMutation,
} from "src/redux/user/apiSlice";
import { useToast } from "src/shared/hooks/useToast";
import { useSendEmailMutation } from "src/redux/emailApiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { CreateUserType, UpdatedUserType, User } from "src/redux/user/types";
import { UserFormSchema } from "src/redux/user/schema";
import { generateRandomPassword } from "src/shared/helpers/generateRandomPassword";
import {
	formatDateToIso,
	formatDateWithTime,
} from "src/shared/helpers/formatDate";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { ToastClose } from "src/shared/components/ui/toast";

// Icon
import { X } from "lucide-react";
import { useAuth } from "src/providers/AuthProvider";

type DateUpdated = {
	date: string;
	time: string;
};

const UserForm = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { userId } = useParams<{ userId: string }>();
	const { data: roles, isFetching: isFetchingRoles } =
		useGetRolesNoAdminQuery();
	const { data: selectedUser, isFetching: isSelectedUserFetching } =
		useGetSelectedUserQuery(Number(userId));

	const { data: currentUser, isFetching: isCurrentUserLoading } =
		useGetSelectedUserQuery(Number(localStorage.getItem("USER_ID")));

	const [_addUser, { isLoading: isUserAddLoading }] = useAddUserMutation();
	const [_updateUser, { isLoading: isUserUpdateLoading }] =
		useUpdateUserMutation();
	const [_sendEmail, { isLoading: isEmailSendLoading }] =
		useSendEmailMutation();

	const [dateUpdated, setDateUpdated] = useState<DateUpdated | undefined>(
		undefined
	);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<User>({
		resolver: zodResolver(UserFormSchema),
		defaultValues: {
			firstname: "",
			lastname: "",
			email: "",
			role_id: 0,
			status: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/users");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/users");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: User) => {
		try {
			if (selectedUser) {
				const updatedUser: UpdatedUserType = {
					id: selectedUser.id,
					firstname: values.firstname,
					lastname: values.lastname,
					address: selectedUser.address,
					phone_number: selectedUser.phone_number,
					status: values.status,
					updated_date: formatDateToIso(new Date()),
					updated_from: 1,
					updated_by: selectedUser.id,
					image: values.image,
					email: values.email,
					role_id: values.role_id,
				};

				const response = await _updateUser(updatedUser);

				if (response.error) {
					throw response.error;
				}

				setIsSaveModalOpen(false);
				setTimeout(() => {
					setIsNotifModalOpen(true);
				}, 500);
			} else {
				const randomPass = generateRandomPassword();

				const newUser: CreateUserType = {
					firstname: values.firstname,
					middlename: "Default Middle Name",
					lastname: values.lastname,
					address: "Default Address",
					phone_number: "1234567890",
					role_id: values.role_id,
					email: values.email,
					password: randomPass,
					status: values.status,
					created_from: 1,
					updated_from: 1,
					created_by: Number(localStorage.getItem("USER_ID")),
					updated_by: Number(localStorage.getItem("USER_ID")),
				};

				const response = await _addUser(newUser);

				if (response.error) {
					throw response.error;
				} else {
					const email = await _sendEmail({
						to: values.email,
						subject: `${values.firstname} ${values.lastname} Initial Password`,
						body: `<!DOCTYPE html>
							<html lang=en>
							<head>
								<meta charset=UTF-8>
								<meta name=viewport content=width=device-width, initial-scale=1.0>
								<title>Your account has been created!</title>
								<style>
									body { font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0; }
									.container { width: 100%; max-width: 600px; margin: auto; background: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
									.header { text-align: center; padding: 10px 0; background-color: #f6bf06; color: white; border-radius: 5px 5px 0 0; }
									.content { margin: 20px 0; line-height: 1.6; }
									.otp { background-color: #f0f0f0; border: 1px solid #ccc; padding: 10px; font-size: 24px; text-align: center; margin: 20px 0; border-radius: 4px; }
									.footer { text-align: center; margin-top: 20px; font-size: 12px; color: #777; }
									a { color: #4CAF50; text-decoration: none; }
								</style>
							</head>
							<body>
								<div class=container>
									<div class=header>
										<h1>Your account has been created!</h1>
									</div>
									<div class=content>
										<p>Dear ${values.firstname},</p>
										<p>We’re excited to let you know that your account has been successfully created! For your security, a randomized password has been generated for your initial login.</p>
										<div class=otp>
							${randomPass}
							</div>
									 
										<p>Update your password as soon as possible to ensure your account remains secure. Using the forgot password in the Login page.</p>
										<p>If you have any questions or need assistance, feel free to email us at ph_customercare@mrdiy.com.</p>
										<p>Welcome aboard!</p>
									</div>
									<div class=footer>
										<p>Best regards,<br>MR DIY</p>
									</div>
								</div>
							</body>
							</html>`,
					});

					if (email.error) {
						setIsSaveModalOpen(false);
						throw email.error;
					}

					setIsSaveModalOpen(false);
					setTimeout(() => {
						setIsNotifModalOpen(true);
					}, 500);
				}
			}
		} catch (error) {
			setIsSaveModalOpen(false);

			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/users");
	};

	useEffect(() => {
		if (selectedUser) {
			form.reset(selectedUser, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}

		const updatedDate = selectedUser?.updated_date;
		if (updatedDate) {
			const dateAndTime = formatDateWithTime(updatedDate);
			setDateUpdated(dateAndTime);
		}
	}, [selectedUser, form]);

	if (isSelectedUserFetching || isFetchingRoles || isCurrentUserLoading) {
		return <Loading />;
	}

	if (
		!isSelectedUserFetching &&
		isCurrentUserLoading &&
		!selectedUser &&
		!currentUser
	) {
		if (pathSegments[2] != "add") {
			navigate("/users", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<FormHeader>
							<Label variant="title">User Management</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={!isDirty || !isValid || isUserUpdateLoading}
									onClick={_onSavePress}
								>
									{isUserUpdateLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="reset"
									onClick={_onCancelPress}
									disabled={isUserUpdateLoading}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>
						<div className="lg:grid lg:grid-cols-3 lg:gap-4">
							<SectionHeader className="lg:col-span-3">
								<div className="flex flex-col space-y-0 lg:gap-2">
									<Label variant="header">
										{!selectedUser?.id
											? "Add User Information"
											: "Edit User Information"}
									</Label>
									{selectedUser?.id && dateUpdated?.date ? (
										<Label variant="description">
											Last edited on{" "}
											<span className="font-bold">{dateUpdated.date}</span> at{" "}
											<span className="font-bold">{dateUpdated.time}</span>
										</Label>
									) : null}
								</div>
							</SectionHeader>

							<FormField
								control={form.control}
								name="firstname"
								render={({ field }) => (
									<FormController label="First Name" required>
										<Input {...field} />
									</FormController>
								)}
							/>
							<FormField
								control={form.control}
								name="lastname"
								render={({ field }) => (
									<FormController label="Last Name" required>
										<Input {...field} />
									</FormController>
								)}
							/>
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormController label="Email" required>
										<Input {...field} />
									</FormController>
								)}
							/>
							<FormField
								control={form.control}
								name="role_id"
								render={({ field }) => {
									const isAdmin =
										(currentUser?.role_id as number) == 1 ||
										(currentUser?.role_id as number) == 2;

									return (
										<FormController label="Role" required>
											<Select
												onValueChange={(value) => field.onChange(Number(value))}
												value={String(field.value)}
												disabled={!!selectedUser && !isAdmin}
											>
												<SelectTrigger>
													<SelectValue placeholder="Default Option" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="0" disabled>
															Default Option
														</SelectItem>
														{roles?.map((role) => {
															return (
																<SelectItem value={String(role.id)}>
																	{role.rolename}
																</SelectItem>
															);
														})}
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormController>
									);
								}}
							/>
							<FormField
								control={form.control}
								name="status"
								render={({ field }) => (
									<FormController label="Status" required>
										<RadioGroup
											onValueChange={(value) => field.onChange(Number(value))}
											value={String(field.value)}
											className="flex gap-4 mt-2"
										>
											<div className="flex items-center space-x-2">
												<RadioGroupItem value="1" id="active" />
												<Label
													htmlFor="active"
													variant="form"
													className="text-secondary"
												>
													Active
												</Label>
											</div>
											<div className="flex items-center space-x-2">
												<RadioGroupItem value="0" id="inactive" />
												<Label
													htmlFor="inactive"
													variant="form"
													className="text-secondary"
												>
													Inactive
												</Label>
											</div>
										</RadioGroup>
									</FormController>
								)}
							/>
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				isLoading={
					isUserUpdateLoading || isUserAddLoading || isEmailSendLoading
				}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<NotificationDialog
				description={
					!userId
						? "User has been added successfully!"
						: "User has been updated successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default UserForm;
