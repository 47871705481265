export type CustomErrorResponse = {
	data: {
		data: unknown;
		status: number;
		message: string;
	};
	status: number;
};

type NotFoundErrorResponse = {
	data: {
		error: string;
		path: string;
		requestId: string;
		status: number;
		timestamp: string;
	};
	status: number;
};

// Type guard to check if the error matches NotFoundErrorResponse shape
const isNotFoundError = (error: unknown): error is NotFoundErrorResponse => {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		"data" in error &&
		typeof (error as any).data?.status === "number" &&
		typeof (error as any).data?.error === "string"
	);
};

// Original type guard for CustomErrorResponse
const isCustomError = (error: unknown): error is CustomErrorResponse => {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		"data" in error &&
		typeof (error as any).data?.message === "string"
	);
};

export const isErrorResponse = (
	error: unknown
): error is CustomErrorResponse => {
	if (isCustomError(error)) {
		return true;
	}

	if (isNotFoundError(error)) {
		return true;
	}

	return false;
};

export const reshapeErrorResponse = (error: unknown): CustomErrorResponse => {
	if (isCustomError(error)) {
		// Already in CustomErrorResponse shape
		return error;
	}

	if (isNotFoundError(error)) {
		// Reshape 404 error into CustomErrorResponse

		console.log(error);
		return {
			data: {
				data: null, // No specific data field in 404 error shape
				status: (error as any).status,
				message:
					// (error as any).data.error ||
					"Error 404! Please contact your administrator.",
			},
			status: (error as any).status,
		};
	}

	// Return null if the error doesn't match any known shape
	return {
		data: {
			data: null, // No specific data field in 404 error shape
			status: (error as any).status,
			message: "Error unknown, not 404 or 500",
		},
		status: (error as any).status,
	};
};
