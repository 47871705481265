import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToast } from "src/shared/hooks/useToast";
import {
	ResetPasswordBody,
	useResetPasswordMutation,
	useValidateResetLinkMutation,
} from "src/redux/auth/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { ResetPassword, ForgotPasswordSchema } from "src/redux/auth/schema";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Components
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/shared/components/ui/card";
import { Form, FormField, FormMessage } from "src/shared/components/form/Form";
import { ToastClose } from "src/shared/components/ui/toast";
import FormController from "src/shared/components/form/FormController";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";

// Icons & Images
import diyLogo from "src/assets/images/mini_diy_logo.png";
import AuthLockIconSvg from "src/assets/svg/auth/AuthLockIconSvg";
import AuthHideIconSvg from "src/assets/svg/auth/AuthHideIconSvg";
import AuthShowIconSvg from "src/assets/svg/auth/AuthShowIconSvg";
import { X } from "lucide-react";

const ResetPasswordPage = () => {
	const { toast } = useToast();

	const otp = localStorage.getItem("OTP");
	const { link } = useParams<{ link: string }>();

	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isValidLink, setIsValidLink] = useState<boolean | null>(null);

	const [_resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
	const [_validateResetLink] = useValidateResetLinkMutation();

	const form = useForm<ResetPassword>({
		resolver: zodResolver(ForgotPasswordSchema),
		defaultValues: {
			new_password: "",
			confirm_password: "",
		},
		mode: "onChange",
		criteriaMode: "all",
	});

	const _onSubmit = async (values: ResetPassword) => {
		try {
			const reqBody: ResetPasswordBody = {
				otpCode: Number(otp),
				confirmPassword: values.confirm_password,
				newPassword: values.new_password,
			};

			const response = await _resetPassword(reqBody);

			if (response.error) {
				throw response.error;
			}

			toast({
				description:
					"Your password has been reset successfully! Redirecting you to the login page shortly.",
				duration: 1500,
				variant: "success",
				action: (
					<ToastClose
						className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
						aria-label="Close"
					>
						<X className="w-5 h-5" />
					</ToastClose>
				),
			});

			setTimeout(() => {
				localStorage.clear();
				window.location.href = "/auth";
			}, 2000);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const checkLink = async () => {
		if (link) {
			const response = await _validateResetLink(link);

			if (response.error) {
				<Navigate to="/*" replace />;
			}

			if (response.data) {
				if (response.data.isValid == true) {
					setIsValidLink(response.data.isValid);
				} else {
					setIsValidLink(false);
				}
			}
		} else {
			<Navigate to="/*" replace />;
		}
	};

	useEffect(() => {
		checkLink();
	}, []);

	if (isValidLink == false) {
		return <Navigate to="/*" replace />;
	}

	return (
		<Form {...form}>
			<form onSubmit={() => form.handleSubmit(_onSubmit)}>
				<Card className="w-[80%] max-w-[20em] sm:w-[20em] sm:max-w-[28em] lg:w-[23em] 2xl:w-[28em] rounded-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4">
					<CardHeader className="pb-5 2xl:pb-8">
						<CardTitle className="relative mb-9 lg:mb-14 2xl:mb-20">
							<div className="absolute h-[6em] w-[6em] -top-24 left-1/2 -translate-x-1/2 overflow-hidden lg:w-[8em] lg:h-[8em] 2xl:w-[10em] 2xl:h-[10em] lg:-top-32 2xl:-top-36">
								<img
									src={diyLogo}
									alt="banner"
									className="w-full h-full object-fill"
								/>
							</div>
						</CardTitle>
						<CardDescription className="flex flex-col gap-4 w-full lg:w-[90%] mx-auto">
							<div className="flex flex-row text-center">
								<Label
									variant="sub_header"
									className="flex mx-auto w-fit text-secondary font-semibold 2xl:text-2xl 2xl:my-2"
								>
									Reset Password
								</Label>
							</div>
							{/* <Label
								variant="description"
								className="flex mx-auto text-secondary lg:text-xs text-center leading-tight tracking-tight"
							>
								Please enter your email address where the link to reset your
								password will be sent.
							</Label> */}
						</CardDescription>
					</CardHeader>
					<CardContent>
						<div className="flex flex-col w-full items-center gap-4 2xl:gap-6">
							<div className="flex flex-col w-[90%]">
								<Label
									variant="form"
									className="flex flex-row gap-1 2xl:gap-2 items-center"
								>
									<AuthLockIconSvg
										className="h-4 w-4 2xl:h-6 2xl:w-6"
										fill="#292D32"
									/>
									New Password
								</Label>
								<FormField
									control={form.control}
									name="new_password"
									render={({ field }) => (
										<FormController hasValidationMessage={false}>
											<>
												<div className="relative w-full">
													<Input
														className="pr-6 lg:pr-8 text-secondary"
														id="password"
														type={showNewPassword ? "" : "password"}
														{...field}
													/>
													<div
														onClick={() =>
															setShowNewPassword((prevValue) => !prevValue)
														}
													>
														{showNewPassword ? (
															<AuthHideIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														) : (
															<AuthShowIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														)}
													</div>
												</div>
												{form.formState.errors.new_password ? (
													<FormMessage>
														<>{form.formState.errors.new_password}</>
													</FormMessage>
												) : (
													<></>
												)}
											</>
										</FormController>
									)}
								/>
							</div>
							<div className="flex flex-col w-[90%]">
								<Label
									variant="form"
									className="flex flex-row gap-1 2xl:gap-2 items-center"
								>
									<AuthLockIconSvg
										className="h-4 w-4 2xl:h-6 2xl:w-6"
										fill="#292D32"
									/>
									Confirm Password
								</Label>
								<FormField
									control={form.control}
									name="confirm_password"
									render={({ field }) => (
										<FormController hasValidationMessage={false}>
											<>
												<div className="relative w-full">
													<Input
														className="pr-6 lg:pr-8 text-secondary"
														id="confirm_password"
														type={showConfirmPassword ? "" : "password"}
														{...field}
													/>
													<div
														onClick={() =>
															setShowConfirmPassword((prevValue) => !prevValue)
														}
													>
														{showConfirmPassword ? (
															<AuthHideIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														) : (
															<AuthShowIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														)}
													</div>
												</div>
												{form.formState.errors.confirm_password ? (
													<FormMessage>
														<>{form.formState.errors.confirm_password}</>
													</FormMessage>
												) : (
													<></>
												)}
											</>
										</FormController>
									)}
								/>
							</div>
						</div>
					</CardContent>
					<CardFooter className="flex justify-center mb-2 lg:mt-1 lg:mb-2 2xl:mt-2">
						<Button
							size="sm"
							className="px-6 h-7 lg:h-9 lg:px-10 xl:w-[40%] shadow-lg rounded-full"
							onClick={() => _onSubmit(form.getValues())}
							disabled={isLoading || isSuccess}
						>
							{isLoading ? (
								<>
									<ButtonLoading />
								</>
							) : (
								"Submit"
							)}
						</Button>
					</CardFooter>
				</Card>
			</form>
		</Form>
	);
};

export default ResetPasswordPage;
