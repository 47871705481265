import { Access } from "src/redux/role/types";

// Define the sidebar order (your preferred order)
const sidebarOrder = [
	"Banner Management",
	"Users List",
	"Roles & Permission",
	"Customer Management",
	"Product Management",
	"Category Management",
	"Store Management",
	"Library",
	"Account Settings",
];

// Function to get the first available module following sidebar order
const getFirstAvailableModule = (
	permissionsAllowed: {
		access: Access;
		module_id: number;
		module: string;
	}[]
) => {
	// Create a set of allowed modules for O(1) lookup
	const allowedModules = new Set(permissionsAllowed.map((p) => p.module));

	// Find the first module from sidebar order that exists in permissions
	for (const module of sidebarOrder) {
		if (allowedModules.has(module)) {
			return module;
		}
	}
};

// Usage in your navigation logic
export const findFirstAvailableModule = (
	permissionsAllowed: {
		access: Access;
		module_id: number;
		module: string;
	}[]
) => {
	const firstModule = getFirstAvailableModule(permissionsAllowed);

	if (firstModule) {
		// Convert module name to route path if needed
		return firstModule?.split(" ")[0].toLowerCase().trim();
	}
};
