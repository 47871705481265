import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreColumns } from "./columns";
import {
	useDeleteMultipleStoresMutation,
	useDeleteStoreMutation,
	useGetStoresQuery,
} from "src/redux/store/apiSlice";

// Types
import { Store } from "src/redux/store/types";

// Utils
import { _exportTemplate } from "src/shared/helpers/exportFile";
import { _handleLocationChange } from "src/redux/store/locationSlice";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Components
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageControls from "src/shared/components/control/PageControls";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import ExportButton from "src/shared/components/button/ExportButton";
import ImportButton from "src/shared/components/button/ImportButton";
import SearchInput from "src/shared/components/textfield/SearchInput";
import TableTabs from "src/shared/components/table-tabs/tableTabs";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import StoreTables from "./fragments/StoreTables";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { useToast } from "src/shared/hooks/useToast";
import { ToastClose } from "src/shared/components/ui/toast";
import { X } from "lucide-react";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

const StorePage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const allowedAccess = useCheckAccessEditability();

	const { data: stores = [], isLoading: isStoresArrayFetching } =
		useGetStoresQuery();
	const [_deleteStore, { isLoading: isDeleteStoreLoading }] =
		useDeleteStoreMutation();
	const [_deleteStores, { isLoading: isMultipleStoreDeleteLoading }] =
		useDeleteMultipleStoresMutation();

	const [isActiveTable, setIsActiveTable] = useState(true);

	// Memoize active and inactive stores
	const activeStores = useMemo(
		() => stores.filter((store) => store.status === 1),
		[stores]
	);

	const inactiveStores = useMemo(
		() => stores.filter((store) => store.status === 0),
		[stores]
	);

	// Memoize the derived `storesArray`
	const storesArray = useMemo(
		() => (isActiveTable ? activeStores : inactiveStores),
		[isActiveTable, activeStores, inactiveStores]
	);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);

	const [searchValue, setSearchValue] = useState("");
	const [selectedStore, setSelectedStore] = useState({
		id: 0,
		name: "",
	});
	const [selectedRows, setSelectedRows] = useState<Store[]>([]);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedStore({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		try {
			const response = await _deleteStore(selectedStore.id);

			if (response.error) {
				throw response.error;
			}

			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
				setIsDeleteModalOpen(false);
			}
		}
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		try {
			const ids: Number[] = selectedRows.map((store) => {
				return store.id;
			});

			const response = await _deleteStores(ids);

			if (response.error) {
				throw response.error;
			}
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
				setIsMultipleDeleteModalOpen(false);
			}
		}
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useStoreColumns({
		_onOpenDeleteModal,
		allowedAccess,
	});

	if (isStoresArrayFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Store Management</Label>

				<div className="m-0 p-0 h-fit w-fit flex gap-2">
					<ExportButton
						onClick={() => _exportTemplate("Store")}
						className={`${
							!allowedAccess.includes("can_add") && "hidden"
						} md:max-w-[11em]`}
					/>
					<ImportButton
						onClick={() => navigate("import")}
						buttonText="Import Store Data"
						className={`${
							!allowedAccess.includes("can_add") && "hidden"
						} md:max-w-[11em]`}
					/>
				</div>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full md:w-[15em]">
					<Button
						onClick={() => {
							navigate(`add`);
						}}
						className={`${
							!allowedAccess.includes("can_add") && "hidden"
						} md:max-w-[10em]`}
					>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Store
					</Button>
					<Button
						variant="destructive"
						disabled={selectedRows.length < 1}
						className={`${
							!allowedAccess.includes("can_delete") && "hidden"
						} md:max-w-[10em]`}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-4 w-4 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<TableTabs
				activeDataLength={activeStores.length}
				inactiveDataLength={inactiveStores.length}
				isActiveTable={isActiveTable}
				setIsActiveTable={setIsActiveTable}
			/>

			<StoreTables
				storesArray={storesArray}
				columns={columns}
				searchValue={searchValue}
				isActiveTable={isActiveTable}
				setSelectedRows={setSelectedRows}
			/>

			<ConfirmationDialog
				title="Delete Store?"
				description={`Store ${selectedStore.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={isDeleteStoreLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Stores?`}
				description={`${selectedRows.length} stores will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={isMultipleStoreDeleteLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Stores has been deleted successfully!"
						: "Store has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default StorePage;
