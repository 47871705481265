import { z } from "zod";

export const AccountFormSchema = z
	.object({
		firstname: z
			.string({
				required_error: "Please enter your first name.",
				invalid_type_error: "First name cannot contain numbers or symbols.",
			})
			.min(1, {
				message: "First name must be at least 1 character.",
			})
			.max(50, {
				message: "First name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "First name cannot contain numbers or symbols.",
			})
			.trim(),
		lastname: z
			.string({
				required_error: "Please enter your last name.",
				invalid_type_error: "Last name cannot contain numbers or symbols.",
			})
			.min(1, {
				message: "Last name must be at least 1 character.",
			})
			.max(50, {
				message: "Last name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "Last name cannot contain numbers or symbols.",
			})
			.trim(),
		email: z
			.string({
				required_error: "Please enter your email.",
			})
			.min(2, {
				message: "Please enter a valid email address.",
			})
			.email({ message: "Please enter a valid email address." }),
		role_id: z.coerce
			.number({
				required_error: "Please select a role.",
			})
			.refine((value) => value != 0, {
				message: "Please select a role.",
			})
			.optional(),
		image: z.union([z.instanceof(File), z.string().url(), z.null()]).optional(),
	})
	.strict();
