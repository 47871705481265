import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Button } from "src/shared/components/ui/button";
import { Sheet, SheetContent } from "src/shared/components/ui/sheet";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/shared/components/ui/tooltip";

// Icons
import SidebarBannerIconSvg from "src/assets/svg/sidebar/SidebarBannerIconSvg";
import SidebarUserIconSvg from "src/assets/svg/sidebar/SidebarUserIconSvg";
import SidebarCustomerIconSvg from "src/assets/svg/sidebar/SidebarCustomerIconSvg";
import SidebarProductIconSvg from "src/assets/svg/sidebar/SidebarProductIconSvg";
import SidebarCategoryIconSvg from "src/assets/svg/sidebar/SidebarCategoryIconSvg";
import SidebarStoreIconSvg from "src/assets/svg/sidebar/SidebarStoreIconSvg";
import SidebarLibraryIconSvg from "src/assets/svg/sidebar/SidebarLibraryIconSvg";
import SidebarSettingsIconSvg from "src/assets/svg/sidebar/SidebarSettingsIconSvg";
import ChevronDownIconSvg from "src/assets/svg/ChevronDownIconSvg";
import ChevronUpIconSvg from "src/assets/svg/ChevronUpIconSvg";
import SidebarSubmoduleIconSvg from "src/assets/svg/sidebar/SidebarSubmoduleIconSvg";
import { Label } from "../../ui/label";
import { useAuth } from "src/providers/AuthProvider";

type NavItem = {
	label: string;
	path: string;
	icon: React.ElementType;
};

type NavItems = NavItem & {
	subModule?: NavItem[];
};

const sideNavItems: NavItems[] = [
	{
		label: "Banner Management",
		path: "banner",
		icon: SidebarBannerIconSvg,
	},
	{
		label: "User Management",
		path: "users",
		icon: SidebarUserIconSvg,
		subModule: [
			{ label: "Users List", path: "users", icon: SidebarSubmoduleIconSvg },
			{
				label: "Roles & Permission",
				path: "roles",
				icon: SidebarSubmoduleIconSvg,
			},
		],
	},
	{
		label: "Customer Management",
		path: "customer",
		icon: SidebarCustomerIconSvg,
	},
	{
		label: "Product Management",
		path: "product",
		icon: SidebarProductIconSvg,
	},
	{
		label: "Category Management",
		path: "category",
		icon: SidebarCategoryIconSvg,
	},
	{
		label: "Store Management",
		path: "store",
		icon: SidebarStoreIconSvg,
	},
	{
		label: "Library",
		path: "library",
		icon: SidebarLibraryIconSvg,
	},
	{
		label: "Account Settings",
		path: "account",
		icon: SidebarSettingsIconSvg,
	},
];

type NavigationListProps = {
	isLargeScreenNavCollapsed: boolean;
	isSmallScreenNavOpen: boolean;
	setIsSmallScreenNavOpen: (isOpen: boolean) => void;
};

const NavigationList = ({
	isLargeScreenNavCollapsed,
	isSmallScreenNavOpen,
	setIsSmallScreenNavOpen,
}: NavigationListProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { permissions } = useAuth();

	const [isUserModuleOpen, setIsUserModuleOpen] = useState(false);

	// Filter navigation items based on permissions
	const filteredNavItems = useMemo(() => {
		return sideNavItems.filter((navItem, index) => {
			const modulePermission = permissions?.find((p) => {
				return p.module === navItem.label;
			});

			const hasViewPermission =
				modulePermission &&
				Object.values(modulePermission.access).some(
					(permission) =>
						permission.permission === "can_view" && permission.status === true
				);

			// If there are submodules, check them too
			if (navItem.subModule) {
				const filteredSubModules = navItem.subModule.filter((subModule) => {
					const subModulePermission = permissions?.find(
						(p) => p.module === subModule.label
					);
					return (
						subModulePermission &&
						Object.values(subModulePermission.access).some(
							(permission) =>
								permission.permission === "can_view" &&
								permission.status === true
						)
					);
				});

				// Keep the nav item if either main module or any submodule has permission
				if (hasViewPermission || filteredSubModules.length > 0) {
					return {
						...navItem,
						subModule: filteredSubModules,
					};
				}
				return false;
			}

			return hasViewPermission;
		});
	}, [permissions]);

	const moduleAvailable = filteredNavItems[0].path;

	useEffect(() => {
		if (moduleAvailable == "users") {
			setIsUserModuleOpen(true);
		}
	}, [moduleAvailable]);

	return (
		<>
			<Sheet open={isSmallScreenNavOpen} onOpenChange={setIsSmallScreenNavOpen}>
				<SheetContent side="left" className="px-3 overflow-auto">
					<div
						id="small-nav"
						className="flex flex-col mt-8 gap-y-2 items-center"
					>
						{filteredNavItems.map((item) => {
							const userModule: NavItems[] = [];

							if (item.label == "User Management") {
								item.subModule?.forEach((subModule) => {
									const isExisting = permissions?.some(
										(permission) => permission.module === subModule.label
									);

									if (isExisting) {
										if (subModule.label == "Roles & Permission") {
											userModule.push(subModule);
										}

										if (subModule.label == "Users List") {
											userModule.push(subModule);
										}
									}
								});
							}

							return (
								<Fragment key={item.path}>
									{!item.subModule ? (
										<Button
											className={`w-full text-start mx-none group rounded-lg ${
												pathSegments[1] === item.path
													? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
													: "bg-transparent hover:bg-accent hover:text-secondary"
											}`}
											variant="sidenav"
											onClick={() => {
												navigate(item.path);
												setIsUserModuleOpen(false);
											}}
										>
											<div className="flex flex-row w-full items-center justify-between">
												<div className="flex items-center gap-3">
													<item.icon
														className={`${
															item.path === "banner" ? "h-7 w-7" : "h-6 w-6"
														}`}
														fill={
															pathSegments[1] === item.path ? "#ED1D24" : "none"
														}
														outline={
															pathSegments[1] === item.path
																? "#FFF200"
																: "#292d32"
														}
													/>
													<span
														className={`text-xs ${
															isLargeScreenNavCollapsed ? "hidden" : "inline"
														}`}
													>
														{item.label}
													</span>
												</div>
											</div>
										</Button>
									) : (
										<>
											{userModule.length != 0 && (
												<div
													className={`w-full ${
														isUserModuleOpen && "space-y-2"
													}`}
												>
													<Button
														className={`w-full text-start mx-none group rounded-lg ${
															isUserModuleOpen
																? "bg-transparent hover:bg-accent text-black active:text-black hover:text-black focus:text-black"
																: "bg-transparent hover:bg-accent hover:text-secondary"
														}`}
														variant="sidenav"
														onClick={() =>
															setIsUserModuleOpen((prevValue) => !prevValue)
														}
													>
														<div className="flex flex-row w-full items-center justify-between">
															<div className="flex items-center gap-3">
																<item.icon
																	className="h-6 w-6"
																	fill="none"
																	outline={
																		isUserModuleOpen ? "292d32" : "#292d32"
																	}
																/>
																<span
																	className={`text-xs ${
																		isLargeScreenNavCollapsed
																			? "hidden"
																			: "inline"
																	}`}
																>
																	{item.label}
																</span>
															</div>

															{isUserModuleOpen ? (
																<ChevronUpIconSvg
																	fill="white"
																	className="h-5 w-5"
																/>
															) : (
																<ChevronDownIconSvg
																	fill="#292d32"
																	className="h-5 w-5"
																/>
															)}
														</div>
													</Button>

													<div
														className={`flex flex-col transition-all transform duration-150 overflow-hidden gap-2 ${
															isUserModuleOpen
																? `${
																		userModule.length == 1
																			? "h-[44px]"
																			: "h-[6em]"
																  } translate-y-0 opacity-100`
																: "h-0 -translate-y-full opacity-0"
														}`}
													>
														{userModule.map((module) => {
															return (
																<Button
																	key={module.path}
																	className={`w-full text-start mx-none group rounded-lg ${
																		pathSegments[1] === module.path
																			? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
																			: "bg-transparent hover:bg-accent hover:text-secondary"
																	}`}
																	variant="sidenav"
																	onClick={() => navigate(module.path)}
																>
																	<div className="flex flex-row w-full items-center justify-between">
																		<div className="flex items-center gap-3">
																			<SidebarSubmoduleIconSvg
																				className="h-6 w-6"
																				fill={
																					pathSegments[1] === module.path
																						? "#FFF200"
																						: "#292d32"
																				}
																				outline={
																					pathSegments[1] === module.path
																						? "#ED1D24"
																						: "#292d32"
																				}
																			/>
																			<span
																				className={`text-xs ${
																					isLargeScreenNavCollapsed
																						? "hidden"
																						: "inline"
																				}`}
																			>
																				{module.label}
																			</span>
																		</div>
																	</div>
																</Button>
															);
														})}
													</div>
												</div>
											)}
										</>
									)}
								</Fragment>
							);
						})}
					</div>
				</SheetContent>
			</Sheet>

			{/* NAVIGATION LIST FOR LARGE SCREENS */}
			<div
				id="large-nav"
				className={`hidden mt-4 mx-[26px] xl:flex xl:flex-col gap-y-2 items-start`}
			>
				{filteredNavItems.map((item) => {
					const userModule: NavItems[] = [];

					if (item.label == "User Management") {
						item.subModule?.forEach((subModule) => {
							const isExisting = permissions?.some(
								(permission) => permission.module === subModule.label
							);

							if (isExisting) {
								if (subModule.label == "Roles & Permission") {
									userModule.push(subModule);
								}

								if (subModule.label == "Users List") {
									userModule.push(subModule);
								}
							}
						});
					}

					return (
						<Fragment key={item.path}>
							{!item.subModule ? (
								// MODULES WITHOUT DROPDOWN
								<TooltipProvider key={item.path}>
									<Tooltip delayDuration={100}>
										<TooltipTrigger className="w-full">
											<Button
												className={`text-start mx-none group rounded-lg ${
													pathSegments[1] === item.path
														? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
														: "bg-transparent hover:bg-accent hover:text-secondary"
												}`}
												variant="sidenav"
												onClick={() => {
													navigate(item.path);
													setIsUserModuleOpen(false);
												}}
											>
												<div className="flex flex-row w-full items-center justify-between">
													<div className="flex items-center gap-3">
														<item.icon
															className={`${
																item.path === "banner"
																	? "h-7 w-7"
																	: "h-6 w-6 ml-[1px]"
															}`}
															fill={
																pathSegments[1] === item.path
																	? "#ED1D24"
																	: "none"
															}
															outline={
																pathSegments[1] === item.path
																	? "#FFF200"
																	: "#292d32"
															}
														/>
														<span
															className={`text-xs ${
																isLargeScreenNavCollapsed ? "hidden" : "inline"
															}`}
														>
															{item.label}
														</span>
													</div>
												</div>
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="right"
											className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 ${
												isLargeScreenNavCollapsed ? "ml-3" : "hidden"
											}`}
										>
											<Label>{item.label}</Label>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								<>
									{userModule.length != 0 && ( // USER MANAGEMENT MODULE
										<Fragment key={item.path}>
											<div
												className={`w-full ${isUserModuleOpen && "space-y-2"}`}
											>
												<TooltipProvider>
													<Tooltip delayDuration={100}>
														<TooltipTrigger className="w-full">
															<Button
																className={`w-full text-start mx-none group rounded-lg ${
																	isUserModuleOpen
																		? "bg-transparent hover:bg-accent text-black active:text-black hover:text-black focus:text-black"
																		: "bg-transparent hover:bg-accent hover:text-secondary"
																}`}
																variant="sidenav"
																onClick={() =>
																	setIsUserModuleOpen((prevValue) => !prevValue)
																}
															>
																<div className="flex flex-row w-full items-center justify-between">
																	<div className="flex items-center gap-3">
																		<item.icon
																			className="h-6 w-6"
																			fill="none"
																			outline={
																				isUserModuleOpen ? "black" : "#292d32"
																			}
																		/>
																		<span
																			className={`text-xs ${
																				isLargeScreenNavCollapsed
																					? "hidden"
																					: "inline"
																			}`}
																		>
																			{item.label}
																		</span>
																	</div>

																	{isUserModuleOpen ? (
																		<ChevronUpIconSvg
																			fill="black"
																			className={`${
																				isLargeScreenNavCollapsed
																					? "h-4 w-4"
																					: "h-5 w-5"
																			}`}
																		/>
																	) : (
																		<ChevronDownIconSvg
																			fill="black"
																			className={`${
																				isLargeScreenNavCollapsed
																					? "h-4 w-4"
																					: "h-5 w-5"
																			}`}
																		/>
																	)}
																</div>
															</Button>
														</TooltipTrigger>
														<TooltipContent
															side="right"
															className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 px-4 mb-4 ${
																isLargeScreenNavCollapsed ? "ml-3" : "hidden"
															}`}
														>
															<Label>{item.label}</Label>
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>

												<div
													className={`${
														isUserModuleOpen
															? `${
																	userModule.length == 1
																		? "h-[44px]"
																		: "h-[6em]"
															  }`
															: "h-0"
													} flex flex-col z-[9999] transition-all duration-150 gap-2`}
												>
													{userModule.map((module) => {
														return (
															<div
																key={module.path}
																className={`${
																	isUserModuleOpen ? "relative" : "hidden"
																} `}
															>
																<TooltipProvider>
																	<Tooltip delayDuration={100}>
																		<TooltipTrigger className="w-full">
																			<Button
																				className={`w-full text-start mx-none group rounded-lg  ${
																					pathSegments[1] === module.path
																						? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
																						: "bg-transparent hover:bg-accent hover:text-secondary"
																				}`}
																				variant="sidenav"
																				onClick={() => navigate(module.path)}
																			>
																				<div className="flex flex-row w-full items-center justify-between">
																					<div className="flex items-center gap-3">
																						<SidebarSubmoduleIconSvg
																							className="h-6 w-6"
																							fill={
																								pathSegments[1] === module.path
																									? "#FFF200"
																									: "#292d32"
																							}
																							outline={
																								pathSegments[1] === module.path
																									? "#ED1D24"
																									: "#292d32"
																							}
																						/>
																						<span
																							className={`text-xs ${
																								isLargeScreenNavCollapsed
																									? "hidden"
																									: "inline"
																							}`}
																						>
																							{module.label}
																						</span>
																					</div>
																				</div>
																			</Button>
																		</TooltipTrigger>
																		<TooltipContent
																			side="right"
																			className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 px-4 ${
																				isLargeScreenNavCollapsed
																					? "ml-3"
																					: "hidden"
																			}`}
																		>
																			<Label>{module.label}</Label>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</div>
														);
													})}
												</div>
											</div>
										</Fragment>
									)}
								</>
							)}
						</Fragment>
					);
				})}
			</div>
		</>
	);
};

export default NavigationList;
