import React, { SetStateAction } from "react";

// Components
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Category } from "../../../redux/category/types";

// Icons
import { Search } from "lucide-react";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

type LevelOneFormProps = {
	categories: Category[];
	_onOpenModalPress: (category: Category) => void;
	_onOpenDeleteModalPress: (category: Category) => void;
	_onOpenAddCategoryModal: React.Dispatch<SetStateAction<boolean>>;
	setSearchValue: React.Dispatch<SetStateAction<string>>;
};

const LevelOneForm = ({
	categories,
	setSearchValue,
	_onOpenModalPress,
	_onOpenDeleteModalPress,
	_onOpenAddCategoryModal,
}: LevelOneFormProps) => {
	const allowedAccess = useCheckAccessEditability();

	return (
		<div className="col-span-3 flex flex-col gap-6 h-[35em] lg:h-[70vh] xl:h-[72vh] w-full items-center lg:col-span-1">
			<Label className="text-[0.825rem] sm:text-sm py-2">Level 1</Label>

			<div className="w-full">
				<div className="relative w-full">
					<Search className="absolute left-0 bottom-0 transform -translate-y-1/2 w-[14px] h-[14px]  lg:w-4 lg:h-4 text-secondary" />
					<Input
						placeholder="Search"
						className="pl-6 lg:pl-8 text-[0.65rem] sm:text-sm"
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				</div>
			</div>

			<Button
				size="sm"
				className={`${!allowedAccess.includes("can_add") && "hidden"} w-fit`}
				onClick={() => _onOpenAddCategoryModal(true)}
			>
				Add Category
			</Button>

			<div className="flex flex-col h-[25em] max-h-[25em] lg:h-[40em] lg:max-h-[40em] px-2 w-full overflow-auto">
				{categories?.map((category) => {
					return (
						<div
							key={category.id}
							className="h-[6em] lg:max-h-[4em] w-full flex gap-4 items-center p-2 hover:bg-disabled rounded-lg"
						>
							<div
								className="bg-cover bg-center aspect-square h-full w-20 lg:w-12 rounded-[2px]"
								style={{
									backgroundImage: `url('${
										category.image === "https://example.com/image.png" ||
										!category.image
											? "/assets/categories/default_category.png"
											: category.image
									}')`,
								}}
							/>
							<Label variant="description">{category.name}</Label>
							<div className="flex flex-row gap-3 ml-auto">
								<Button
									variant="icon"
									size="icon"
									className={`${
										!allowedAccess.includes("can_edit") && "hidden"
									}`}
									onClick={() => _onOpenModalPress(category)}
								>
									<TableEditIconSvg fill="#292D32" className="h-5 w-5" />
								</Button>

								<Button
									variant="icon"
									size="icon"
									className={`${
										!allowedAccess.includes("can_delete") && "hidden"
									}`}
									onClick={() => _onOpenDeleteModalPress(category)}
								>
									<TableDeleteIconSvg fill="#292D32" className="h-5 w-5" />
								</Button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LevelOneForm;
