import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";

// ? Mainly for handling manually typed path
export const useCheckPermissions = (): boolean | null => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname.split("/");
	const module = path[1].toLowerCase().trim();
	const { permissions } = useAuth();

	const [hasAccess, setHasAccess] = useState<boolean | null>(null);

	useEffect(() => {
		if (!!permissions) {
			const currentModuleAction = permissions.filter((permission) => {
				return permission.module.split(" ")[0].toLowerCase() == module;
			});

			if (currentModuleAction.length > 0) {
				const access = Object.values(currentModuleAction[0]?.access);

				if (path.length > 2) {
					// CREATE AND UPDATE
					if (path[2] != "add") {
						const permission = access.some((item) => {
							return item.permission == "can_edit" && item.status == true;
						});

						setHasAccess(permission);
					}

					if (path[2] == "add") {
						const permission = access.some((item) => {
							return item.permission == "can_add" && item.status == true;
						});

						setHasAccess(permission);
					}
				} else {
					const permission = access.some((item) => {
						return item.permission == "can_view" && item.status == true;
					});

					setHasAccess(permission);
				}
			} else {
				console.log("useCheckPermission navigate");
				navigate("/access-denied");
			}
		}
	}, [location, permissions]);

	return hasAccess;
};

export const useCheckAccessEditability = (): string[] => {
	const location = useLocation();
	const path = location.pathname.split("/");
	const module = path[1].toLowerCase().trim();
	const { permissions } = useAuth();

	const [accessAllowed, setAccessAllowed] = useState<string[]>([]);

	useEffect(() => {
		if (!!permissions) {
			const currentModuleAction = permissions.filter((permission) => {
				return permission.module.split(" ")[0].toLowerCase() == module;
			});

			// const currentModuleAccess = currentModuleAction[0].access;
			const allowedAccess: string[] = Object.values(
				currentModuleAction[0].access
			).map((access) => {
				return access.permission;
			});
			setAccessAllowed(allowedAccess);
		}
	}, [location, permissions]);

	return accessAllowed;
};
