import { jwtDecode } from "jwt-decode";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Access, Permission } from "src/redux/role/types";
import { CustomJwtPayload } from "src/redux/types";

type AuthProviderValue = {
	permissions: Permission[] | undefined;
	token: string;
	_logout: () => void;
	setCredentials: (permission: Permission[], token: string) => void;
};

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

AuthContext.displayName = "AuthContext";

export const useAuth = () => {
	const authContext = useContext(AuthContext);

	if (!authContext) {
		throw new Error("useAuth must be used within AuthProvider");
	}

	return authContext;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [token, setToken] = useState<string>(
		localStorage.getItem("ACCESS_TOKEN") ?? ""
	);
	const [permissions, setPermissions] = useState<Permission[]>();

	const setCredentials = (permissions: Permission[], token: string) => {
		setPermissions(permissions);
		setToken(token);
	};

	const _logout = async () => {
		const _removeUser = () => {
			localStorage.clear();
		};

		_removeUser();
		window.location.href = "/auth";
	};

	useEffect(() => {
		const permissionToken = localStorage.getItem("PERMISSION_TOKEN");

		if (permissionToken) {
			const decodedPermissions = jwtDecode<CustomJwtPayload>(
				String(permissionToken) || ""
			);
			const { permissions: permissionArr } = decodedPermissions;

			const permissionsAllowed = permissionArr
				.map((permission) => {
					const filteredAccess: Access = Object.entries(
						permission.access
					).reduce((acc, [key, rolePermission]) => {
						if (rolePermission.status === true) {
							acc[key] = rolePermission;
						}
						return acc;
					}, {} as Access);

					return {
						...permission,
						access: filteredAccess,
					};
				})
				.filter((permission) => Object.keys(permission.access).length > 0);

			setPermissions(permissionsAllowed);
		}
	}, []);

	const value = useMemo(() => {
		return {
			permissions,
			token,
			_logout,
			setCredentials,
		};
	}, [token, permissions]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
