import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

type EmailBody = {
	to: string;
	subject: string;
	body: string;
};

type EmailResponse = {
	message: string;
	status: number;
};

// Create the media API slice
export const emailApi = createApi({
	reducerPath: "emailApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		sendEmail: builder.mutation<EmailResponse, EmailBody>({
			query: (email) => ({
				url: "/email/sendemail",
				method: "POST",
				body: email,
			}),
			transformResponse: (data: string) => {
				return {
					message: data,
					status: 200,
				};
			},
		}),
	}),
});

// Export hooks for usage in components
export const { useSendEmailMutation } = emailApi;
