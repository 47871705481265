import { useState } from "react";
import { useUploadMediaMutation } from "src/redux/mediaApiSlice";

interface UseFileUploadResult {
	uploadImage: (
		files: File[],
		moduleName: string
	) => Promise<string | undefined>;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
	uploadedImagePath: string | null;
}

export const useFileUpload = (): UseFileUploadResult => {
	const [uploadMedia, { isLoading, isError, isSuccess }] =
		useUploadMediaMutation();
	const [uploadedImagePath, setUploadedImagePath] = useState<string | null>(
		null
	);

	const uploadImage = async (
		files: File[],
		moduleName: string
	): Promise<string | undefined> => {
		try {
			const result = await uploadMedia({
				files,
				module: moduleName,
			});

			if (!result.data) {
				throw result.error;
			}

			if (result.data) {
				const file = result.data.filePath;

				console.log("set me");
				return `https://staging.mr.diy.api.suites.digital/file-upload/images/${file}`;
			}
		} catch (error) {
			// Handle upload error
			console.error("File upload failed:", error);

			// return "There is an issue with the file you're uploading. Please recheck your image.";
		}
	};

	return {
		uploadImage,
		isLoading,
		isError,
		isSuccess,
		uploadedImagePath,
	};
};
