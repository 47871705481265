import errorIcon from "src/assets/images/error-icon.png";
import mrDiyIcon from "src/assets/images/MRDIYlogo.png";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";

// TODO create server issue page
const ServerIssuePage = () => {
	const navigate = useNavigate();

	return (
		<div
			className="grid place-items-center max-h-screen text-center bg-upload"
			style={{ height: "calc(100vh - 80px)" }}
		>
			<div className="flex flex-col min-w-[320px] sm:min-w-[400px] md:min-w-[550px] 2xl:min-w-[800px] max-h-[800px] space-y-8 sm:space-y-10 md:space-y-8 z-10 mt-8 xl:mt-10">
				<div
					style={{ backgroundImage: `url(${mrDiyIcon})` }}
					className="h-[60px] w-[140px] md:h-[80px] md:w-[180px] bg-contain bg-center bg-no-repeat mx-auto"
				></div>
				<div className="space-y-2 sm:space-y-4 xl:space-y-4">
					<h1 className="text-3xl font-semibold sm:text-4xl lg:text-3xl xl:text-4xl">
						Oops! 500 Server Issue
					</h1>
					<p className="text-xs font-normal sm:text-sm xl:text-sm">
						Something went wrong on our end. Please try again later.
					</p>
				</div>
				<div
					style={{ backgroundImage: `url(${errorIcon})` }}
					className="h-[160px] w-[200px] md:h-[180px] md:w-[210px] 2xl:h-[220px] 2xl:w-[250px] bg-contain bg-center bg-no-repeat mx-auto"
				></div>
				<div className="max-w-[300px] sm:max-w-[480px] md:max-w-[600px] xl:max-w-full h-fit text-center mx-auto space-y-2 md:space-y-4">
					<p className="text-xs font-normal sm:text-sm xl:text-sm">
						It seems we're experiencing some technical difficulties. Please try
						refreshing the page or come back later.
					</p>
					<p className="text-xs font-normal sm:text-sm xl:text-sm">
						If the issue persists, feel free to contact our support team for
						assistance.
					</p>
				</div>

				<div className="h-fit">
					<Button
						size="sm"
						variant="default"
						className="w-fit px-10"
						type="button"
						onClick={() => navigate(-1)}
					>
						Go back
					</Button>
				</div>
			</div>
			<div className="bg-disabled-foreground opacity-[40%] absolute bottom-0 h-1/2 xl:h-[45%] w-full z-5"></div>
		</div>
	);
};

export default ServerIssuePage;
