import * as React from "react";

// Library
import {
	ColumnDef,
	OnChangeFn,
	Row,
	RowSelectionState,
	SortingState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import {
	closestCorners,
	DndContext,
	DragEndEvent,
	UniqueIdentifier,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// Components
import { Button } from "src/shared/components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/shared/components/ui/table";
import { Label } from "src/shared/components/ui/label";
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationLink,
} from "src/shared/components/ui/pagination";

import { renderPaginationNumbers } from "src/shared/helpers/computePagination";

// Icons
import { ChevronLeft, ChevronRight, GripVerticalIcon } from "lucide-react";

type RowContextProps = {
	setActivatorNodeRef?: (element: HTMLElement | null) => void;
	listeners?: SyntheticListenerMap;
};

const RowContext = React.createContext<RowContextProps>({});

type DraggableRowProps<T> = {
	row: Row<T>;
	id: UniqueIdentifier;
};

const DraggableRow = <T,>({ row, id }: DraggableRowProps<T>) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id });

	const style: React.CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
		...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
	};

	const contextValue = React.useMemo<RowContextProps>(
		() => ({ setActivatorNodeRef, listeners }),
		[setActivatorNodeRef, listeners]
	);

	return (
		<RowContext.Provider value={contextValue}>
			<TableRow
				key={row.id}
				data-state={row.getIsSelected() && "selected"}
				ref={setNodeRef}
				{...attributes}
				style={style}
				className="border-none text-xs hover:bg-transparent hover:cursor-default"
			>
				{row.getVisibleCells().map((cell) => (
					<TableCell
						key={cell.id}
						className="p-0 py-4 border-none text-secondary"
					>
						<div className="mx-2 w-auto 2xl:text-sm">
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</div>
					</TableCell>
				))}
			</TableRow>
		</RowContext.Provider>
	);
};

// * Button / Icon for dragging rows
// * Don't remove here need row context to access listeners and setActivatorNodeRef
export const DragHandle = () => {
	const { setActivatorNodeRef, listeners } = React.useContext(RowContext);
	const [isDragging, setIsDragging] = React.useState<boolean>(false);

	const handleMouseDown = () => {
		setIsDragging(true);
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	return (
		<Button
			style={{ cursor: isDragging ? "grabbing" : "grab" }}
			ref={setActivatorNodeRef}
			{...listeners}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			className="m-0 p-0 w-fit text-secondary bg-transparent shadow-none border-none hover:border-none hover:bg-transparent hover:text-black"
			size={"icon"}
		>
			<GripVerticalIcon
				className="h-8 w-6 text-lg"
				strokeWidth={1.75}
				absoluteStrokeWidth
			/>
		</Button>
	);
};

type DataTableProps<T> = {
	data: T[];
	columns: ColumnDef<T>[];
	hasPagination?: boolean;
	emptyTableText?: string;
	onSelectionChange?: (newSelectedRows: T[]) => void;
};

// * Extends {id} to tell typescript that this generic will always have id
export function DataTable<T extends { id: UniqueIdentifier }>({
	data,
	columns,
	hasPagination = false,
	emptyTableText = "No results.",
	onSelectionChange,
}: DataTableProps<T>) {
	// Store current page index in state to persist it
	const [persistedPageIndex, setPersistedPageIndex] = React.useState(0);
	const previousDataLength = React.useRef(data.length);

	const [sorting, setSorting] = React.useState<SortingState>([]);

	const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

	const [stateData, setStateData] = React.useState<T[]>(data);

	const getDataPos = (id: UniqueIdentifier): number =>
		stateData.findIndex((data) => data.id === id);

	const _handleSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
		setSorting((prev) => {
			// Handle updater function
			if (typeof updaterOrValue === "function") {
				const newSort = updaterOrValue(prev);

				if (prev.length > 0 && newSort.length > 0) {
					const currentSort = prev[0];
					const nextSort = newSort[0];

					// If same column and currently descending, clear sort
					if (currentSort.id === nextSort.id && currentSort.desc) {
						return [];
					}
				}
				return newSort;
			}

			// Handle direct value
			const newSort = updaterOrValue;
			if (prev.length > 0 && newSort.length > 0) {
				const currentSort = prev[0];
				const nextSort = newSort[0];

				// If same column and currently descending, clear sort
				if (currentSort.id === nextSort.id && currentSort.desc) {
					return [];
				}
			}
			return newSort;
		});
	};

	// Function for swapping row's 'order' field
	const _handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (!over) return;

		if (active.id === over.id) return;

		setStateData((data: T[]) => {
			const originalPos = getDataPos(active.id);
			const newPos = getDataPos(over.id);

			// Swap their positions in the array
			const updatedData = arrayMove(data, originalPos, newPos);

			return updatedData.map((item, index) => ({
				...item,
				order: index + 1,
			}));
		});
	};

	const table = useReactTable({
		data: stateData,
		columns,
		onSortingChange: _handleSortingChange,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			rowSelection,
			pagination: {
				pageIndex: persistedPageIndex,
				pageSize: 10,
			},
		},
		pageCount: Math.ceil(data.length / 10),
	});

	React.useEffect(() => {
		const newPageIndex = table.getState().pagination.pageIndex;
		setPersistedPageIndex(newPageIndex);
	}, [table.getState().pagination.pageIndex]);

	React.useEffect(() => {
		if (data.length !== stateData.length) {
			table.resetRowSelection(true);
		}

		// Handle pagination when data changes
		if (data.length < previousDataLength.current) {
			const currentPageIndex = persistedPageIndex;
			const totalPages = Math.ceil(data.length / 10);

			// Calculate items on current page
			const startIndex = currentPageIndex * 10;
			const itemsOnCurrentPage = data.slice(startIndex, startIndex + 10).length;

			// If there are no items on the current page and we're not on the first page,
			// go to the previous page
			if (itemsOnCurrentPage === 0 && currentPageIndex > 0) {
				setPersistedPageIndex(currentPageIndex - 1);
				table.setPageIndex(currentPageIndex - 1);
			} else if (currentPageIndex >= totalPages) {
				// If current page index is beyond total pages, go to last page
				const newPageIndex = Math.max(0, totalPages - 1);
				setPersistedPageIndex(newPageIndex);
				table.setPageIndex(newPageIndex);
			}
		}

		previousDataLength.current = data.length;
		setStateData(data);
	}, [data]);

	React.useEffect(() => {
		const selectedRows = table
			.getSelectedRowModel()
			.rows.map((row) => row.original);

		if (onSelectionChange) {
			onSelectionChange(selectedRows);
		}
	}, [table.getSelectedRowModel().rows, onSelectionChange]);

	return (
		<>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow
							key={headerGroup.id}
							className="text-xs border-zinc-400 hover:bg-transparent hover:cursor-default"
						>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className="p-0 py-4 border-none text-secondary"
									>
										{header.isPlaceholder ? null : (
											<div className="mx-2 w-auto">
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
											</div>
										)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					<DndContext
						collisionDetection={closestCorners}
						onDragEnd={_handleDragEnd}
					>
						<SortableContext
							items={table.getRowModel().rows.map((row) => row.original.id)}
							strategy={verticalListSortingStrategy}
						>
							{/* <SortableContext
							items={stateData.map((payment) => payment.id)}
							strategy={verticalListSortingStrategy}
						> */}
							{table.getRowModel().rows?.length ? (
								table
									.getRowModel()
									.rows.map((row, index) => (
										<DraggableRow
											key={row.original.id}
											row={row}
											id={row.original.id}
										/>
									))
							) : (
								<TableRow>
									<TableCell
										colSpan={columns.length}
										className="h-12 py-16 text-center"
									>
										{emptyTableText}
									</TableCell>
								</TableRow>
							)}
						</SortableContext>
					</DndContext>
				</TableBody>
			</Table>
			{hasPagination && (
				<div className="flex items-end justify-end gap-2 w-full mt-4">
					<div>
						<Pagination className="flex flex-col h-fit items-center">
							<Label className="text-sm text-secondary font-light mb-2">
								Showing{" "}
								<span className="font-bold">
									{Math.min((persistedPageIndex + 1) * 10, stateData.length) -
										persistedPageIndex * 10}
									{/* {table.getRowModel().rows.length} */}
								</span>{" "}
								out of <span className="font-bold">{stateData.length}</span>{" "}
								results
							</Label>
							<PaginationContent>
								{/* Previous Page */}
								<PaginationItem>
									<PaginationLink
										href="#"
										className={`px-2 ${
											// !table.getCanPreviousPage()
											persistedPageIndex === 0
												? "cursor-not-allowed text-muted-foreground hover:bg-transparent active:shadow-none"
												: "hover:bg-transparent active:shadow-none"
										}`}
										onClick={(e) => {
											// e.preventDefault();
											// if (table.getCanPreviousPage()) {
											// 	table.previousPage();
											// }

											e.preventDefault();
											if (persistedPageIndex > 0) {
												const newIndex = persistedPageIndex - 1;
												setPersistedPageIndex(newIndex);
												table.setPageIndex(newIndex);
											}
										}}
									>
										<ChevronLeft className="h-4 w-4 hover:bg-secondary" />
									</PaginationLink>
								</PaginationItem>

								{renderPaginationNumbers(table).map((item, index) => {
									if (item === "...") {
										return (
											<PaginationItem key={index}>
												<span className="px-3 hover:cursor-default">...</span>
											</PaginationItem>
										);
									}

									const pageIndex = typeof item === "number" ? item - 1 : -1; // Adjusted for 0-based index

									return (
										<PaginationItem key={index}>
											<PaginationLink
												href="#"
												onClick={(e) => {
													e.preventDefault();
													if (pageIndex >= 0) {
														setPersistedPageIndex(pageIndex);
														table.setPageIndex(pageIndex);
													}
													// e.preventDefault();
													// if (pageIndex >= 0) {
													// 	table.setPageIndex(pageIndex);
													// }
												}}
												className={`px-3 hover:bg-transparent ${
													// table.getState().pagination.pageIndex === pageIndex
													persistedPageIndex === pageIndex
														? "text-black font-semibold"
														: "text-muted-foreground"
												} bg-transparent border-none cursor-pointer`}
												isActive={persistedPageIndex === pageIndex}
												// isActive={
												// 	table.getState().pagination.pageIndex === pageIndex
												// }
											>
												{item}
											</PaginationLink>
										</PaginationItem>
									);
								})}

								{/* Next Page */}
								<PaginationItem>
									<PaginationLink
										href="#"
										className={`px-2 ${
											// !table.getCanNextPage()
											persistedPageIndex >= Math.ceil(stateData.length / 10) - 1
												? "cursor-not-allowed text-muted-foreground hover:bg-transparent active:shadow-none"
												: "hover:bg-transparent active:shadow-none"
										}`}
										onClick={(e) => {
											e.preventDefault();
											if (
												persistedPageIndex <
												Math.ceil(stateData.length / 10) - 1
											) {
												const newIndex = persistedPageIndex + 1;
												setPersistedPageIndex(newIndex);
												table.setPageIndex(newIndex);
											}
											// e.preventDefault();
											// if (table.getCanNextPage()) {
											// 	table.nextPage();
											// }
										}}
									>
										<ChevronRight className="h-4 w-4 hover:bg-secondary" />
									</PaginationLink>
								</PaginationItem>
							</PaginationContent>
						</Pagination>
					</div>
				</div>
			)}
		</>
	);
}
