import { configureStore } from "@reduxjs/toolkit";
import { bannersApi } from "./banner/apiSlice";
import { authApi } from "./auth/apiSlice";
import { customersApi } from "./customer/apiSlice";
import { accountsApi } from "./account/apiSlice";
import { productsApi } from "src/redux/product/apiSlice";
import { usersApi } from "./user/apiSlice";
import { categoryApi } from "src/redux/category/apiSlice";
import { storesApi } from "./store/apiSlice";
import { rolesApi } from "./role/apiSlice";
import { libraryApi } from "./library/apiSlice";
import sidebarSlice from "./slices/sidebarSlice";
import locationSlice from "./store/locationSlice";
import errorSlice from "./slices/errorSlice";
import { mediaApi } from "./mediaApiSlice";
import { emailApi } from "./emailApiSlice";

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[bannersApi.reducerPath]: bannersApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer,
		[rolesApi.reducerPath]: rolesApi.reducer,
		[customersApi.reducerPath]: customersApi.reducer,
		[storesApi.reducerPath]: storesApi.reducer,
		[accountsApi.reducerPath]: accountsApi.reducer,
		[productsApi.reducerPath]: productsApi.reducer,
		[categoryApi.reducerPath]: categoryApi.reducer,
		[libraryApi.reducerPath]: libraryApi.reducer,
		[mediaApi.reducerPath]: mediaApi.reducer,
		[emailApi.reducerPath]: emailApi.reducer,
		location: locationSlice,
		sidebar: sidebarSlice,
		error: errorSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(authApi.middleware)
			.concat(bannersApi.middleware)
			.concat(usersApi.middleware)
			.concat(rolesApi.middleware)
			.concat(customersApi.middleware)
			.concat(storesApi.middleware)
			.concat(accountsApi.middleware)
			.concat(productsApi.middleware)
			.concat(categoryApi.middleware)
			.concat(mediaApi.middleware)
			.concat(emailApi.middleware)
			.concat(libraryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
