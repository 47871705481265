export function generateRandomPassword(length = 12): string {
	const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
	const numbers = "0123456789";
	const specialCharacters = "@$!%*?&";
	const allCharacters =
		upperCaseLetters + lowerCaseLetters + numbers + specialCharacters;

	let password = "";

	// Ensure at least one uppercase letter
	password +=
		upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
	// Ensure at least one special character
	password +=
		specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
	// Ensure at least one number
	password += numbers[Math.floor(Math.random() * numbers.length)];
	// Ensure at least one lowercase letter
	password +=
		lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];

	// Fill the rest of the password with random characters
	for (let i = password.length; i < length; i++) {
		password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
	}

	// Shuffle the password to make it more random
	password = password
		.split("")
		.sort(() => Math.random() - 0.5)
		.join("");

	// Ensure the password satisfies the regex
	const regex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
	if (!regex.test(password)) {
		// Regenerate the password if it doesn't satisfy the regex
		return generateRandomPassword(length);
	}

	return password;
}
