// Types
import { Library } from "src/redux/library/types";
import { UseFormReturn } from "react-hook-form";

// Components
import { Input } from "src/shared/components/ui/input";
import { FormField } from "src/shared/components/form/Form";
import { Label } from "src/shared/components/ui/label";
import FormController from "src/shared/components/form/FormController";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

type ContactInfoFormProp = {
	form: UseFormReturn<Library, any, undefined>;
};

const ContactInfoForm = ({ form }: ContactInfoFormProp) => {
	const allowedAccess = useCheckAccessEditability();

	return (
		<SectionContainer>
			<SectionHeader>
				<div className="flex flex-col gap-2">
					<Label variant="header">Contact Info</Label>
					<Label variant="description">
						Displayed on the Help and Support Page of the app.
					</Label>
				</div>
			</SectionHeader>
			<div className="grid xl:grid-cols-2 w-full gap-2">
				<FormField
					control={form.control}
					name="contact_info.email_address"
					render={({ field }) => (
						<FormController label="Email Address" required>
							<Input
								id="emailcontact_info.email_address"
								disabled={!allowedAccess.includes("can_edit")}
								{...field}
							/>
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="contact_info.mobile_no"
					render={({ field }) => (
						<FormController label="Mobile Number" required>
							<div className="relative">
								<Label className="absolute left-0 bottom-[5px] text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight whitespace-nowrap">
									+63
								</Label>
								<Input
									id="mobcontact_info.mobile_no"
									{...field}
									disabled={!allowedAccess.includes("can_edit")}
									type="number"
									className="pl-6 lg:pl-8"
								/>
							</div>
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="contact_info.caremail"
					render={({ field }) => (
						<FormController label="Caremail" required>
							<Input
								id="caremail"
								disabled={!allowedAccess.includes("can_edit")}
								{...field}
							/>
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="contact_info.main_office_address"
					render={({ field }) => (
						<FormController label="Main Office Address" required>
							<Input
								id="contact_info.main_office_address"
								disabled={!allowedAccess.includes("can_edit")}
								{...field}
							/>
						</FormController>
					)}
				/>
			</div>
		</SectionContainer>
	);
};

export default ContactInfoForm;
