import React, {
	ElementType,
	forwardRef,
	HTMLProps,
	PropsWithChildren,
} from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import { cn } from "src/lib/utils";

type UploadButtonProps = HTMLProps<HTMLInputElement> & {
	className?: string;
};

const UploadButton = forwardRef<
	HTMLInputElement,
	PropsWithChildren<UploadButtonProps>
>(({ children, className, ...props }, ref) => {
	return (
		<div className={cn("", className)}>
			<Input id="input" type="file" className="hidden" {...props} ref={ref} />
			<Button
				type="button"
				onClick={() => document.getElementById("input")?.click()}
				variant="upload"
			>
				{children}
			</Button>
		</div>
	);
});

export default UploadButton;
