import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";
import { setErrorState } from "src/redux/slices/errorSlice";
import { RootState } from "src/redux/store";
import { useCheckPermissions } from "../hooks/useCheckPermissions";

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const token = localStorage.getItem("ACCESS_TOKEN");
	const dispatch = useDispatch();
	const access = useCheckPermissions();

	const errorState = useSelector((state: RootState) => state.error);

	useEffect(() => {
		if (errorState.status === 404) {
			dispatch(setErrorState({ data: {}, status: 0 }));
		}
	}, [errorState]);

	if (!token) {
		return <Navigate to="/auth" replace />;
	}

	if (access == null) {
		return <div></div>;
	}

	if (access == false) {
		return <Navigate to="/access-denied" />;
	}

	return <>{children}</>;
};

export default ProtectedRoute;
