import {
	FormEvent,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

// Utils
import { formatDate } from "src/shared/helpers/formatDate";
import { Banner } from "src/redux/banner/types";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";
import { FormDescription, FormField } from "src/shared/components/form/Form";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import FormController from "src/shared/components/form/FormController";
import CustomDateInput from "src/shared/components/date-picker/CustomDateInput";
import { isAfter } from "date-fns";

type PromoDurationSectionProps = {
	form: UseFormReturn<Banner, any, undefined>;
	noOfActiveBanners: number;
};

const PromoDurationSection = ({
	form,
	noOfActiveBanners,
}: PromoDurationSectionProps) => {
	// * Don't delete, this triggers rerenders and validations if an event happens in CustomDateInput component
	const dates = useWatch({
		control: form.control,
		name: ["startDate", "endDate"],
	});

	const [isStartDateOpen, setIsStartDateOpen] = useState<boolean>(false);
	const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false);

	const isNoExpirationDate = form.watch("noExpiration");

	// const handleDateChange =
	// 	(field: "startDate" | "endDate") =>
	// 	(value: Date | FormEvent<HTMLInputElement>) => {
	// 		const otherField = field === "startDate" ? "endDate" : "startDate";
	// 		const otherValue = form.getValues(otherField);

	// 		// Convert value endDate date string based on its type
	// 		let dateString = "";
	// 		if (value instanceof Date) {
	// 			// Adjust for timezone endDate keep the selected date
	// 			const offset = value.getTimezoneOffset();
	// 			const adjustedDate = new Date(value.getTime() - offset * 60 * 1000);
	// 			dateString = adjustedDate.toISOString().split("T")[0];
	// 		} else {
	// 			dateString = (value.target as HTMLInputElement).value;
	// 		}

	// 		// Set the value
	// 		form.setValue(field, formatDate(dateString), {
	// 			shouldDirty: true,
	// 			shouldValidate: true,
	// 		});

	// 		// Only validate if both dates have values
	// 		if (dateString && otherValue) {
	// 			const fromDate =
	// 				field === "startDate" ? dateString : form.getValues("startDate");
	// 			const toDate =
	// 				field === "endDate" ? dateString : form.getValues("endDate");

	// 			// Compare dates
	// 			if (new Date(String(toDate)) < new Date(fromDate)) {
	// 				form.setError("startDate", {
	// 					type: "manual",
	// 					message: "End date cannot be earlier than Start date.",
	// 				});
	// 			} else {
	// 				form.clearErrors(["startDate", "endDate"]);
	// 			}
	// 		}
	// 	};

	const handleDateChange = useCallback(
		(field: "startDate" | "endDate") =>
			(value: Date | FormEvent<HTMLInputElement>) => {
				const otherField = field === "startDate" ? "endDate" : "startDate";
				const otherValue = form.getValues(otherField);

				let dateString = "";
				if (value instanceof Date) {
					const offset = value.getTimezoneOffset();
					const adjustedDate = new Date(value.getTime() - offset * 60 * 1000);
					dateString = adjustedDate.toISOString().split("T")[0];
				} else {
					dateString = (value.target as HTMLInputElement).value;
				}

				form.setValue(field, formatDate(dateString), {
					shouldDirty: true,
					shouldValidate: true,
				});

				if (dateString && otherValue) {
					const fromDate =
						field === "startDate" ? dateString : form.getValues("startDate");
					const toDate =
						field === "endDate" ? dateString : form.getValues("endDate");

					if (new Date(String(toDate)) < new Date(fromDate)) {
						form.setError("startDate", {
							type: "manual",
							message: "End date cannot be earlier than Start date.",
						});
					} else {
						form.clearErrors(["startDate", "endDate"]);
					}
				}
			},
		[form] // Add form as dependency
	);

	useEffect(() => {
		if (isNoExpirationDate == true) {
			form.setValue("endDate", "", {
				shouldValidate: false,
				shouldTouch: false,
				shouldDirty: true,
			});
		}
	}, [isNoExpirationDate]);

	return (
		<div className="flex flex-col lg:flex-row lg:flex-wrap gap-4">
			<Label variant="sub_header" className="w-full">
				Promo Duration<span className="text-destructive">*</span>
			</Label>

			<div>
				<FormField
					control={form.control}
					name="startDate"
					render={({ field }) => (
						<FormController label="Start Date">
							<CustomDateInput
								{...field}
								onChange={handleDateChange("startDate")}
								value={String(field.value)}
								isCalendarOpen={isStartDateOpen}
								setIsCalendarOpen={setIsStartDateOpen}
							/>
						</FormController>
					)}
				/>
			</div>

			<div className="flex flex-col-reverse gap-2 lg:flex-row lg:gap-4">
				<FormField
					control={form.control}
					name="endDate"
					render={({ field }) => {
						return (
							<FormController label="End Date">
								<CustomDateInput
									{...field}
									onChange={handleDateChange("endDate")}
									value={!field.value ? "" : String(field.value)}
									isCalendarOpen={isEndDateOpen}
									setIsCalendarOpen={setIsEndDateOpen}
									startDate={!dates[0] ? undefined : new Date(dates[0])}
									disabled={isNoExpirationDate}
								/>
							</FormController>
						);
					}}
				/>

				<FormField
					control={form.control}
					name="noExpiration"
					render={({ field }) => (
						<FormController label="Expiration">
							<div className="flex gap-1 items-center">
								<Checkbox
									className="w-3 h-3 lg:w-4 lg:h-4"
									checked={field.value} // Use field.value directly
									onCheckedChange={field.onChange} // Update form state directly
								/>
								<Label variant="description">No Expiration</Label>
							</div>
						</FormController>
					)}
				/>

				{/* <FormField
					control={form.control}
					name="noExpiration"
					render={({ field }) => {
						return (
							<FormController label="Expiration">
								<div className="flex gap-1 items-center">
									<Checkbox
										className="w-3 h-3 lg:w-4 lg:h-4"
										checked={isNoExpirationDate}
										onCheckedChange={() => {
											setIsNoExpirationDate(!isNoExpirationDate);
											form.setValue("noExpiration", !isNoExpirationDate, {
												shouldDirty: true,
											});
										}}
									/>
									<Label variant="description">No Expiration</Label>
								</div>
							</FormController>
						);
					}}
				/> */}
			</div>

			<div className="lg:flex-1">
				<FormField
					control={form.control}
					name="status"
					render={({ field }) => (
						<FormController
							label="Status"
							// description={``}
						>
							<div className="h-fit flex flex-col gap-1">
								<RadioGroup
									onValueChange={(value) => field.onChange(Number(value))}
									value={String(field.value)}
									className="flex gap-4"
								>
									<div className="flex items-center space-x-2">
										<RadioGroupItem
											value="1"
											id="active"
											disabled={noOfActiveBanners >= 8}
										/>
										<Label
											htmlFor="active"
											variant="form"
											className={`${
												noOfActiveBanners >= 8
													? "text-disabled-foreground"
													: "text-secondary"
											}`}
										>
											Active
										</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="0" id="inactive" />
										<Label
											htmlFor="inactive"
											variant="form"
											className="text-secondary"
										>
											Inactive
										</Label>
									</div>
								</RadioGroup>

								<FormDescription className="leading-tight tracking-tight text-secondary mt-1">
									<Label variant="description">
										* Inactive promo banners will have their Display Order
										nullified by default and will not reflect in the app.
									</Label>
								</FormDescription>

								<FormDescription className="leading-tight tracking-tight text-secondary">
									<Label variant="description">
										* The maximum number of active banners allowed is 8. If you
										have already reached this limit, please remove one or more
										active banners before adding a new one.
									</Label>
								</FormDescription>
							</div>
						</FormController>
					)}
				/>
			</div>
		</div>
	);
};

export default PromoDurationSection;
