import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	useAddRoleMutation,
	useGetPermissionsQuery,
	useGetSelectedRoleQuery,
	useUpdateRoleMutation,
} from "../../redux/role/apiSlice";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "src/shared/hooks/useToast";

// Libraries
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import {
	Access,
	Permission,
	Permissions,
	RolePermission,
	RolePermissionReqBody,
	RoleRequestBody,
} from "../../redux/role/types";
import { RoleFormSchema } from "../../redux/role/schema";
import { formatDateToIso } from "src/shared/helpers/formatDate";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { ToastClose } from "src/shared/components/ui/toast";
import { Form, FormField, FormMessage } from "src/shared/components/form/Form";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

// Icons
import { X } from "lucide-react";

const RoleForm = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { roleId } = useParams<{ roleId: string }>();

	const { data: selectedRole, isLoading: isSelectedRoleFetching } =
		useGetSelectedRoleQuery(Number(roleId));
	const { data: permissionsArray = [], isLoading: isPermissionsLoading } =
		useGetPermissionsQuery();

	const [
		_updateRolesAndPermissions,
		{ isLoading: isRolesAndPermissionsUpdateLoading },
	] = useUpdateRoleMutation();
	const [
		_addRolesAndPermissions,
		{ isLoading: isRolesAndPermissionsAddLoading },
	] = useAddRoleMutation();

	// Formatter for permissions from backend
	const convertPermissionToRolePermission = (array: Permissions[]) => {
		// Create dynamic access object based on permissions array
		const finalAccess = array.reduce((acc, permission) => {
			const key = permission.permission.replace("can_", "");
			acc[key] = {
				permission: permission.permission,
				permission_id: permission.id,
				status: false,
			};

			return acc;
		}, {} as Record<string, RolePermission>);

		return finalAccess;
	};

	const access = useMemo(() => {
		return convertPermissionToRolePermission(permissionsArray);
	}, [permissionsArray]);

	const MODULES = useMemo(() => {
		return [
			{
				module_id: 1,
				module: "Banner Management",
				access: { ...access },
			},
			{
				module_id: 2,
				module: "Users List",
				access: { ...access },
			},
			{
				module_id: 3,
				module: "Roles & Permission",
				access: { ...access },
			},
			{
				module_id: 4,
				module: "Customer Management",
				access: Object.fromEntries(
					Object.entries(access).filter(([key]) => !key.startsWith("add"))
				),
			},
			{
				module_id: 5,
				module: "Product Management",
				access: { ...access },
			},
			{
				module_id: 6,
				module: "Category Management",
				access: { ...access },
			},
			{
				module_id: 7,
				module: "Store Management",
				access: { ...access },
			},
			{
				module_id: 8,
				module: "Library",
				access: Object.fromEntries(
					Object.entries(access).filter(
						([key]) => !key.startsWith("add") && !key.startsWith("delete")
					)
				),
			},
			{
				module_id: 9,
				module: "Account Settings",
				access: Object.fromEntries(
					Object.entries(access).filter(
						([key]) => !key.startsWith("add") && !key.startsWith("delete")
					)
				),
			},
		];
	}, [access]);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const [isAllAccessFalse, setIsAllAccessFalse] = useState(false);
	const [roles, setRoles] = useState<{
		permissions: Permission[];
	}>({
		permissions: [...MODULES],
	});
	const [rolesPermission, setRolesPermission] = useState<
		RolePermissionReqBody[]
	>([]);

	const form = useForm({
		resolver: zodResolver(RoleFormSchema),
		defaultValues: {
			rolename: "",
			role_description: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/roles");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/roles");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		const allAccessFalse = roles.permissions.every((permission: Permission) =>
			Object.values(permission.access).every(
				(access) => access.status === false
			)
		);
		if (!allAccessFalse) {
			setIsSaveModalOpen(true);
		} else {
			setIsAllAccessFalse(true);
		}
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: z.infer<typeof RoleFormSchema>) => {
		try {
			if (selectedRole) {
				const rolesPermissionsRequestBody = rolesPermission.map((i) => {
					return {
						id: i.id,
						module_id: i.module_id,
						permission_id: i.permission_id,
						status: i.status == false ? 0 : 1,
						created_from: 101,
						updated_from: 102,
						created_by: selectedRole?.created_by,
						updated_by: Number(localStorage.getItem("USER_ID")),
						country_code: "US",
					};
				});

				const updatedRole: RoleRequestBody = {
					id: selectedRole.id,
					rolename: values.rolename,
					role_description: values.role_description,
					status: selectedRole?.status,
					created_date: selectedRole?.created_date,
					created_from: selectedRole?.created_from,
					updated_from: 1,
					created_by: selectedRole?.created_by,
					updated_by: Number(localStorage.getItem("USER_ID")),
					country_code: "US",
					role_permissions: rolesPermissionsRequestBody,
				};

				const response = await _updateRolesAndPermissions(updatedRole);

				if (response.error) {
					throw response.error;
				}
			} else {
				const rolesPermissionsRequestBody = rolesPermission.map((i) => {
					return {
						id: null,
						module_id: i.module_id,
						permission_id: i.permission_id,
						status: i.status == false ? 0 : 1,
						created_from: 101,
						updated_from: 101,
						created_by: Number(localStorage.getItem("USER_ID")),
						updated_by: Number(localStorage.getItem("USER_ID")),
						country_code: "US",
					};
				});

				const newRole: RoleRequestBody = {
					rolename: values.rolename,
					role_description: values.role_description,
					status: 1,
					created_date: String(formatDateToIso(new Date())),
					created_from: 1,
					updated_from: 1,
					created_by: Number(localStorage.getItem("USER_ID")),
					updated_by: Number(localStorage.getItem("USER_ID")),
					country_code: "US",
					role_permissions: rolesPermissionsRequestBody,
				};

				const response = await _addRolesAndPermissions(newRole);

				if (response.error) {
					throw response.error;
				}
			}

			setIsSaveModalOpen(false);
			setTimeout(() => {
				setIsNotifModalOpen(true);
			}, 500);
		} catch (error) {
			setIsSaveModalOpen(false);
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/roles");
	};

	const _handlePermissionChange = (
		moduleId: number,
		moduleName: string,
		accessType: keyof Access,
		checked: boolean,
		accessValue: RolePermission
	) => {
		setRolesPermission((prevValue) => {
			// Find if this permission exists in backend data
			const matchingModule = selectedRole?.permissions.find(
				(p) => p.module === moduleName
			);

			// If found in backend, get the ID from the matching access type
			let existingId: number | undefined;
			if (matchingModule?.access) {
				const accessKey = accessValue.permission.replace(
					"can_",
					""
				) as keyof Access;
				existingId = matchingModule.access[accessKey]?.id;
			}

			// Try to find if this permission already exists in our array
			const existingIndex = prevValue.findIndex(
				(item) =>
					item.module_id === moduleId &&
					item.permission_id === accessValue.permission_id &&
					item.permission === accessValue.permission
			);

			let updatedValue;

			if (existingIndex !== -1) {
				// Update existing permission
				updatedValue = prevValue.map((item, index) =>
					index === existingIndex
						? {
								...item,
								id: existingId == undefined ? null : existingId, // Add backend ID if it exists
								status: checked,
								permission: accessValue.permission,
								module: moduleName,
						  }
						: item
				);
			} else {
				// Add new permission
				updatedValue = [
					...prevValue,
					{
						id: existingId == undefined ? null : existingId, // Add backend ID if it exists
						module_id: moduleId,
						permission_id: accessValue.permission_id,
						status: checked,
						permission: accessValue.permission,
						module: moduleName,
					},
				];
			}

			// Now filter out items with status === false and id === undefined
			return updatedValue.filter(
				(item) => !(item.status === false && item.id === null)
			);
		});
		const newPermissions = roles.permissions.map((permission, index) => {
			if (permission.module_id === moduleId) {
				if (accessType === "view" && !checked) {
					// When unchecking "view", set ALL access types to false
					return {
						...permission,
						access: Object.keys(permission.access).reduce((acc, key) => {
							acc[key as keyof Access] = {
								...permission.access[key as keyof Access],
								status: false,
							};
							return acc;
						}, {} as Access),
					};
				} else {
					// For other cases, just update the specific access
					return {
						...permission,
						access: {
							...permission.access,
							[accessType]: {
								...permission.access[accessType],
								status: checked,
							},
						},
					};
				}
			}
			return permission;
		});

		// Optionally check if all access is false for all modules
		const allAccessFalse = newPermissions.every((permission) =>
			Object.values(permission.access).every((access) => !access.status)
		);

		// Update state with the new permissions
		setIsAllAccessFalse(allAccessFalse);
		setRoles({ permissions: newPermissions });
	};

	useEffect(() => {
		setRoles({ permissions: [...MODULES] });
	}, [MODULES]);

	useEffect(() => {
		if (selectedRole) {
			form.reset(
				{
					rolename: selectedRole.rolename,
					role_description: selectedRole.role_description,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);

			setTimeout(() => {
				setRoles((prevState) => ({
					...prevState,
					permissions: prevState.permissions.map((permission) => {
						// Find the matching permission from the selected role
						const matchedPermission = selectedRole.permissions.find(
							(selectedPermission) =>
								selectedPermission.module === permission.module
						);

						if (matchedPermission) {
							// Iterate over the access keys ('view', 'add', 'edit', 'delete')
							(Object.keys(permission.access) as (keyof Access)[]).forEach(
								(key) => {
									// If matchedPermission has the same key and permission, update permission_id and status
									if (matchedPermission.access[key]) {
										permission.access[key] = {
											...permission.access[key],
											permission_id: permission.access[key].permission_id, // Retain permission_id
											status: matchedPermission.access[key].status, // Update status based on matchedPermission
										};
									}
								}
							);
						}

						return permission;
					}),
				}));
			}, 100);
		}
	}, [selectedRole, form]);

	if (isSelectedRoleFetching || isPermissionsLoading) {
		return <Loading />;
	}

	if (!isSelectedRoleFetching && !selectedRole) {
		if (pathSegments[2] != "add") {
			navigate("/roles", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div>
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<div className="p-8 xl:py-10 xl:px-14 w-full h-full">
						<FormHeader>
							<Label variant="title">Roles & Permissions</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={
										!isValid ||
										isRolesAndPermissionsUpdateLoading ||
										isRolesAndPermissionsAddLoading ||
										isAllAccessFalse
									}
									onClick={_onSavePress}
								>
									{isRolesAndPermissionsUpdateLoading ||
									isRolesAndPermissionsAddLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={
													!isValid || isAllAccessFalse ? "#cbcbcc" : "#292D32"
												}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="button"
									onClick={_onCancelPress}
									disabled={
										isRolesAndPermissionsUpdateLoading ||
										isRolesAndPermissionsAddLoading
									}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>

						<SectionContainer className="lg:grid-cols-3 ">
							<SectionHeader className="lg:col-span-3">
								<Label variant="header">
									{!selectedRole?.id
										? "Add Role & Permissions"
										: "Edit Role & Permissions"}
								</Label>
							</SectionHeader>

							<div>
								<FormField
									control={form.control}
									name="rolename"
									render={({ field }) => (
										<FormController label="Role Name">
											<Input {...field} />
										</FormController>
									)}
								/>
							</div>
							<div className="lg:col-span-2">
								<FormField
									control={form.control}
									name="role_description"
									render={({ field }) => (
										<FormController label="Description">
											<Input {...field} />
										</FormController>
									)}
								/>
							</div>
						</SectionContainer>

						<SectionContainer className="mt-6 lg:mt-8 mb-4 sm:mb-0">
							<SectionHeader className="grid space-y-2 sm:space-y-1 lg:mb-4">
								<Label className="text-[0.85rem] sm:text-[0.90rem] lg:text-base">
									Module Permissions
								</Label>
								<Label variant="description">
									*Unchecked module hides that module to that role. Unchecked
									permissions sets the module to be viewable only by that role.
								</Label>
								{isAllAccessFalse ? (
									<FormMessage className="text-destructive">
										You need to choose 1 permission.
									</FormMessage>
								) : null}
							</SectionHeader>
						</SectionContainer>

						<div>
							<div
								className="grid grid-cols-3 pb-2 border-b-[1px] border-gray-400 border-t-none
                            border-x-none sm:mb-0 lg:m-0 px-2 mb-0"
							>
								<Label variant="description" className="col-span-1 font-bold">
									Module
								</Label>
								<Label variant="description" className="col-span-2 font-bold">
									Permission
								</Label>
							</div>
							<div className="overflow-auto mt-2 lg:mt-4">
								{roles.permissions.map((permission, index) => {
									return (
										<div
											className={`col-span-3 grid grid-cols-3 px-2 pb-4 ${
												permission.module.includes("Store Management")
													? "mb-4 border-b-[1px] border-gray-400"
													: ""
											} ${
												permission.module.includes("Roles & Permission") &&
												"hidden"
											}`}
											key={permission.module_id}
										>
											<Label variant="description" className="col-span-1 mr-2">
												{permission.module}
											</Label>
											<div className="col-span-2 flex gap-8 items-center">
												{Object.entries(permission.access).map(
													([accessType, accessValue]) => {
														const formattedAccess =
															accessType[0].toUpperCase() + accessType.slice(1);

														const isViewAccessChecked =
															permission.access.view.status === true;

														const isOtherAccessChecked =
															Object.entries(permission.access).filter(
																([key, value]) =>
																	key !== "view" && value.status === true
															).length > 0;

														const isDisabled =
															accessType !== "view" &&
															!isViewAccessChecked &&
															!isOtherAccessChecked;
														const isViewDisabled = isOtherAccessChecked;

														const handleCheckedChange = (checked: boolean) => {
															if (accessType === "view") {
																if (!checked) {
																	// When unchecking view, set all other permissions to false
																	Object.keys(permission.access).forEach(
																		(type) => {
																			if (type !== "view") {
																				_handlePermissionChange(
																					permission.module_id,
																					permission.module,
																					type as keyof Access,
																					false,
																					accessValue
																				);
																			}
																		}
																	);
																}
															}
															// Always update the clicked permission
															_handlePermissionChange(
																permission.module_id,
																permission.module,
																accessType as keyof Access,
																checked,
																accessValue
															);
														};

														return (
															<div
																key={accessType}
																className={`flex justify-center items-center gap-1 lg:gap-2`}
															>
																<Checkbox
																	className={`w-3 h-3 xl:w-4 xl:h-4`}
																	checked={accessValue.status}
																	disabled={
																		isDisabled ||
																		isRolesAndPermissionsUpdateLoading ||
																		isRolesAndPermissionsAddLoading ||
																		(accessType === "view" && isViewDisabled) ||
																		permission.module.includes(
																			"Roles & Permission"
																		)
																	}
																	onCheckedChange={handleCheckedChange}
																/>
																<Label variant="description">
																	{formattedAccess}
																</Label>
															</div>
														);
													}
												)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>

					<ConfirmationDialog
						title="Save Changes?"
						description="Saving will apply all changes."
						confirmButtonLabel="Continue"
						closeButtonLabel="Cancel"
						modalState={isSaveModalOpen}
						isLoading={
							isRolesAndPermissionsAddLoading ||
							isRolesAndPermissionsUpdateLoading
						}
						_onCancel={() => setIsSaveModalOpen(false)}
						_onConfirm={_onConfirmSave}
						confirmButtonVariant="default"
					/>

					<NotificationDialog
						description={
							!roleId
								? "Role has been added successfully!"
								: "Role has been updated successfully!"
						}
						confirmButtonLabel="Ok"
						modalState={isNotifModalOpen}
						_onConfirm={_onNotifOkayPress}
					/>
				</form>
			</div>
		</Form>
	);
};

export default RoleForm;
