import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { CustomerWithFullName } from "src/redux/customer/types";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";

type CustomerColumnProps = {
	_onOpenDeleteModal: (id: number, user_id: number, name: string) => void;
	allowedAccess: string[];
};

export const useCustomerColumns = ({
	_onOpenDeleteModal,
	allowedAccess,
}: CustomerColumnProps): ColumnDef<CustomerWithFullName>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<CustomerWithFullName>[] = [
		{
			id: "select",
			header: ({ table }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(value) => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="w-[14px] h-[14px] ml-2"
					/>
				) : null,
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Name</Label>
				</Button>
			),
		},
		{
			accessorKey: "email",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Email</Label>
				</Button>
			),
		},
		{
			accessorKey: "mobile_no",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Mobile Number</Label>
				</Button>
			),
			cell: ({ row }) => <p>+63 {row.getValue("mobile_no")}</p>,
		},
		{
			accessorKey: "landline",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Landline Number</Label>
				</Button>
			),
		},
		{
			id: "actions",
			header: () =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div>
						<Label>Action</Label>
					</div>
				) : null,
			cell: ({ row }) =>
				allowedAccess.includes("can_edit") ||
				allowedAccess.includes("can_delete") ? (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_edit") && "hidden"}`}
							onClick={() => navigate(`${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							className={`${!allowedAccess.includes("can_delete") && "hidden"}`}
							onClick={() =>
								_onOpenDeleteModal(
									row.original.id,
									row.original.user_id,
									row.original.name
								)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				) : null,
		},
	];

	return columns;
};
