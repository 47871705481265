import { useCallback, useMemo, useState } from "react";
import { useRolesColumns } from "./columns";
import {
	useDeleteRoleMutation,
	useGetRolesNoAdminQuery,
	useGetRolesQuery,
} from "../../redux/role/apiSlice";
import { useNavigate } from "react-router-dom";
import { useToast } from "src/shared/hooks/useToast";

// Utils
import { Role } from "../../redux/role/types";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import { ToastClose } from "src/shared/components/ui/toast";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import { X } from "lucide-react";
import { useAuth } from "src/providers/AuthProvider";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

const RolePage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const { data: rolesArray = [], isLoading: isRolesArrayLoading } =
		useGetRolesNoAdminQuery();

	const allowedAccess = useCheckAccessEditability();

	const [_deleteRole, { isLoading: isDeleteRoleLoading }] =
		useDeleteRoleMutation();

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState({
		id: 0,
		name: "",
	});
	const [searchValue, setSearchValue] = useState("");

	const [selectedRows, setSelectedRows] = useState<Role[]>([]);

	const filteredRoles = useMemo(() => {
		return rolesArray.filter(
			(role) =>
				role.rolename.toLowerCase().includes(searchValue.toLowerCase()) ||
				role.role_description.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, rolesArray]);

	const handleSelectionChange = useCallback((newSelectedRows: Role[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedRole({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		try {
			const response = await _deleteRole(selectedRole.id);

			if (response.error) {
				throw response.error;
			}

			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
				setIsDeleteNotifModalOpen(false);
			}
		}
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useRolesColumns({
		_onOpenDeleteModal,
		allowedAccess,
	});

	if (isRolesArrayLoading) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Roles & Permission</Label>
			</PageHeader>
			<PageControls>
				<div className="flex gap-2 w-full md:w-[15em]">
					<Button
						onClick={() => navigate("add")}
						className={`${
							!allowedAccess.includes("can_add") && "hidden"
						} md:max-w-[10em]`}
					>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Roles
					</Button>
					<Button
						variant="destructive"
						className={`${
							!allowedAccess.includes("can_delete") && "hidden"
						} md:max-w-[10em]`}
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredRoles}
					columns={columns}
					onSelectionChange={handleSelectionChange}
					hasPagination={rolesArray.length > 10}
				/>
			</div>

			<ConfirmationDialog
				title="Delete Role?"
				description={`Role ${selectedRole.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={isDeleteRoleLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Roles?`}
				description={`${selectedRows.length} roles will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Roles has been deleted successfully!"
						: "Role has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default RolePage;
