import React, { SetStateAction } from "react";
import { Label } from "src/shared/components/ui/label";

type TableTabsProps = {
	isActiveTable: boolean;
	setIsActiveTable: React.Dispatch<SetStateAction<boolean>>;
	activeBannersCount: number;
	inactiveBannersCount: number;
};

const TableTabs = ({
	isActiveTable,
	setIsActiveTable,
	activeBannersCount,
	inactiveBannersCount,
}: TableTabsProps) => {
	return (
		<div className="grid gap-x-2 gap-y-6 w-full sm:grid-cols-2 mb-2">
			<div className="w-full row-start-3 md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
				<div className="flex flex-row justify-evenly md:justify-center md:gap-8 xl:gap-6 2xl:gap-10 w-full">
					<Label
						onClick={() => setIsActiveTable(true)}
						className="inline-flex gap-4 md:text-xs font-semibold"
					>
						<span
							className={`relative after:content-[''] after:block after:w-0 after:h-[4px] after:bg-destructive after:transition-all after:duration-300  focus:after:w-full after:mt-[1px] cursor-pointer ${
								isActiveTable ? "after:w-full" : "after:w-0 hover:after:w-full"
							}`}
						>
							Active
						</span>
						{activeBannersCount}
					</Label>
					<Label
						onClick={() => setIsActiveTable(false)}
						className="inline-flex gap-4 md:text-xs font-semibold"
					>
						<span
							className={`relative after:content-[''] after:block after:w-0 after:h-[4px] after:bg-destructive after:transition-all after:duration-300  focus:after:w-full after:mt-[1px] cursor-pointer ${
								!isActiveTable ? "after:w-full" : "after:w-0 hover:after:w-full"
							}`}
						>
							Inactive
						</span>
						{inactiveBannersCount}
					</Label>
				</div>
			</div>
		</div>
	);
};

export default TableTabs;
