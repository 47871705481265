// Types
import { UseFormReturn } from "react-hook-form";
import { Role } from "src/redux/role/types";
import { Account } from "src/redux/account/types";

// Components
import FormController from "src/shared/components/form/FormController";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import { FormField } from "src/shared/components/form/Form";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";

type AccountFormDetailsSectionProp = {
	roleOptions?: Role[];
	form: UseFormReturn<Account, any, undefined>;
	allowedAccess: string[];
};

const AccountFormDetailsSection = ({
	roleOptions = [],
	form,
	allowedAccess,
}: AccountFormDetailsSectionProp) => {
	return (
		<SectionContainer className="col-span-1 sm:col-span-4 row-span-2 xl:h-fit">
			<SectionHeader>
				<Label variant="header">Account Details</Label>
			</SectionHeader>
			<div className="w-full h-full flex flex-col gap-4 sm:gap-6">
				<FormField
					control={form.control}
					name="firstname"
					disabled={!allowedAccess.includes("can_edit")}
					render={({ field }) => (
						<FormController label="First Name" required>
							<Input id="firstname" {...field} />
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="lastname"
					disabled={!allowedAccess.includes("can_edit")}
					render={({ field }) => (
						<FormController label="Last Name" required>
							<Input id="lastname" {...field} />
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="email"
					disabled={!allowedAccess.includes("can_edit")}
					render={({ field }) => (
						<FormController label="Email" required>
							<Input id="email" {...field} />
						</FormController>
					)}
				/>
				<FormField
					control={form.control}
					name="role_id"
					render={({ field }) => (
						<FormController label="Role" required>
							<Select
								onValueChange={(value) => field.onChange(Number(value))}
								value={String(field.value)}
								disabled
							>
								<SelectTrigger isIconShowing={false}>
									<SelectValue placeholder="Select Role" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectLabel>Default Option</SelectLabel>
										{roleOptions?.map((role) => {
											return (
												<SelectItem value={String(role.id)}>
													{role.rolename}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
						</FormController>
					)}
				/>
			</div>
		</SectionContainer>
	);
};

export default AccountFormDetailsSection;
