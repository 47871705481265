import { useVerifyOtpMutation } from "src/redux/auth/apiSlice";
import { useToast } from "src/shared/hooks/useToast";
import { useForm } from "react-hook-form";

// Utils
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Components
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "src/shared/components/ui/input-otp";
import { Dialog, DialogContent } from "../ui/dialog";
import { Form, FormField } from "../form/Form";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { ToastClose } from "../ui/toast";
import ButtonLoading from "../loading-indicator/ButtonLoading";

// Icons
import { X } from "lucide-react";
import BackIconSvg from "src/assets/svg/BackIconSvg";

type OtpInputProps = {
	modalState: boolean;
	modalTrigger: () => void;
};

type OTP = {
	pin: string;
};

const OtpInput = ({ modalState, modalTrigger }: OtpInputProps) => {
	const { toast } = useToast();

	const [_verifyOtp, { isLoading, isSuccess }] = useVerifyOtpMutation();

	const form = useForm<OTP>({
		defaultValues: {
			pin: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const _onSubmit = async (values: OTP) => {
		try {
			const response = await _verifyOtp(Number(values.pin));

			if (response.error) {
				throw response.error;
			}

			toast({
				description:
					"Redirecting you to the Reset Password page. This will only take a moment.",
				duration: 1500,
				variant: "success",
				action: (
					<ToastClose
						className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
						aria-label="Close"
					>
						<X className="w-5 h-5" />
					</ToastClose>
				),
			});

			const resetLink = response?.data?.data[1].split(": ")[1];

			setTimeout(() => {
				localStorage.setItem("OTP", values.pin);

				window.location.href = `/auth/reset-password/${resetLink}`;
			}, 2000);
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	return (
		<Dialog open={modalState} onOpenChange={modalTrigger}>
			<Form {...form}>
				<div className="h-auto relative">
					<form
						onSubmit={(e) => {
							form.handleSubmit(_onSubmit);
						}}
					>
						<DialogContent
							className={`flex flex-col h-full w-full sm:h-fit sm:w-fit xl:space-y-2 justify-normal min-w-[320px]`}
						>
							<div className="flex flex-col w-full gap-8 sm:flex-row sm:gap-4xl:text-center sm:items-center sm:mt-2">
								<div onClick={modalTrigger}>
									<BackIconSvg
										fill="#e2e8f0"
										className="h-8 w-8 sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10"
									/>
								</div>
								<Label className="text-xl font-bold sm:text-base md:text-lg lg:text-2xl">
									OTP Verification
								</Label>
							</div>
							<Label className="text-disabled-foreground leading-none tracking-tight text-sm sm:text-xs md:text-sm lg:text-base line-clamp-2">
								We have sent your one time password (OTP) on your email address.
							</Label>
							<FormField
								control={form.control}
								name="pin"
								render={({ field }) => (
									<InputOTP maxLength={4} {...field}>
										<InputOTPGroup className="my-4 max-w-[380px] lg:mx-auto">
											<InputOTPSlot index={0} />
											<InputOTPSlot index={1} />
											<InputOTPSlot index={2} />
											<InputOTPSlot index={3} />
										</InputOTPGroup>
									</InputOTP>
								)}
							/>
							<Button
								className="sm:text-xs md:text-sm w-full xl:w-full xl:max-w-full max-w-full"
								onClick={() => _onSubmit(form.getValues())}
								disabled={isLoading || isSuccess}
							>
								{isLoading ? (
									<>
										<ButtonLoading />
									</>
								) : (
									"Continue"
								)}
							</Button>
						</DialogContent>
					</form>
				</div>
			</Form>
		</Dialog>
	);
};

export default OtpInput;
