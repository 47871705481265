// Components
import PageControls from "src/shared/components/control/PageControls";
import { Label } from "src/shared/components/ui/label";

const AccountFormDescription = ({
	date,
	time,
}: {
	date: string;
	time: string;
}) => {
	return (
		<>
			<PageControls className="w-full flex">
				<Label variant="description">
					Changes will be reflected on the app. Last edited{" "}
					<span className="font-bold">{date}</span> at{" "}
					<span className="font-bold">{time}</span>
				</Label>
			</PageControls>
		</>
	);
};

export default AccountFormDescription;
