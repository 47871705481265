import { createApi } from "@reduxjs/toolkit/query/react";

import {
	CreateUserType,
	UpdatedUserType,
	User,
	UserWithFullNameAndRole,
} from "src/redux/user/types";
import {
	MultipleDeleteApiResponse,
	SuccessMultipleDataResponse,
} from "src/redux/types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const usersApi = createApi({
	reducerPath: "usersApi",
	tagTypes: ["Users", "User"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getUsers: builder.query<UserWithFullNameAndRole[], void>({
			query: () => ({
				url: "/user/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<User>) => {
				const usersWithFullNameAndRoleName = data
					.map((user) => {
						return {
							...user,
							name: `${user.firstname} ${user.lastname}`,
							roleName: user.role?.roleName,
						};
					})
					.filter((i) => i.id != 1);

				return usersWithFullNameAndRoleName;
			},
			providesTags: ["Users"],
			keepUnusedDataFor: 10,
		}),
		getSelectedUser: builder.query<User, number>({
			query: (id) => ({
				url: `/user/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<User>) => {
				return data[0];
			},
			keepUnusedDataFor: 0,
		}),
		addUser: builder.mutation<void, CreateUserType>({
			query: (newUser) => ({
				url: "/user/signup",
				method: "POST",
				body: newUser,
			}),
			invalidatesTags: ["Users"],
		}),
		updateUser: builder.mutation<void, UpdatedUserType>({
			query: (updatedUser) => ({
				url: `/user/${updatedUser.id}`,
				method: "PATCH",
				body: updatedUser,
			}),
			invalidatesTags: ["Users"],
		}),
		deleteUser: builder.mutation<void, number>({
			query: (id) => ({
				url: `/user/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Users"],
		}),
		deleteMultipleUsers: builder.mutation<MultipleDeleteApiResponse, Number[]>({
			query: (users) => {
				return {
					url: "/user/multiple-delete",
					method: "POST",
					body: users,
				};
			},
			invalidatesTags: ["Users"],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetSelectedUserQuery,
	useAddUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useDeleteMultipleUsersMutation,
} = usersApi;
