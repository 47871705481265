import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "src/shared/hooks/useToast";
import { useGetRolesQuery } from "../../redux/role/apiSlice";
import {
	useGetCurrentUserAccountQuery,
	useUpdateAccountMutation,
} from "src/redux/account/apiSlice";

// Types
import { Account, UpdateAccountType } from "src/redux/account/types";

// Utils
import { useFileUpload } from "src/shared/hooks/useFileUpload";
import { AccountFormSchema } from "src/redux/account/schema";
import {
	formatDateToIso,
	formatDateWithTime,
} from "src/shared/helpers/formatDate";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Form } from "src/shared/components/form/Form";
import { ToastClose } from "src/shared/components/ui/toast";
import AccountFormDescription from "./fragments/AccountFormDescription";
import AccountFormHeader from "./fragments/AccountFormHeader";
import AccountFormImageSection from "./fragments/AccountFormImageSection";
import AccountFormDetailsSection from "./fragments/AccountFormDetailsSection";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import Loading from "src/shared/components/loading-indicator/Loading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

// Icons
import { X } from "lucide-react";
import { useCheckAccessEditability } from "src/shared/hooks/useCheckPermissions";

type DateUpdated = {
	date: string;
	time: string;
};

const AccountForm = () => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const allowedAccess = useCheckAccessEditability();

	const {
		uploadImage: _uploadImage,
		isLoading: isImageUploading,
		isSuccess: isImageUploaded,
		isError: isImageUploadingFailed,
	} = useFileUpload();

	const user_id = Number(localStorage?.getItem("USER_ID"));

	const { data: roles, isLoading: isRolesLoading } = useGetRolesQuery();
	const { data: currentUser, isLoading: isUserLoading } =
		useGetCurrentUserAccountQuery(user_id);

	const [_updateAccount, { isLoading: isUserUpdateLoading }] =
		useUpdateAccountMutation();

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);
	const [dateUpdated, setDateUpdated] = useState<DateUpdated | null>(null);
	const [image, setImage] = useState<File[] | null>(null);

	const form = useForm<Account>({
		resolver: zodResolver(AccountFormSchema),
		mode: "onTouched",
		reValidateMode: "onBlur",
		criteriaMode: "all",
	});

	const _onConfirmLeave = () => {
		form.reset();
		setIsLeaveModalOpen(false);
	};

	const _onCancelPress = () => {
		if (!form.formState.isDirty) navigate("/account");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: Account) => {
		try {
			setIsSaveModalOpen(false);
			if (values && currentUser) {
				let imagePath = values.image;

				if (image) {
					const imageUploaded = await _uploadImage(image, "users");
					imagePath = String(imageUploaded);
				}

				if (!isImageUploaded && isImageUploadingFailed) {
					console.log("Abort saving!");
					form.setError("image", {
						type: "manual",
						message:
							"There is an issue with the file you're uploading. Please recheck your image.",
					});
					toast({
						description: "Failed to upload your image. Please try again.",
						duration: 2000,
						variant: "destructive",
						action: (
							<ToastClose
								className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
								aria-label="Close"
							>
								<X className="w-5 h-5" />
							</ToastClose>
						),
					});
				} else {
					const account: UpdateAccountType = {
						firstname: values.firstname,
						middlename: currentUser.middlename,
						lastname: values.lastname,
						address: currentUser.address,
						phone_number: currentUser.phone_number,
						status: currentUser.status,
						updated_date: formatDateToIso(new Date()),
						is_delete: currentUser.is_delete,
						updated_from: 1,
						updated_by: currentUser.id,
						image: imagePath,
						id: currentUser.id,
						email: values.email,
						role_id: values.role_id,
					};

					const response = await _updateAccount(account);

					if (response.error) {
						throw response.error;
					}

					setTimeout(() => {
						setIsNotifModalOpen(true);
						form.reset(
							{
								email: values?.email,
								firstname: values?.firstname,
								lastname: values?.lastname,
								role_id: values?.role_id,
								image: values?.image,
							},
							{
								keepDirty: false,
								keepTouched: false,
								keepIsValid: false,
								keepErrors: false,
							}
						);

						// const profile = !uploadedImagePath ? null : uploadedImagePath;

						localStorage.setItem("FIRST_NAME", values.firstname);
						localStorage.setItem("LAST_NAME", values.lastname);
						localStorage.setItem("PROFILE", String(imagePath));
					}, 150);
				}
			}
		} catch (error) {
			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
	};

	useEffect(() => {
		if (currentUser) {
			form.reset(
				{
					email: currentUser?.email,
					firstname: currentUser?.firstname,
					lastname: currentUser?.lastname,
					role_id: currentUser?.role_id,
					image: currentUser?.image,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);
		}
	}, [currentUser, form]);

	useEffect(() => {
		if (currentUser?.updated_date) {
			const dateAndTime = formatDateWithTime(currentUser.updated_date);
			setDateUpdated(dateAndTime);
		}
	}, [currentUser]);

	if (isUserLoading || isRolesLoading || !dateUpdated?.date) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<div className="h-auto w-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<AccountFormHeader
							isDirty={form.formState.isDirty}
							isValid={form.formState.isValid}
							isLoading={isUserUpdateLoading}
							_onCancelPress={_onCancelPress}
							_onSavePress={_onSavePress}
							allowedAccess={allowedAccess}
						/>
						<AccountFormDescription
							date={dateUpdated?.date}
							time={dateUpdated?.time}
						/>

						<div className="mt-10 grid grid-rows-3 sm:grid-rows-1 grid-cols-1 sm:grid-cols-6 gap-8 w-full h-full">
							<AccountFormImageSection
								form={form}
								setImage={setImage}
								isLoading={isImageUploading}
								allowedAccess={allowedAccess}
							/>
							<AccountFormDetailsSection
								form={form}
								roleOptions={roles}
								allowedAccess={allowedAccess}
							/>
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<ConfirmationDialog
				title="Confirm Cancel?"
				description="Canceling will discard unsaved changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<NotificationDialog
				description={"Account has been updated successfully!"}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default AccountForm;
