import { z } from "zod";

export const StoreFormSchema = z.object({
	name: z
		.string({
			required_error: "Please enter store name.",
			invalid_type_error: "Store name cannot be a number.",
		})
		.min(2, {
			message: "Store name must be at least 2 characters.",
		})
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "Store name contains invalid characters.",
		})
		.transform((val) => val.trim()),
	address: z
		.string({
			required_error: "Address is empty.",
			invalid_type_error: "Address cannot be a number.",
		})
		.min(5, {
			message: "Address must be at least 5 characters.",
		})
		.max(100, {
			message: "Address must not exceed 100 characters.",
		})
		.trim()
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "Address contains invalid characters.",
		}),
	postal_code: z.coerce
		.string({
			required_error: "Please enter postal code.",
			invalid_type_error: "Postal code cannot be a number.",
		})
		.min(4, {
			message: "Postal code must be at least 4 characters.",
		})
		.max(10, {
			message: "Postal code must not exceed 10 characters.",
		})
		.regex(/^[A-Za-z0-9\s-]+$/, {
			message:
				"Postal code can only contain letters, numbers, spaces, and hyphens.",
		})
		.trim(),
	city: z
		.string({
			required_error: "Please enter city.",
			invalid_type_error: "City cannot be a number.",
		})
		.min(2, {
			message: "City name must be at least 2 characters.",
		})
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "City contains invalid characters.",
		})
		.trim(),
	latitude: z.coerce.number({
		required_error: "Please enter latitude.",
		invalid_type_error: "Latitude must be a number.",
	}),
	longitude: z.coerce.number({
		required_error: "Please enter longitude.",
		invalid_type_error: "Longitude must be a number.",
	}),
	opening_hrs: z
		.string({
			required_error: "Opening hours is empty.",
			invalid_type_error: "Invalid opening hosur.",
		})
		.min(6, {
			message: "Opening hours must be at least 6 characters.",
		})
		.trim()
		.optional(),
	status: z.coerce.number().min(0, "Status must be a non-negative integer"),
	landline_no: z.coerce
		.string()
		.refine((val) => val.toString().length <= 14, {
			message: "Landline number must be lower than or equal to 14 digits long.",
		})
		// .refine((value) =>  /^\d{3}-\d{3}-\d{4}$/.test(value), {
		// 	message:
		// 		"Please input a valid landline number in the format 111-222-3333.",
		// })
		.refine((val) => /^\(\d{2}\)\s\d{4}\s\d{4}$/.test(val), {
			message:
				"Landline number must follow the format: (XX) XXXX XXXX, where X is a number.",
		}),
	store_code: z.coerce
		.string({
			required_error: "Please enter store code.",
		})
		.min(2, {
			message: "Store code must be at least 2 characters.",
		})
		.trim(),
	system_name: z.coerce
		.string({
			required_error: "Please enter system name.",
		})
		.min(2, {
			message: "System name must be at least 2 characters.",
		})
		.trim()
		.optional(),
});

export const ImportStoreSchema = z.object({
	name: z
		.string({
			required_error: "Store name is empty.",
			invalid_type_error: "Store name cannot be a number.",
		})
		.min(2, {
			message: "Store name must be at least 2 characters.",
		})
		.max(50, {
			message: "Store name must not exceed 50 characters.",
		})
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "Store name contains invalid characters.",
		})
		.transform((val) => val.trim()),
	address: z
		.string({
			required_error: "Address is empty.",
			invalid_type_error: "Address cannot be a number.",
		})
		.min(5, {
			message: "Address must be at least 5 characters.",
		})
		.max(100, {
			message: "Address must not exceed 100 characters.",
		})
		.trim()
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "Address contains invalid characters.",
		}),
	postal_code: z
		.string({
			required_error: "Postal Code is empty.",
			invalid_type_error: "Postal Code cannot be a number.",
		})
		.min(4, {
			message: "Postal code must be at least 4 characters.",
		})
		.max(10, {
			message: "Postal code must not exceed 10 characters.",
		})
		.regex(/^[A-Za-z0-9\s-]+$/, {
			message:
				"Postal code can only contain letters, numbers, spaces, and hyphens.",
		})
		.trim(),
	city: z
		.string({
			required_error: "City is empty.",
			invalid_type_error: "City cannot be a number.",
		})
		.min(2, {
			message: "City name must be at least 2 characters.",
		})
		.max(50, {
			message: "City name must not exceed 50 characters.",
		})
		.regex(/^[a-zA-ZñÑ0-9\s,./\-]+$/, {
			message: "City contains invalid characters.",
		})
		.trim(),
	latitude: z.number({
		required_error: "Latitude is empty.",
		invalid_type_error: "Latitude must be a number.",
	}),
	longitude: z.number({
		required_error: "Longitude is empty.",
		invalid_type_error: "Longitude must be a number.",
	}),
	opening_hrs: z
		.string({
			required_error: "Opening hours is empty.",
			invalid_type_error: "Invalid opening hours.",
		})
		.trim()
		.optional(),
	landline_no: z
		.string({
			required_error: "Landline is empty.",
			invalid_type_error: "Invalid landline number.",
		})
		.refine((val) => val.toString().length === 14, {
			message: "Landline number must be exactly 14 digits long.",
		})
		// .refine((value) => value === "" || /^\d{3}-\d{3}-\d{4}$/.test(value), {
		// 	message:
		// 		"Please input a valid landline number in the format 111-222-3333.",
		// })
		.refine((val) => /^\(\d{2}\)\s\d{4}\s\d{4}$/.test(val), {
			message:
				"Landline number must follow the format: (XX) XXXX XXXX, where X is a number.",
		}),
	store_code: z
		.string({
			required_error: "Please enter store code.",
		})
		.min(2, {
			message: "Store code must be at least 2 characters.",
		})
		.trim(),
	system_name: z
		.string({
			required_error: "Please enter system name.",
		})
		.min(2, {
			message: "System name must be at least 2 characters.",
		})
		.trim()
		.optional(),
	status: z
		.number({
			required_error:
				"Invalid input. Please enter either 'active' or 'inactive'.",
		})
		.min(0, "Status must be a non-negative integer"),
});
