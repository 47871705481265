import { createApi } from "@reduxjs/toolkit/query/react";
import { Permissions, Role, RoleRequestBody } from "./types";
import { SuccessMultipleDataResponse } from "../types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const rolesApi = createApi({
	reducerPath: "rolesApi",
	tagTypes: ["Roles No Admin", "Roles", "Role", "Permissions"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getRolesNoAdmin: builder.query<Role[], void>({
			query: () => ({
				url: "/role/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Role>) => {
				const rolesWithNoAdmin = data.filter((role) => role.id != 1);

				return [...rolesWithNoAdmin];
			},
			providesTags: ["Roles No Admin"],
			keepUnusedDataFor: 10,
		}),
		getRoles: builder.query<Role[], void>({
			query: () => ({
				url: "/role/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Role>) => {
				return [...data];
			},
			providesTags: ["Roles"],
			keepUnusedDataFor: 10,
		}),
		getSelectedRole: builder.query<Role, number>({
			query: (id) => ({
				url: `/role/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Role>) => {
				const x = data[0].permissions.map((permission) => {
					if (Array.isArray(permission.access)) {
						// Reduce to format access keys
						const y = permission.access.reduce(
							(
								acc,
								access: { id: number; permission: string; status: boolean }
							) => {
								// Extract the key from the permission (e.g., 'can_view' => 'view')
								const key = access.permission.replace("can_", "");

								// Set the new key directly in the accumulator
								acc[key] = {
									...access,
								};
								return acc;
							},
							{}
						);

						// Now, return the updated permission object with the new access format
						return { ...permission, access: { ...y } };
					}

					// If permission.access is not an array, return the original permission
					return permission;
				});

				return { ...data[0], permissions: x };
			},
			keepUnusedDataFor: 0,
		}),
		addRole: builder.mutation<void, RoleRequestBody>({
			query: (newRole) => ({
				url: "/role/create",
				method: "POST",
				body: newRole,
			}),
			invalidatesTags: ["Roles No Admin", "Roles"],
		}),
		updateRole: builder.mutation<void, RoleRequestBody>({
			query: (updatedRole) => ({
				url: `/role/${updatedRole.id}`,
				method: "PATCH",
				body: updatedRole,
			}),
			invalidatesTags: ["Roles No Admin", "Roles"],
		}),
		deleteRole: builder.mutation<void, number>({
			query: (id) => ({
				url: `/role/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Roles No Admin", "Roles"],
		}),
		getPermissions: builder.query<Permissions[], void>({
			query: () => ({
				url: "/permission/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Permissions>) => {
				return [...data];
			},
			providesTags: ["Permissions"],
			keepUnusedDataFor: 10,
		}),
		getRolesPermission: builder.query<Role[], void>({
			query: () => ({
				url: "/role/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Role>) => {
				return [...data];
			},
			providesTags: ["Permissions"],
			keepUnusedDataFor: 10,
		}),
	}),
});

export const {
	useGetRolesNoAdminQuery,
	useGetRolesQuery,
	useGetSelectedRoleQuery,
	useAddRoleMutation,
	useUpdateRoleMutation,
	useDeleteRoleMutation,
	useGetPermissionsQuery,
	useGetRolesPermissionQuery,
} = rolesApi;
