// Type
import { Banner } from "./types";

import { createApi } from "@reduxjs/toolkit/query/react";
import { formatDate } from "src/shared/helpers/formatDate";
import { SuccessMultipleDataResponse } from "../types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const bannersApi = createApi({
	reducerPath: "bannersApi",
	tagTypes: ["Banners", "Banner", "Active Banners", "Inactive Banners"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getActiveBanners: builder.query<Banner[], void>({
			query: () => ({
				url: "/banner/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Banner>) => {
				let i = 0;

				const banners = data
					.map((banner) => {
						if (banner.status === 1) {
							i++;
						}

						return {
							...banner,
							order: i,
							startDate: formatDate(banner.startDate),
							endDate: !banner.endDate ? null : formatDate(banner.endDate),
						};
					})
					.filter((banner: Banner) => banner.status === 1);

				return banners;
			},
			keepUnusedDataFor: 0,
			providesTags: ["Active Banners"],
		}),
		getInactiveBanners: builder.query<Banner[], void>({
			query: () => ({
				url: "/banner/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Banner>) => {
				const banners = data
					.map((banner) => {
						return {
							...banner,
							startDate: formatDate(banner.startDate),
							endDate: !banner.endDate ? null : formatDate(banner.endDate),
						};
					})
					.filter((banner: Banner) => banner.status === 0);

				return banners;
			},
			keepUnusedDataFor: 0,
			providesTags: ["Inactive Banners"],
		}),
		getSelectedBanner: builder.query<Banner, number>({
			query: (id) => ({
				url: `/banner/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Banner>) => {
				const selectedBanner = {
					...data[0],
					startDate: formatDate(data[0].startDate),
					endDate: !data[0].endDate ? null : formatDate(data[0].endDate),
				};

				return selectedBanner;
			},
			keepUnusedDataFor: 0,
		}),
		addBanner: builder.mutation<void, Omit<Banner, "id">>({
			query: (newBanner) => ({
				url: "/banner/create",
				method: "POST",
				body: newBanner,
			}),
			invalidatesTags: ["Active Banners", "Inactive Banners"],
		}),
		updateBanner: builder.mutation<void, Banner>({
			query: (updatedBanner) => ({
				url: `/banner/${updatedBanner.id}`,
				method: "PATCH",
				body: updatedBanner,
			}),
			invalidatesTags: ["Active Banners", "Inactive Banners"],
		}),
		deleteBanner: builder.mutation<void, number>({
			query: (id) => ({
				url: `/banner/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Active Banners", "Inactive Banners"],
		}),
	}),
});

export const {
	useGetActiveBannersQuery,
	useGetInactiveBannersQuery,
	useGetSelectedBannerQuery,
	useAddBannerMutation,
	useUpdateBannerMutation,
	useDeleteBannerMutation,
} = bannersApi;

// TODO: Integrate force refetch. combine active and inactive if needs to refetch the data everytime
// forceRefetch
// Prevents caching
// keepUnusedDataFor: 0,
// providesTags: [{ type: "Banners", id: "ACTIVE" }],
