import { useState } from "react";

// Types
import { UseFormReturn } from "react-hook-form";
import { Product } from "src/redux/product/types";

// Utils
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";

// Components
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import FormController from "src/shared/components/form/FormController";
import { FormField, FormMessage } from "src/shared/components/form/Form";
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

// Icons & Images
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";

import ButtonEditIconSvg from "src/assets/svg/ButtonEditIconSvg";
import image from "src/assets/images/image.png";

type ImagesSectionProp = {
	form: UseFormReturn<Product, any, undefined>;
};

const ImagesSection = ({ form }: ImagesSectionProp) => {
	const [isDeleteImageModalOpen, setIsDeleteImageModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(0);

	const handleFileChange = (file: File, index: number) => {
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			let currentImages = form.getValues("images") || [];

			// Ensure array is of length 5
			if (currentImages.length < 5) {
				currentImages = [
					...currentImages,
					...Array(5 - currentImages.length).fill(""),
				];
			}

			// Find the first empty slot
			const firstEmptyIndex = currentImages.findIndex((img) => !img);

			if (firstEmptyIndex !== -1) {
				// If there's an empty slot, use it
				currentImages[firstEmptyIndex] = imageUrl;
			} else {
				// If no empty slots, use the specified index
				currentImages[index] = imageUrl;
			}

			form.setValue("images", currentImages);
		}
	};

	const _onConfirmDeleteImage = () => {
		const images = form.getValues("images");

		if (Array.isArray(images)) {
			// Remove the image at selectedImage index
			images.splice(selectedImage, 1);

			// Add a null/empty value at the end to maintain array length of 5
			images.push("");

			form.setValue("images", images);
		}

		setIsDeleteImageModalOpen(false);
	};

	const _onDeleteImagePress = (index: number) => {
		setSelectedImage(index);
		setIsDeleteImageModalOpen(true);
	};

	return (
		<div className="space-y-2">
			<Label variant="sub_header">Product Images</Label>

			<div className="grid gap-4 xl:max-w-[71vw] 2xl:max-w-full overflow-x-auto overflow-y-hidden">
				<FormField
					control={form.control}
					name="images"
					render={({ field: { value, onChange, ...fieldProps } }) => (
						<>
							<FormController hasValidationMessage={false} label="Images">
								<div className="flex flex-row gap-2 lg:gap-4 space-evenly h-40 lg:h-60 w-full overflow-auto">
									{Array(5)
										.fill(null)
										.map((_, index) => {
											const productImage = value?.[index] || image;

											return (
												<div
													key={index}
													className={`relative h-40 w-40 lg:h-60 lg:w-60 aspect-square rounded-lg border-[1px] border-gray-400 group hover:bg-gray-900/50 transform-all duration-300 overflow-hidden ${
														!value?.[index] ? "p-10 lg:p-16" : "p-0"
													}`}
												>
													<div
														key={index}
														className={`${
															!value?.[index] ? "bg-contain w-18" : "bg-cover"
														} bg-center bg-no-repeat aspect-square -z-10`}
														style={{
															backgroundImage: `url('${productImage}')`,
														}}
													/>

													{value?.[index] && (
														<div className="hidden absolute top-0 h-40 w-40 lg:h-60 lg:w-60 z-10 group-hover:bg-gray-900/50 group-hover:flex aspect-square rounded-lg transform-all duration-300" />
													)}

													<div className="z-40 absolute bottom-1 left-1">
														<Input
															id={`input-${index}`}
															type="file"
															className="hidden"
															accept="image/*"
															{...fieldProps}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																const file = e.target.files?.[0];
																if (file) {
																	const isAttachmentValid = validateFileSize({
																		file: file,
																		form: form,
																		errorMessage:
																			"Image size must not exceed 2mb.",
																		fieldName: "images",
																		sizeLimit: 2,
																	});

																	const isExtensionValid =
																		validateFileExtension({
																			file: file,
																			form: form,
																			errorMessage:
																				"Invalid file type. Please upload a .png, .jpg, or .jpeg file.",
																			fieldName: "images",
																			validExtensions: [
																				"image/jpeg",
																				"image/jpg",
																				"image/png",
																			],
																		});

																	if (!isExtensionValid || !isAttachmentValid) {
																		return;
																	} else {
																		form.clearErrors();
																		handleFileChange(file, index);
																	}
																}
															}}
															value="" // Keep the file input value empty
															{...fieldProps}
														/>
														<Button
															type="button"
															onClick={() =>
																document
																	.getElementById(`input-${index}`)
																	?.click()
															}
															className="px-[10px] py-[4px] lg:px-[18px] lg:py-2 bg-primary rounded-full hidden group-hover:flex hover:bg-primary"
															variant="icon"
															size="icon"
														>
															<ButtonEditIconSvg
																fill="#ffe444"
																className="h-3 w-3 p-[3px] lg:h-5 lg:w-5 lg:p-[4px] bg-secondary rounded-full"
															/>
														</Button>
													</div>

													<div className="z-40 absolute bottom-1 right-1">
														<Button
															type="button"
															disabled={!value?.[index]}
															onClick={() => _onDeleteImagePress(index)}
															className={`px-[10px] py-[4px] lg:px-[18px] lg:py-2 rounded-full hidden group-hover:flex ${
																!value?.[index]
																	? "bg-disabled hover:bg-disabled"
																	: "bg-destructive hover:bg-destructive"
															}`}
															variant="icon"
															size="icon"
														>
															<ButtonDeleteIconSvg
																fill={`${
																	!value?.[index] ? "#cbcbcc" : "white"
																}`}
																className="h-3 w-3 lg:h-5 lg:w-5"
															/>
														</Button>
													</div>
												</div>
											);
										})}
								</div>
							</FormController>
						</>
					)}
				/>
			</div>

			<div className="flex flex-col gap-1 lg:gap-0 leading-tight tracking-tight md:tracking-normal md:leading-normal text-secondary text-justify">
				<>
					{form.formState.errors.images && (
						<FormMessage className="text-destructive-foreground mb-1">
							{form.formState.errors.images?.message}
						</FormMessage>
					)}
				</>
				<Label variant="description" className="lg:text-[0.70rem]">
					&bull; Recommended image resolution is minimum of{" "}
					<span className="font-bold">360px</span> by{" "}
					<span className="font-bold">360px</span>
				</Label>
				<Label variant="description" className="lg:text-[0.70rem]">
					&bull; Image file size should be no more than{" "}
					<span className="font-bold">2mb</span>
				</Label>
				<Label variant="description" className="lg:text-[0.70rem]">
					&bull; Allowed image formats are{" "}
					<span className="font-bold">.jpg</span> and{" "}
					<span className="font-bold">.png</span> only
				</Label>
			</div>

			<ConfirmationDialog
				title="Delete Product Image?"
				description="Product Image will be deleted."
				confirmButtonIcon={ButtonDeleteIconSvg}
				confirmButtonLabel="Delete"
				closeButtonLabel="Cancel"
				modalState={isDeleteImageModalOpen}
				_onCancel={() => setIsDeleteImageModalOpen(false)}
				_onConfirm={_onConfirmDeleteImage}
			/>
		</div>
	);
};

export default ImagesSection;
