import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import {
	useAddBannerMutation,
	useGetActiveBannersQuery,
	useGetSelectedBannerQuery,
	useUpdateBannerMutation,
} from "../../redux/banner/apiSlice";
import { useGetCategoriesQuery } from "src/redux/category/apiSlice";
import { useGetProductsQuery } from "src/redux/product/apiSlice";
import { useToast } from "src/shared/hooks/useToast";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Banner } from "src/redux/banner/types";
import { BannerFormSchema } from "src/redux/banner/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

import { Form } from "src/shared/components/form/Form";
import { ToastClose } from "src/shared/components/ui/toast";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import ProductListSection from "./fragments/ProductListSection";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";

// Icons
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import BannerInformationSection from "./fragments/BannerInformationSection";
import PromoDurationSection from "./fragments/PromoDurationSection";
import ArrowRightIconSvg from "src/assets/svg/ArrowRightIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { useFileUpload } from "src/shared/hooks/useFileUpload";
import { formatDateToIso } from "src/shared/helpers/formatDate";
import {
	isErrorResponse,
	reshapeErrorResponse,
} from "src/shared/helpers/errorAssertion";
import { X } from "lucide-react";

const BannerForm = () => {
	const navigate = useNavigate();
	const { toast } = useToast();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { bannerId } = useParams<{ bannerId: string }>();

	const {
		uploadImage: _uploadImage,
		isLoading: isImageUploading,
		isSuccess: isImageUploaded,
		isError: isImageUploadingFailed,
	} = useFileUpload();

	const { data: selectedBanner, isFetching: isSelectedBannerFetching } =
		useGetSelectedBannerQuery(Number(bannerId));
	const { data: banners = [], isLoading: isActiveBannersFetching } =
		useGetActiveBannersQuery();
	const { data: categoriesOption = [], isLoading: isCategoriesFetching } =
		useGetCategoriesQuery();
	const { data: productsArray = [], isLoading: isProductsArrayFetching } =
		useGetProductsQuery();

	const [_addBanner, { isLoading: isBannerAddLoading }] =
		useAddBannerMutation();
	const [_updateBanner, { isLoading: isBannerUpdateLoading }] =
		useUpdateBannerMutation();

	const [image, setImage] = useState<File[] | null>(null);
	const [activeBanners, setActiveBanners] = useState(banners);
	const [isActiveBanner, setIsActiveBanner] = useState(
		!!selectedBanner?.status
	);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<Banner>({
		resolver: zodResolver(BannerFormSchema),
		defaultValues: {
			id: 0,
			title: "",
			image: "",
			description: "",
			products: [],
			startDate: "",
			endDate: "",
			order: 0,
			categoryId: 0,
			status: 0,
			noExpiration: false,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});
	const { isDirty, isValid } = form.formState;

	const status = useWatch({
		control: form.control,
		name: "status",
	});

	const dates = useWatch({
		control: form.control,
		name: ["startDate", "endDate"],
	});

	const _navigateToAddProducts = () => {
		if (!bannerId) {
			navigate("products");
		} else {
			navigate("add/products");
		}
	};

	const _onConfirmLeave = () => {
		navigate("/banner");

		form.reset();
	};

	const _onCancelPress = () => {
		form.trigger();
		if (!isDirty) navigate("/banner");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: Banner) => {
		try {
			if (String(values.endDate) == "" && values.noExpiration == false) {
				form.setError("endDate", {
					type: "manual",
					message: "If no end date, press No Expiration checkbox.",
				});
				setIsSaveModalOpen(false);
			}

			let imagePath = values.image;

			if (image) {
				const imageUploaded = await _uploadImage(image, "banners");
				imagePath = String(imageUploaded);
			}

			if (!isImageUploaded && isImageUploadingFailed) {
				console.log("Abort saving!");
				form.setError("image", {
					type: "manual",
					message:
						"There is an issue with the file you're uploading. Please recheck your image.",
				});
				toast({
					description: "Failed to upload your image. Please try again.",
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			} else {
				if (selectedBanner?.id) {
					const updatedBanner: Banner = {
						...selectedBanner,
						image: imagePath,
						title: values.title,
						status: values.status,
						categoryId: values.categoryId,
						noExpiration: values.noExpiration,
						startDate: formatDateToIso(String(values.startDate)),
						endDate: values.noExpiration
							? null
							: formatDateToIso(String(values.endDate)),
						description: values.description,
						updatedBy: Number(localStorage.getItem("USER_ID")),
						updatedDate: formatDateToIso(new Date()),
						createdBy: Number(localStorage.getItem("USER_ID")),
						countryCode: "PH",
						is_delete: false,
						createdFrom: 1,
						updatedFrom: 1,
					};

					const response = await _updateBanner(updatedBanner);

					if (response.error) {
						throw response.error;
					}
				} else {
					const newBanner: Omit<Banner, "id"> = {
						image: imagePath,
						title: values.title,
						description: values.description,
						startDate: formatDateToIso(String(values.startDate)),
						endDate: values.noExpiration
							? null
							: formatDateToIso(String(values.endDate)),
						noExpiration: !values.noExpiration ? false : true,
						status: values.status,
						categoryId: values.categoryId,
						createdFrom: 1,
						updatedFrom: 1,
						updatedBy: Number(localStorage.getItem("USER_ID")),
						createdBy: Number(localStorage.getItem("USER_ID")),
						countryCode: "PH",
						is_delete: false,
					};

					const response = await _addBanner(newBanner);

					if (response.error) {
						throw response.error;
					}
				}
			}

			setIsSaveModalOpen(false);
			setTimeout(() => {
				setIsNotifModalOpen(true);
			}, 250);
		} catch (error) {
			setIsSaveModalOpen(false);

			if (isErrorResponse(error)) {
				const errorResponse = reshapeErrorResponse(error);

				toast({
					description:
						errorResponse.status >= 500
							? "Server Issue! Please contact your administrator."
							: errorResponse.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/banner");
	};

	useEffect(() => {
		if (selectedBanner) {
			form.reset(selectedBanner, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});

			if (selectedBanner.status === 1) {
				setIsActiveBanner(true);
			}
		}
	}, [selectedBanner, form]);

	useEffect(() => {
		if (selectedBanner) {
			if (isActiveBanner) {
				if (status === 0) {
					setActiveBanners((prevBanners) => prevBanners.slice(0, -1));
				} else {
					setActiveBanners(banners);
				}
			}
		}
	}, [status]);

	// useEffect(() => {
	// 	if (isDirty) {
	// 		form.trigger();
	// 	}
	// }, [dates]);

	if (
		isSelectedBannerFetching ||
		isCategoriesFetching ||
		isActiveBannersFetching ||
		isProductsArrayFetching
	) {
		return <Loading />;
	}

	if (!isSelectedBannerFetching && !selectedBanner) {
		if (pathSegments[2] != "add") {
			navigate("/banner", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<FormHeader>
							<Label variant="title">
								{!selectedBanner?.id ? "New Promo Banner" : "Edit Promo Banner"}
							</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={
										!isDirty ||
										!isValid ||
										isBannerAddLoading ||
										isBannerUpdateLoading
									}
									onClick={_onSavePress}
								>
									{isBannerAddLoading || isBannerUpdateLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="button"
									onClick={_onCancelPress}
									disabled={isBannerAddLoading || isBannerUpdateLoading}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>
						<div className="grid gap-4 xl:gap-6 w-full h-fit xl:h-full">
							<div className="flex flex-col gap-4 w-full my-1 justify-start items-start sm:flex-row sm:my-4 sm:justify-between sm:items-center lg:my-6">
								<Label variant="header">Banner Information</Label>
								<Button size="md" onClick={_navigateToAddProducts}>
									Add Products
									<ArrowRightIconSvg className="h-5 w-5" fill="#292D32" />
								</Button>
							</div>
							<BannerInformationSection
								form={form}
								categoriesOption={categoriesOption}
								setImage={setImage}
								isLoading={isImageUploading}
							/>
							<PromoDurationSection
								form={form}
								noOfActiveBanners={activeBanners.length || 0}
							/>
						</div>

						<ProductListSection
							productsArray={productsArray}
							pathSegments={pathSegments}
						/>
					</PageContainer>

					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>

					<ConfirmationDialog
						title="Save Changes?"
						description="Saving will apply all changes."
						confirmButtonLabel="Continue"
						closeButtonLabel="Cancel"
						modalState={isSaveModalOpen}
						isLoading={isBannerAddLoading || isBannerUpdateLoading}
						_onCancel={() => setIsSaveModalOpen(false)}
						_onConfirm={_onConfirmSave}
						confirmButtonVariant="default"
					/>

					<NotificationDialog
						description={
							!bannerId
								? "Banner has been added successfully!"
								: "Banner has been updated successfully!"
						}
						confirmButtonLabel="Ok"
						modalState={isNotifModalOpen}
						_onConfirm={_onNotifOkayPress}
					/>
				</form>
			</div>
		</Form>
	);
};

export default BannerForm;
