import React from "react";
import { Button } from "../ui/button";
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import { Label } from "../ui/label";

type ImportButtonProps = {
	onClick?: () => void;
	buttonText: string;
	className?: string;
};

const ImportButton = ({
	onClick,
	buttonText,
	className,
}: ImportButtonProps) => {
	return (
		<Button
			variant="secondary"
			size="sm"
			className={className}
			onClick={() => {
				if (onClick) onClick();
			}}
		>
			<ImportIconSvg fill="white" className="md:hidden w-5 h-5" />
			<Label className="hidden md:block cursor-pointer">{buttonText}</Label>
		</Button>
	);
};

export default ImportButton;
