import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SuccessMultipleDataResponse } from "./types";
// import "dotenv/config";
// const dotenv = require("dotenv");

// dotenv.config();

type FileUploadResponse = {
	filePath: string;
};

// Create the media API slice
export const mediaApi = createApi({
	reducerPath: "mediaApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://staging.mr.diy.api.suites.digital/",
		prepareHeaders: (headers, { getState }) => {
			const token = localStorage.getItem("ACCESS_TOKEN");

			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		// Mutation for uploading media
		uploadMedia: builder.mutation<
			FileUploadResponse,
			{
				files: File | File[];
				module: string;
			}
		>({
			query: ({ files, module }) => {
				const formData = new FormData();

				// Handle single file or array of files
				const fileArray = Array.isArray(files) ? files : [files];
				fileArray.forEach((file) => formData.append("files", file));

				formData.append("module", module);

				return {
					url: `/file-upload/create`,
					method: "POST",
					body: formData,
				};
			},
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<FileUploadResponse>) => {
				return data[0];
			},
		}),
	}),
});

// Export hooks for usage in components
export const { useUploadMediaMutation } = mediaApi;
