import { createApi } from "@reduxjs/toolkit/query/react";

import { AboutUs, Contact, Platform, SocialMediaLink } from "./types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";
import { SuccessMultipleDataResponse } from "../types";

export const libraryApi = createApi({
	reducerPath: "libraryApi",
	tagTypes: ["Library"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getAboutUsInfo: builder.query<AboutUs, void>({
			query: () => ({
				url: "/library/6",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<AboutUs>) => {
				return data[0];
			},
			keepUnusedDataFor: 0,
		}),
		getSocialMediaLinks: builder.query<Platform[], void>({
			query: () => ({
				url: "/social-media/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Platform>) => {
				return data;
			},
			keepUnusedDataFor: 0,
		}),
		getContactInfo: builder.query<Contact, void>({
			query: () => ({
				url: "/contact/11",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Contact>) => {
				return data[0];
			},
			keepUnusedDataFor: 0,
		}),
		updateAboutUs: builder.mutation<void, AboutUs>({
			query: (about_us) => ({
				url: `/library/${about_us.id}`,
				method: "PATCH",
				body: about_us,
			}),
		}),
		updateContact: builder.mutation<void, Contact>({
			query: (contact) => ({
				url: `/contact/${contact.id}`,
				method: "PATCH",
				body: contact,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				const { data } = await queryFulfilled;
				console.log(args);
			},
		}),
		updateSocialMedia: builder.mutation<void, SocialMediaLink>({
			query: (platform) => ({
				url: `/social-media/${platform.id}`,
				method: "PATCH",
				body: platform,
			}),
		}),
	}),
});

export const {
	useGetAboutUsInfoQuery,
	useGetSocialMediaLinksQuery,
	useGetContactInfoQuery,
	useUpdateSocialMediaMutation,
	useUpdateAboutUsMutation,
	useUpdateContactMutation,
} = libraryApi;
